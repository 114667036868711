
.dark_block_loader{
text-align: center;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    /*padding-top: 150px;*/
    /* background-color: #f3f3f3ab; */
    background-color: #bbbbbb52;
	z-index:999;
	padding-top:20%;
}
.dark_block_loader > div{
	 display: inline-block;
	position: relative;
    width: 85px;
    height: 80px;
    background: #636363;
    border-radius: 5px;
    border: 1px solid #4a4848;

}
.dark_block_loader .loading-text{
	color: #f5f2f2;
    text-align: center;
    font-size: 13px;
}

.dark_block_loader .loader {	
	margin-top: 8px;
	margin-bottom: 5px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-left: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}