@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto+Condensed:400,500,600,700');
/**
*
* -----------------------------------------------------------------------------
*
* Template : Edulearn | Responsive Education HTML5 Template 
* Author : rs-theme
* Author URI : http://www.rstheme.com/
*
* -----------------------------------------------------------------------------
*
**/
/* Table Of Content
---------------------------------------------------------
01. General CSS
02. Global Class
03. Header Section 
04. Sticky Menu
05. Slider Section
06. Breadcrumbs
07. Services
08. About Us
09. Popular Cources
10. Courses Details
11. Rs-counter
12. Upcoming Events
13. Experienced Staffs
14. Staffs Single
15. Calltoaction
16. Latest News
17. Our Publications
18. Testimonial
19. Newsletter
20. Rs-video
21. Why Choose Us
22. Pricing Table
23. Instagram
24. About Us Page
25. Rs Timeline
26. Elements
27. Blog Pages
28. Blog Details
29. Shop Page Start Here
30. Shop Single Page Start Here 
31. Check Out css Start Here
32. Shipping Area Start Here
32. Contact Page Section Start Here
33. Rs Gallery
34. ScrollUp
35. 404 Page Area Start Here 
36. Preloader css
37. Rs Footer

--------------------------------------------------------*/
/**
*
* -----------------------------------------------------------------------------
*
* Template : RS-Construction - Construction HTML5 Template 
* Author : rs-theme
* Author URI : http://www.rstheme.com/
*
* -----------------------------------------------------------------------------
*
**/
/*-----------------------
    Animation
-------------------------*/
[class*='rs-animation-'] {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.rs-animation-fade {
  -webkit-animation-name: rs-fade;
  animation-name: rs-fade;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}
.rs-animation-slide-top {
  -webkit-animation-name: rs-fade-top;
  animation-name: rs-fade-top;
}
.rs-animation-slide-bottom {
  -webkit-animation-name: rs-fade-bottom;
  animation-name: rs-fade-bottom;
}
.rs-animation-slide-left {
  -webkit-animation-name: rs-fade-left;
  animation-name: rs-fade-left;
}
.rs-animation-slide-right {
  -webkit-animation-name: rs-fade-right;
  animation-name: rs-fade-right;
}
.rs-animation-scale-up {
  -webkit-animation-name: rs-animation-scale-up;
  animation-name: rs-animation-scale-up;
}
.rs-animation-scale-down {
  -webkit-animation-name: rs-animation-scale-down;
  animation-name: rs-animation-scale-down;
}
.rs-animation-shake {
  -webkit-animation-name: rs-shake;
  animation-name: rs-shake;
}
.rs-animation-rotate {
  -webkit-animation-name: rs-rotate;
  animation-name: rs-rotate;
}
.rs-animation-scale {
  -webkit-animation-name: rs-scale;
  animation-name: rs-scale;
}
.rs-animation-hover:not(:hover),
.rs-animation-hover:not(:hover) [class*='rs-animation-'] {
  -webkit-animation-name: none;
  animation-name: none;
}
.rs-animation-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.rs-image-effect-shine {
  position: relative;
  overflow: hidden;
}
.rs-image-effect-shine::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.rs-image-effect-shine:hover::before {
  -webkit-animation: img-shine 1s;
  animation: img-shine 1s;
}
@-webkit-keyframes img-shine {
  100% {
    left: 125%;
  }
}
@keyframes img-shine {
  100% {
    left: 125%;
  }
}
/*-------------------------
    Fade
---------------------------*/
@-webkit-keyframes rs-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rs-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*---------------------------
    Fade Top
------------------------------*/
@-webkit-keyframes rs-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-60%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes rs-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-60%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*---------------------------
    Fade Bottom
------------------------------*/
@-webkit-keyframes rs-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(60%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes rs-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(60%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*---------------------------
    Fade Left
------------------------------*/
@-webkit-keyframes rs-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-60%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes rs-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-60%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*---------------------------
    Fade Right
------------------------------*/
@-webkit-keyframes rs-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(60%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes rs-fade-right {
  0% {
    opacity: 0;
    transform: translateX(60%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*------------------------------
   Scale Up
--------------------------------*/
@-webkit-keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*---------------------------
    Scale Down
------------------------------*/
@-webkit-keyframes rs-animation-scale-down {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.6);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes rs-animation-scale-down {
  0% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*---------------------------
    Rotate
------------------------------*/
@-webkit-keyframes rs-rotate {
  0% {
    -webkit-transform: rotate(280deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rs-rotate {
  0% {
    transform: rotate(280deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*---------------------------
    Shake
------------------------------*/
@-webkit-keyframes rs-shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
  }
}
@keyframes rs-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*-------------------
    Scale
---------------------*/
@-webkit-keyframes rs-scale {
  0% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes rs-scale {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* -----------------------------------
    01. General CSS
-------------------------------------*/
html,
body {
  font-size: 16px;
  color: #505050;
  font-family: 'Roboto Condensed', sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #212121;
  margin: 0 0 26px;
  font-weight: 700;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
a {
  color: #ff3115;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #e41f05;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #ff3115;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #ff3115;
  text-shadow: none;
  color: #ffffff;
}
.sec-spacer {
  padding: 100px 0;
}
.sec-color {
  background-color: #f9f9f9;
}
.drak-color {
  background-color: #252525;
}
.gray-color {
  background-color: #f0f0f0;
}
.primary-color {
  color: #ff3115;
}
.primary-bg {
  background: #ff3115;
}
.sec-black {
  background: #212121;
}
.bg-fixed {
  background-attachment: fixed;
  background-repeat: no-repeat;
}
/*-- Readon Button Css --*/
.readon2 {
  font-size: 14px;
  border: 1px solid #ff3115;
  background: #ff3115;
  display: inline-block;
  padding: 0 35px;
  height: 45px;
  line-height: 45px;
  position: relative;
  color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  border-radius: 35px;
}
.readon2.transparent {
  border: 1px solid #ff3115;
  color: #ff3115;
  background: transparent;
}
.readon2.transparent:hover {
  background: #ff3115;
  color: #ffffff;
}
.readon2:hover {
  background: #e41f05;
  color: #ffffff;
}
/********************************/
/*       Slides backgrounds     */
/********************************/
.bg1 {
  background-image: url(../images/bg/bg1.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.bg2 {
  background-image: url(../images/bg/bg2.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.bg3 {
  background-image: url(../images/bg/counter-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.bg4 {
  background-image: url(../images/bg/cta-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.bg5 {
  background-image: url(../images/bg/testimonial-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
/* .bg6 {
  background-image: url(images/slider/home1/slide2.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
} */
.bg7 {
  background-image: url(../images/bg/bg3.jpg);
  background-size: cover;
  background-position: center;
  background-position: center top;
}
.bg8 {
  background-image: url(../images/bg/bg8.jpg);
  background-attachment: fixed;
  background-position: center top;
}
.bg9 {
  background-image: url(../images/bg/video-bg2.jpg);
  background-attachment: fixed;
  background-position: center top;
}
.bg10 {
  background-image: url(../images/bg/counter-bg-style2.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.bg11 {
  background-image: url(../images/bg/home7-style.jpg);
  background-position: center top;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 130px;
}
.bg12 {
  background-image: url(../images/bg/countdown.jpg);
  background-position: center top;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
.bg13 {
  background-image: url(../images/bg/home8-style.jpg);
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 250px;
}
.bg14 {
  background-image: url(../images/counter/2.jpg);
  background-size: cover;
  background-position: center top;
  padding-bottom: 500px !important;
}
.bg15 {
  background-image: url(../images/bg/about-home8-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.uppercase {
  text-transform: uppercase;
}
/* ------------------------------------
    02. Global Class
---------------------------------------*/
.drak-color .owl-nav .owl-prev,
.drak-color .owl-nav .owl-next,
.drak-color .sec-title h3 {
  color: #ffffff;
}
.sec-title2 h2 {
  font-size: 30px;
  text-transform: uppercase;
  color: #212121;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
}
.sec-title2 span {
  display: block;
  font-size: 18px;
  line-height: 34px;
  color: #ff3115;
  font-weight: 600;
}
.sec-title {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 20px;
}
.sec-title h2 {
  font-size: 30px;
  line-height: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sec-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100px;
  background: #ff3115;
}
.sec-title.text-center:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.sec-title.text-right:after {
  left: auto;
  right: 0;
}
.sec-title.text-right .view-more {
  right: auto;
  left: 0;
}
.sec-title p {
  margin-bottom: 0;
  font-size: 16px;
}
.sec-title .view-more {
  position: absolute;
  right: 0;
}
.sec-title .view-more a {
  font-weight: 600;
  font-size: 15px;
}
.sec-title.white-text h2 {
  color: #ffffff;
}
.sec-title.white-text p {
  color: rgba(255, 255, 255, 0.6);
}
.home5 .sec-title h2 {
  color: #92278f;
}
.home5 .sec-title:after {
  background: #92278f;
}
.home5 .sec-title.white-text p {
  color: #ffffff;
}
.sec-title-2 {
  position: relative;
}
.sec-title-2 h2 {
  position: relative;
  font-size: 30px;
  line-height: 24px;
  margin-bottom: 25px;
  padding-bottom: 26px;
  text-transform: uppercase;
}
.sec-title-2 h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100px;
  background: #ff3115;
}
.sec-title-2 .view-more {
  position: absolute;
  right: 0;
  bottom: 0;
}
.sec-title-2 .view-more a {
  font-weight: 700;
  font-size: 14px;
}
.sec-title-2.text-center h2:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.sec-title-2.text-right h2:after {
  left: auto;
  right: 0;
}
.sec-title-2.text-right .view-more {
  right: auto;
  left: 0;
}
.sec-title-2 p {
  margin-bottom: 0;
  font-size: 16px;
}
.primary-btn {
  display: inline-block;
  height: 40px;
  line-height: 35px;
  text-align: center;
  min-width: 136px;
  padding: 0 20px;
  border: 2px solid #ff3115;
  color: #212121;
  transition: all 0.3s ease 0s;
  font-weight: 600;
}
.primary-btn:hover {
  background: #ff3115;
  color: #ffffff;
}
.readon {
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  line-height: normal;
  background: #ff3115;
  color: #fff;
  transition: all 0.3s ease 0s;
  border-radius: 2px;
}
.readon:hover,
.readon:focus {
  background: #e41f05;
  color: rgba(255, 255, 255, 0.8);
}
.readon.border {
  background: transparent;
  border: 1px solid #ff3115;
  color: #ff3115;
}
.readon.border:hover {
  color: #fff;
  background: #ff3115;
}
.readon.border.white {
  border-color: #fff;
  color: #fff;
}
.readon.border.white:hover {
  color: #ff3115;
  background: #fff;
}
/*overly border*/
.overly-border::before,
.overly-border::after {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  z-index: 1;
}
.blue-bg {
  position: relative;
}
.blue-bg .blue-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 188, 212, 0.9);
}
.about-img .overly-border::before,
.about-img .overly-border::after {
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
}
.overly-border::before {
  border-top: 5px solid #ff3115;
  border-bottom: 5px solid #ff3115;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
.rs-blog .blog-item.slick-current .team-content {
  opacity: 1;
  top: 0;
}
.overly-border::after {
  border-right: 5px solid #ff3115;
  border-left: 5px solid #ff3115;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}
.single-member-area figure .overly-border:before,
.single-member-area figure .overly-border:after,
.rs-blog .blog-item.slick-current .overly-border:before,
.rs-blog .blog-item.slick-current .overly-border:after,
.single-member-area:hover .overly-border:before,
.single-member-area:hover .overly-border:after,
.team-content:hover .overly-border:before,
.team-content:hover .overly-border:after,
.blog-content:hover .overly-border:before,
.blog-content:hover .overly-border:after,
.project-content:hover .overly-border:before,
.project-content:hover .overly-border:after,
.about-img:hover .overly-border:before,
.about-img:hover .overly-border:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.right_menu_togle .search-wrap button {
  color: #ff3115;
}
.padding-0 {
  padding: 0 !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-170 {
  padding-bottom: 170px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.sparator-15 {
  height: 15px;
  clear: both;
}
.ml-15 {
  margin-left: 15px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.margin-remove {
  margin: 0 !important;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.white-color {
  color: #fff !important;
}
.rs-vertical-middle .logo-area {
  line-height: 95px;
}
.rs-vertical-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.rs-vertical-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
/* -. Owl Carousel -*/
.owl-controls .owl-nav > div {
  position: absolute;
  top: 50%;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #444;
  color: #fff;
  transform: translateY(-50%);
  font-size: 22px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.owl-controls .owl-nav > div:hover {
  background-color: #ff3115;
}
.owl-controls .owl-nav .owl-prev {
  left: -60px;
}
.owl-controls .owl-nav .owl-next {
  right: -60px;
}
.rs-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1;
}
.rs-navigation-2 {
  padding-bottom: 80px;
}
.rs-navigation-2 .owl-controls .owl-nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.rs-navigation-2 .owl-controls .owl-nav > div {
  position: initial;
  display: inline-block;
  transform: none;
  opacity: 1 !important;
}
.rs-navigation-2 .owl-controls .owl-nav .owl-next {
  margin-left: 12px;
}
.rs-navigation-2 .owl-stage-outer {
  padding-bottom: 0 !important;
}
/* ------------------------------------
    03. Header Section  
---------------------------------------*/
.rs-toolbar {
  padding: 4px 0 5px;
  background-color: #111111;
}
.rs-toolbar .rs-toolbar-left .welcome-message {
  font-size: 14px;
}
.rs-toolbar .rs-toolbar-left .welcome-message i {
  color: #ff3115;
  margin-right: 8px;
}
.rs-toolbar .rs-toolbar-left .welcome-message span {
  color: #ffffff;
}
.rs-toolbar .rs-toolbar-right {
  text-align: right;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon {
  display: inline-block;
  margin-right: 30px;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li {
  display: inline-block;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li + li {
  margin-left: 8px;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li a {
  font-size: 14px;
  color: #888888;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li a:hover,
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li a:active,
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li a:focus {
  color: #ffffff;
}
.rs-toolbar .rs-toolbar-right .apply-btn {
  font-weight: 500;
  font-size: 14px;
}
/* ------------------------------------
    02. Header Start
---------------------------------------*/
.rs-header .rs-header-top {
  padding: 25px 0 20px;
}
.rs-header .rs-header-top .header-contact {
  float: right;
}
.rs-header .rs-header-top .header-contact .widget-text {
  position: relative;
  padding-left: 55px;
}
.rs-header .rs-header-top .header-contact .widget-text i {
  position: absolute;
  border-radius: 2px;
  text-align: center;
  left: 0;
  line-height: 40px;
  color: #ff3115;
  font-size: 35px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-weight: 700;
}
.rs-header .rs-header-top .header-contact .widget-text .info-text a {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
}
.rs-header .rs-header-top .header-contact .widget-text .info-text a:hover {
  color: #ff3115;
}
.rs-header .rs-header-top .header-contact .widget-text .info-text span {
  display: block;
  font-weight: 700;
  color: #101010;
  line-height: 18px;
}
.rs-header .rs-menu-toggle {
  color: #fff !important;
}
.rs-header .rs-menu-toggle:hover {
  color: #ff3115 !important;
}
.rs-header .menu-area .rs-menu li.current_page_item > a,
.rs-header-2 .menu-area .rs-menu li.current_page_item > a,
.rs-header-3 .menu-area .rs-menu li.current_page_item > a,
.rs-header-4 .menu-area .rs-menu li.current_page_item > a,
.rs-header .menu-area .rs-menu li.current-menu-item > a,
.rs-header-2 .menu-area .rs-menu li.current-menu-item > a,
.rs-header-3 .menu-area .rs-menu li.current-menu-item > a,
.rs-header-4 .menu-area .rs-menu li.current-menu-item > a,
.rs-header .menu-area .rs-menu li.active > a,
.rs-header-2 .menu-area .rs-menu li.active > a,
.rs-header-3 .menu-area .rs-menu li.active > a,
.rs-header-4 .menu-area .rs-menu li.active > a,
.rs-header .menu-area .rs-menu li a:hover,
.rs-header-2 .menu-area .rs-menu li a:hover,
.rs-header-3 .menu-area .rs-menu li a:hover,
.rs-header-4 .menu-area .rs-menu li a:hover {
  color: #ff3115 !important;
}
.rs-header-2 {
  position: absolute;
  width: 100%;
  z-index: 999;
}
.rs-header-2 .menu-area {
  padding: 30px 0;
}
.rs-header-2 .menu-area .rs-menu .nav-menu {
  text-align: right;
}
.rs-header-2 .menu-area .rs-menu .nav-menu > li > a {
  color: #ffffff;
}
.rs-header-2 .menu-area .rs-menu .nav-menu > li > a:hover {
  color: #ff3115;
}
@-moz-document url-prefix() {
  .home2 .right-bar-icon .nav-expander {
    position: relative;
    top: -1px;
  }
}
/* ----- Menu -------*/
.nav-menu > li > a {
  font-weight: 500;
  font-size: 16px;
}
.home1 .logo-area {
  padding-top: 3px;
}
.home1 .rs-menu li i {
  margin-right: 0;
  color: #ff3115;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
}
.home1 .rs-menu li i:hover {
  color: #e41f05;
}
.home1 .menu-area .rs-menu > ul > li > a {
  color: #bbbbbb;
}
.home1 .menu-area .rs-menu > ul > li > a:hover,
.home1 .menu-area .rs-menu li.current_page_item > a {
  color: #ff3115 !important;
}
.home1 .menu-area {
  background: #212121;
}
.home1 .searce-box {
  position: absolute;
  top: 0;
  right: 23%;
  top: 16px;
}
.home1 .searce-box a.rs-search i {
  color: #ff3115;
}
.home1 .searce-box a.rs-search i:hover {
  color: #ffffff;
}
.home1 .rs-header .rs-header-top .col-md-4:first-child .header-contact {
  float: left;
}
.inner-page .searce-box {
  position: absolute;
  top: 0;
  right: 16px;
  top: 15px;
}
.inner-page .searce-box a.rs-search i {
  color: #ff3115;
}
.home2 .rs-header-top {
  padding: 20px 0 50px;
}
.home2 .menu-area .container {
  position: relative;
}
.home2 .menu-area .main-menu {
  background: #111111;
  position: absolute;
  top: -30px;
  border-radius: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  max-width: 1170px;
  margin: 0 auto;
}
.home2 .menu-area .rs-menu ul > li > a {
  color: #bbbbbb;
}
.home2 .menu-area .rs-menu ul > li > a:hover {
  color: #ff3115;
}
.home2 .menu-area .rs-menu ul > li ul.sub-menu a {
  color: #bbbbbb;
}
.home2 .menu-area.sticky .main-menu {
  position: static;
}
.home2 #rs-slider .container {
  margin-bottom: 100px;
}
.home2 .rs-search {
  position: absolute;
  right: 15px;
  z-index: 99;
  top: 0;
  color: #fff;
  font-size: 18px;
  display: inline-block;
  height: 55px;
  width: 60px;
  line-height: 55px;
  padding: 0;
  text-align: center;
  background: #ff3115;
}
.home2 .rs-search:hover {
  background-color: #e41f05;
  color: rgba(255, 255, 255, 0.7);
}
.home1 .right-bar-icon,
.home2 .right-bar-icon {
  position: absolute;
  right: 15px;
}
.home1 .right-bar-icon a,
.home2 .right-bar-icon a {
  display: inline-block;
  height: 55px;
  width: 45px;
  line-height: 55px;
  text-align: center;
  color: #fff;
}
.home1 .right-bar-icon a:hover,
.home2 .right-bar-icon a:hover {
  color: #fff;
}
.home1 .right-bar-icon .rs-search,
.home2 .right-bar-icon .rs-search {
  position: static;
  color: #fff;
  background-color: #ff3115;
  margin-right: -4px;
}
.home1 .right-bar-icon .rs-search:hover,
.home2 .right-bar-icon .rs-search:hover {
  background: #e41f05;
}
.home1 .right-bar-icon .nav-expander,
.home2 .right-bar-icon .nav-expander {
  background: #e41f05;
}
.home1 .right-bar-icon .nav-expander:hover,
.home2 .right-bar-icon .nav-expander:hover {
  background: #ff3115;
}
.home1 .rs-courses .cource-item {
  border: none;
  background: #fff;
}
.university-home .search-modal .modal-content,
.inner-page .search-modal .modal-content,
.instructor-home .search-modal .modal-content,
.home1 .search-modal .modal-content,
.home2 .search-modal .modal-content,
.home5 .search-modal .modal-content,
.home3 .search-modal .modal-content {
  background: transparent;
  position: initial;
  border: 0;
}
.university-home .search-modal .search-block input,
.inner-page .search-modal .search-block input,
.instructor-home .search-modal .search-block input,
.home1 .search-modal .search-block input,
.home2 .search-modal .search-block input,
.home5 .search-modal .search-block input,
.home3 .search-modal .search-block input {
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}
.university-home .search-modal .close,
.inner-page .search-modal .close,
.instructor-home .search-modal .close,
.home1 .search-modal .close,
.home2 .search-modal .close,
.home5 .search-modal .close,
.home3 .search-modal .close {
  color: #ffffff;
  margin-top: 20px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.4);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  opacity: 1;
  outline: none;
  transition: all 0.3s ease 0s;
}
.university-home .search-modal .close:hover,
.inner-page .search-modal .close:hover,
.instructor-home .search-modal .close:hover,
.home1 .search-modal .close:hover,
.home2 .search-modal .close:hover,
.home5 .search-modal .close:hover,
.home3 .search-modal .close:hover {
  background-color: #ff3115;
}
.university-home .modal-backdrop,
.inner-page .modal-backdrop,
.instructor-home .modal-backdrop,
.home1 .modal-backdrop,
.home2 .modal-backdrop,
.home5 .modal-backdrop,
.home3 .modal-backdrop {
  opacity: 0.95;
}
.home3 .rs-header {
  position: absolute;
  width: 100%;
  z-index: 999;
}
#rs-header.rs-transfarent-header-style {
  position: absolute;
  width: 100%;
  z-index: 999;
}
#rs-header.rs-transfarent-header-style .logo-area {
  padding-top: 0;
}
#rs-header.rs-transfarent-header-style .menu-sticky.sticky {
  background: #212121;
  padding-top: 10px;
  padding-bottom: 10px;
}
#rs-header.rs-transfarent-header-style .menu-area {
  background: transparent;
}
#rs-header.rs-transfarent-header-style .menu-area .rs-menu .nav-menu > li > a {
  color: #ffffff;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner {
  position: relative;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner .searce-box {
  position: absolute;
  top: 0;
  right: 90%;
  top: 7px;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner .searce-box .rs-search i {
  color: #ff3115;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner .apply-box {
  margin-left: 35px;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner .apply-box a {
  padding: 0 28px;
  border-radius: 35px;
  line-height: 40px;
  height: auto;
  color: #ffffff;
  background: #ff3115;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
#rs-header.rs-transfarent-header-style .rs-right-btn-inner .apply-box a:hover {
  color: #ffffff;
  background: #e41f05;
}
.home3 .rs-header .menu-area {
  background-color: rgba(37, 37, 37, 0.8);
}
.home3 .rs-header .menu-area .rs-menu .nav-menu > li > a {
  color: #ffffff;
  line-height: 105px;
  height: 105px;
}
.home3 .rs-header .menu-area .rs-menu .nav-menu > li > a:hover {
  color: #ff3115;
}
.home3 .rs-header .menu-area .toggle-btn {
  position: absolute;
  right: 16px;
  top: 44px;
  background-color: transparent;
  cursor: pointer;
}
.home3 .rs-header .menu-area .toggle-btn .border-icon {
  display: block;
  height: 2px;
  margin-bottom: 5px;
  width: 25px;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  background: #ffffff;
  z-index: 11;
}
.home3 .rs-header .menu-area .toggle-btn .border-icon:last-child {
  margin-bottom: 0;
}
.home3 .rs-header .menu-area .toggle-btn.active {
  top: 40px;
}
.home3 .rs-header .menu-area .toggle-btn.active .border-icon {
  background: #ff3115;
}
.home3 .rs-header .menu-area .toggle-btn.active .border-icon:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.home3 .rs-header .menu-area .toggle-btn.active .border-icon:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.home3 .rs-header .menu-area .toggle-btn.active .border-icon:nth-child(3) {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.home3 .rs-header .menu-sticky.sticky {
  background-color: #212121;
}
.home3 .rs-header .menu-sticky.sticky .rs-menu .nav-menu > li > a {
  line-height: 80px;
  height: 80px;
}
.home3 .rs-header .menu-sticky.sticky .rs-menu .nav-menu > li > a:hover {
  color: #ff3115;
}
.home3 .rs-header .menu-sticky.sticky .searce-box {
  top: 26px;
}
.home3 .rs-header .menu-sticky.sticky .toggle-btn {
  top: 32px;
}
.home3 .rs-header .searce-box {
  position: absolute;
  top: 0;
  right: 8%;
  top: 38px;
}
.home3 .rs-header .searce-box a.rs-search i {
  color: #ff3115;
}
.home3 .rs-header .rs-menu ul {
  text-align: right;
  margin-right: 70px;
}
.home3 .rs-header .searce-box,
.home3 .rs-header .rs-menu > ul {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.home3.hidden-menu .rs-header .searce-box,
.home3.hidden-menu .rs-header .rs-menu > ul {
  opacity: 1;
  visibility: visible;
}
.home3 .rs-toolbar .rs-toolbar-left .welcome-message,
.home5 .rs-toolbar .rs-toolbar-left .welcome-message,
.instructor-home .rs-toolbar .rs-toolbar-left .welcome-message {
  float: left;
  margin-right: 25px;
}
.home3 .rs-toolbar .rs-toolbar-left .welcome-message:last-child,
.home5 .rs-toolbar .rs-toolbar-left .welcome-message:last-child,
.instructor-home .rs-toolbar .rs-toolbar-left .welcome-message:last-child {
  margin-right: 0;
}
.home3 .rs-toolbar .rs-toolbar-left .welcome-message a,
.home5 .rs-toolbar .rs-toolbar-left .welcome-message a,
.instructor-home .rs-toolbar .rs-toolbar-left .welcome-message a {
  color: #ffffff;
}
.home3 .rs-toolbar .rs-toolbar-left .welcome-message a:hover,
.home5 .rs-toolbar .rs-toolbar-left .welcome-message a:hover,
.instructor-home .rs-toolbar .rs-toolbar-left .welcome-message a:hover {
  color: #ff3115;
}
.home5 .rs-toolbar {
  background: #112170;
}
.home5 .rs-toolbar .toolbar-share-icon {
  margin-right: 15px;
}
.home5 .rs-toolbar .toolbar-share-icon ul li a {
  color: #fff;
  font-size: 14px;
}
.home5 .rs-toolbar .toolbar-share-icon ul li a:hover {
  color: #92278f;
}
.home5 .rs-toolbar .rs-search {
  color: #fff;
  font-size: 14px;
}
.home5 .rs-toolbar .rs-search:hover {
  color: #92278f;
}
.home5 .rs-header .logo-area {
  padding-top: 23px;
}
.home5 .rs-header .main-menu .rs-menu ul {
  text-align: right;
  margin-right: 112px;
}
.home5 .rs-header .main-menu .rs-menu > ul > li > a {
  color: #112170;
}
.home5 .rs-header .main-menu .rs-menu ul li a:hover,
.home5 .rs-header .main-menu .rs-menu ul li.active a,
.home5 .rs-header .main-menu .rs-menu ul li.current-menu-item > a {
  color: #92278f !important;
}
.home5 .rs-header .main-menu .nav-expander {
  font-size: 20px;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: block;
  color: #92278f;
  position: absolute;
  right: 15px;
  top: 26px;
}
.home5 .rs-header .main-menu .nav-expander:hover {
  transform: scale(1.1);
  color: #ff3115;
}
.home5 .rs-header .sticky {
  background: #fff;
}
.home5 .rs-header .apply-box {
  position: absolute;
  right: 0;
  min-width: 130px;
  text-align: center;
  line-height: 46px;
  background: #92278f;
  margin-top: 17px;
  border-radius: 30px;
}
.home5 .rs-header .apply-box a {
  color: #fff;
  display: block;
  text-transform: uppercase;
}
.home5 .rs-header .apply-box a:hover {
  color: #112170;
}
.instructor-home .rs-header .logo-area {
  padding-top: 23px;
}
.instructor-home .rs-header .main-menu .rs-menu ul {
  text-align: right;
  margin-right: 110px;
}
.instructor-home .rs-header .main-menu .rs-menu > ul > li > a {
  color: #505050;
}
.instructor-home .rs-header .main-menu .rs-menu ul li a:hover,
.instructor-home .rs-header .main-menu .rs-menu ul li.active a,
.instructor-home .rs-header .main-menu .rs-menu ul li.current-menu-item > a {
  color: #ff3115 !important;
}
.instructor-home .rs-header .main-menu .nav-expander {
  font-size: 20px;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: block;
  color: #92278f;
  position: absolute;
  right: 15px;
  top: 26px;
}
.instructor-home .rs-header .main-menu .nav-expander:hover {
  transform: scale(1.1);
  color: #ff3115;
}
.instructor-home .rs-header .sticky {
  background: #fff;
}
.instructor-home .rs-header .apply-box {
  position: absolute;
  right: 0;
  min-width: 130px;
  text-align: center;
  line-height: 46px;
  background: #ff3115;
  margin-top: 17px;
  border-radius: 30px;
  transition: 0.4s;
}
.instructor-home .rs-header .apply-box:hover {
  background: #e41f05;
}
.instructor-home .rs-header .apply-box a {
  color: #fff;
  display: block;
  text-transform: uppercase;
}
.instructor-home .searce-box {
  padding-top: 28px;
}
.instructor-home .searce-box i {
  color: #ff3115;
}
.university-home .menu-area {
  padding: 16px 0;
}
.university-home .menu-area .rs-menu ul {
  text-align: right;
}
.university-home .rs-header .menu-area .rs-menu .nav-menu > li > a {
  color: #ffffff;
}
.university-home .rs-header .menu-area .rs-menu .searce-box {
  padding-top: 15px;
}
.university-home .rs-header .menu-area .rs-menu .searce-box a {
  position: relative;
}
.university-home .rs-header .menu-area .rs-menu .searce-box a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 1px;
  background: #ffffff;
}
.home5 .overly-border::before,
.university-home .overly-border::before {
  border-top: 5px solid #92278f;
  border-bottom: 5px solid #92278f;
}
.home5 .overly-border::after,
.university-home .overly-border::after {
  border-right: 5px solid #92278f;
  border-left: 5px solid #92278f;
}
.home5 .rs-footer .footer-share ul li a,
.university-home .rs-footer .footer-share ul li a,
.home5 .rs-footer .footer-share ul li a:hover,
.university-home .rs-footer .footer-share ul li a:hover,
.home5 .rs-footer .footer-title:after,
.university-home .rs-footer .footer-title:after,
.home5 .rs-footer .footer-top .news-form button,
.university-home .rs-footer .footer-top .news-form button,
.home5 .rs-footer .footer-top .recent-post-widget .post-item .post-date,
.university-home .rs-footer .footer-top .recent-post-widget .post-item .post-date,
.home5 .rs-footer .footer-contact-desc,
.university-home .rs-footer .footer-contact-desc,
.home5 .rs-latest-news .news-normal-block .news-btn a,
.university-home .rs-latest-news .news-normal-block .news-btn a,
.home5 .owl-controls .owl-nav > div:hover,
.university-home .owl-controls .owl-nav > div:hover,
.home5 .rs-team .team-item .team-img .normal-text,
.university-home .rs-team .team-item .team-img .normal-text,
.home5 .rs-team .team-item .team-title:after,
.university-home .rs-team .team-item .team-title:after,
.home5 #scrollUp i,
.university-home #scrollUp i {
  background: #92278f;
}
.home5 .rs-footer .footer-bottom,
.university-home .rs-footer .footer-bottom,
.home5 .rs-footer .footer-top .recent-post-widget .post-item + .post-item,
.university-home .rs-footer .footer-top .recent-post-widget .post-item + .post-item,
.home5 .rs-footer .footer-top .news-form input,
.university-home .rs-footer .footer-top .news-form input {
  border-color: #92278f;
}
.home5 .searce-box i,
.university-home .searce-box i,
.home5 #rs-slider .slide-content .slider-desc,
.university-home #rs-slider .slide-content .slider-desc,
.home5 .copyright a,
.university-home .copyright a,
.home5 .rs-footer .footer-top .sitemap-widget li a:hover,
.university-home .rs-footer .footer-top .sitemap-widget li a:hover,
.home5 .rs-footer .footer-title,
.university-home .rs-footer .footer-title,
.home5 .rs-latest-news .news-list-block .news-list-item .news-title a,
.university-home .rs-latest-news .news-list-block .news-list-item .news-title a,
.home5 .rs-latest-news .news-normal-block .news-title a,
.university-home .rs-latest-news .news-normal-block .news-title a,
.home5 .rs-events .event-item .event-btn a,
.university-home .rs-events .event-item .event-btn a,
.home5 .rs-events .event-item .event-title a,
.university-home .rs-events .event-item .event-title a {
  color: #92278f;
}
.home5 .copyright a:hover,
.university-home .copyright a:hover,
.home5 .rs-footer .footer-top .recent-post-widget .post-item .post-title a:hover,
.university-home .rs-footer .footer-top .recent-post-widget .post-item .post-title a:hover {
  color: #5d0b5b;
}
.home5 .rs-footer .footer-top .news-form button:hover,
.university-home .rs-footer .footer-top .news-form button:hover {
  background: #5d0b5b;
}
.home5 .rs-footer .footer-share ul li a:hover,
.university-home .rs-footer .footer-share ul li a:hover {
  color: #112170;
}
.home5 .rs-footer .footer-contact-desc .contact-inner i,
.university-home .rs-footer .footer-contact-desc .contact-inner i {
  color: #e84b3a;
}
.home5 .rs-footer,
.university-home .rs-footer {
  color: #505050;
}
.home5 .rs-footer .footer-top .sitemap-widget li a,
.university-home .rs-footer .footer-top .sitemap-widget li a,
.home5 .rs-footer .footer-top .recent-post-widget .post-item .post-title a,
.university-home .rs-footer .footer-top .recent-post-widget .post-item .post-title a {
  color: #505050;
}
.home5 .slider-overlay-2 .slide-content:after,
.university-home .slider-overlay-2 .slide-content:after {
  display: none;
}
.home5 .rs-footer .footer-top .news-form input,
.university-home .rs-footer .footer-top .news-form input {
  color: #000;
}
.home5 .rs-footer .footer-contact-desc,
.university-home .rs-footer .footer-contact-desc {
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}
.home5 .rs-footer .footer-bottom,
.university-home .rs-footer .footer-bottom {
  background: #269aa9;
}
.home5 .rs-toolbar .rs-toolbar-left .welcome-message i,
.university-home .rs-toolbar .rs-toolbar-left .welcome-message i {
  color: #fff;
}
.university-home .searce-box i {
  color: #ff3115;
}
.university-home #scrollUp i,
.university-home .owl-controls .owl-nav > div:hover {
  background: #ff3115;
}
/* ------------------------------------
    04. Sticky Menu
---------------------------------------*/
.menu-sticky.sticky {
  background: #111111;
  position: fixed !important;
  top: 0px;
  z-index: 999;
  margin: 0 auto !important;
  padding: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: sticky-animation;
  animation-name: sticky-animation;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.menu-sticky.sticky .main-menu {
  position: static ;
}
.rs-header-2 .menu-sticky1.sticky1 .nav-menu > li > a {
  height: 80px;
  line-height: 80px;
}
#nav-close {
  transition: all 0.3s ease 0s;
}
#nav-close:hover {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #fff;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
    05. Slider
---------------------------------------*/
#rs-slider {
  position: relative;
}
#rs-slider .slide-content {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 8;
}
#rs-slider .slide-content .slider-title {
  font-size: 60px;
  color: #fff;
  margin-bottom: 15px;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  text-transform: uppercase;
}
#rs-slider .slide-content .slider-desc {
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 20px;
}
#rs-slider .slide-content .sl-readmore-btn {
  border: 2px solid #101010;
  background: #101010;
  display: inline-block;
  padding: 12px 25px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease 0s;
}
#rs-slider .slide-content .sl-readmore-btn:hover {
  background-color: #e41f05;
  border-color: #e41f05;
  color: rgba(255, 255, 255, 0.8);
}
#rs-slider .slide-content .sl-get-started-btn {
  background-color: #ff3115;
  border: 2px solid #ff3115;
  display: inline-block;
  padding: 12px 25px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease 0s;
}
#rs-slider .slide-content .sl-get-started-btn:hover {
  background-color: #e41f05;
  border-color: #e41f05;
  color: rgba(255, 255, 255, 0.8);
}
#rs-slider .owl-nav {
  display: none;
}
#rs-slider .owl-dots {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
}
#rs-slider .owl-dots .owl-dot {
  height: 15px;
  width: 5px;
  background-color: #ffffff;
}
#rs-slider .owl-dots .owl-dot + .owl-dot {
  margin-top: 8px;
}
#rs-slider .owl-dots .owl-dot.active {
  background-color: #ff3115;
}
.slider-overlay-1 .slide-content:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.slider-overlay-2 .slide-content:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 109, 0, 0.3);
  z-index: -1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
  background: linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.3) 100%);
  background: -webkit-gradient(linear, right top, right bottom, color-stop(0%, rgba(0, 0, 0, 0.55)), color-stop(50%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.3)));
  opacity: 0.95;
}
.home5 span.red-color {
  color: #e84b3a;
}
.home5 span.orange-color {
  color: #fc7f0c;
}
.home5 #rs-slider {
  position: relative;
}
.home5 #rs-slider .slide-content .slider-title {
  color: #92278f;
}
.home5 #rs-slider .slide-content .slider-title,
.home5 #rs-slider .slide-content .slider-desc,
.home5 #rs-slider .slide-content .sl-get-started-btn {
  position: relative;
  z-index: 11;
}
.home5 #rs-slider .slide-content .sl-get-started-btn {
  border: none;
  background: #92278f;
  border-radius: 30px;
  font-weight: 700;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
}
.home5 #rs-slider .slide-content .sl-get-started-btn:hover {
  background: #550b53;
}
.home5 #rs-slider .owl-dots {
  display: none !important;
}
.rs-banner-section {
  position: relative;
  overflow: hidden;
}
.rs-banner-section img.banner-image {
  width: 100%;
}
.rs-banner-section .banner-inner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.rs-banner-section .banner-inner .sl-sub-title {
  margin-bottom: 2px;
  font-size: 56px;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 70px;
  color: #fff;
  font-weight: 600;
  font-style: italic;
}
.rs-banner-section .banner-inner .banner-title {
  font-size: 75px;
  line-height: 75px;
  margin: 0 0 25px;
  font-weight: 700;
  color: #fff;
}
.rs-banner-section .banner-inner .banner-title span {
  display: block;
  color: #ff3115;
}
.rs-banner-section .banner-inner .content {
  color: #ffffff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 40px;
}
.rs-banner-section .banner-inner .readon {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 16px;
  padding: 15px 25px;
}
.rs-banner-section .banner-text {
  max-width: 700px;
}
.rs-banner-section2 {
  position: relative;
  overflow: hidden;
}
.rs-banner-section2 img.banner-image {
  width: 100%;
}
.rs-banner-section2 .banner-inner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.rs-banner-section2 .banner-inner .sl-sub-title,
.rs-banner-section2 .banner-inner .banner-title,
.rs-banner-section2 .banner-inner .banner-title2 {
  color: #fff;
}
.rs-banner-section2 .banner-inner .sl-sub-title {
  font-size: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 50px;
  font-weight: 400;
}
.rs-banner-section2 .banner-inner .banner-title {
  margin: 0 0 6px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 90px;
  line-height: 1.1;
  letter-spacing: 5px;
}
.rs-banner-section2 .banner-inner .banner-title span {
  display: block;
  line-height: 1;
}
.rs-banner-section2 .banner-inner .content {
  color: #ffffff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 40px;
}
.rs-banner-section2 .banner-inner .readon {
  text-transform: uppercase;
  font-size: 17px;
  padding: 15px 45px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 30px;
  font-weight: 600;
}
.rs-banner-section2 .banner-inner .readon:hover {
  background: #ffffff;
  color: #ff3115;
}
.rs-banner-section2 .animate .circle1 {
  position: absolute;
  right: 40%;
  bottom: 50px;
  height: 150px;
  animation: swing-anim 2s alternate-reverse infinite;
  -webkit-animation: swing-anim 2s alternate-reverse infinite;
}
.rs-banner-section2 .animate .circle2 {
  position: absolute;
  right: 25%;
  top: 300px;
  text-align: right;
  width: 150px;
  animation: swing-anim2 2s alternate-reverse infinite;
  -webkit-animation: swing-anim2 2s alternate-reverse infinite;
}
.rs-banner-section3 {
  background: url(../images/bg/home8-banner-bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 100px;
  background-size: cover;
}
.rs-banner-section3 .countdown-part .sub-title {
  display: block;
  font-size: 18px;
  line-height: 34px;
  color: #ff3115;
  font-weight: 600;
}
.rs-banner-section3 .countdown-part .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}
.rs-banner-section3 .countdown-part .counter-wrap {
  max-width: 550px;
}
.rs-banner-section3 .countdown-part .counter-wrap .timecounter-inner .time_circles {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
}
.rs-banner-section3 .countdown-part .counter-wrap .timecounter-inner .time_circles canvas {
  opacity: 1;
}
.rs-banner-section3 .countdown-part .counter-wrap .timecounter-inner .time_circles div {
  position: absolute;
  text-align: left;
  padding-right: 0;
}
.rs-banner-section3 .countdown-part .counter-wrap .timecounter-inner .time_circles div span {
  padding: 0;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 40px !important;
  margin: 0 0 9px 0;
  color: #ff3115;
}
.rs-banner-section3 .countdown-part .counter-wrap .timecounter-inner .time_circles div h4 {
  color: #ffffff;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 17px;
  font-size: 12px !important;
}
.rs-banner-section3 .register-form {
  background: rgba(255, 255, 255, 0.6);
}
.rs-banner-section3 .register-form .form-title {
  position: relative;
  padding: 30px 65px 36px;
  margin-bottom: 50px;
}
.rs-banner-section3 .register-form .form-title .title {
  font-weight: 400;
  color: #fff;
  position: relative;
  z-index: 10;
  line-height: 1.4;
  font-size: 22px;
}
.rs-banner-section3 .register-form .form-title:after {
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(-115.5% 0, 113% 0, 76% 100%);
  left: 0;
  background-color: #ff3115;
  top: 0;
  content: "";
}
.rs-banner-section3 .register-form .form-group {
  padding: 0 50px 5px;
}
.rs-banner-section3 .register-form .form-group .from-control {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  padding: 10px 14px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: 0;
}
.rs-banner-section3 .register-form .form-group input[type=submit] {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  -webkit-appearance: button;
  cursor: pointer;
  background: #ff3115;
  border: 0;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  outline: 0;
  transition: 0.4s;
  font-weight: 600;
}
.rs-banner-section3 .register-form .form-group input[type=submit]:hover {
  color: #ffffff;
  background: #e41f05;
}
.rs-banner-section3 .register-form .form-group .select-option {
  position: relative;
}
.rs-banner-section3 .register-form .form-group .select-option select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: #b1b1b1;
  opacity: 1;
  z-index: 11;
  position: relative;
}
.rs-banner-section3 .register-form .form-group .select-option:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #b1b1b1;
  right: 15px;
  position: absolute;
  top: 11px;
  z-index: 12;
  font-size: 22px;
  pointer-events: none;
}
/* ------------------------------------
    06. Breadcrumbs
---------------------------------------*/
.rs-breadcrumbs {
  padding: 100px 0 35px;
}
.rs-breadcrumbs .page-title {
  margin: 80px 0 100px;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.rs-breadcrumbs ul {
  padding: 0;
}
.rs-breadcrumbs ul li {
  color: #ff3115;
  display: inline-block;
  font-weight: 400;
}
.rs-breadcrumbs ul li a {
  position: relative;
  padding-right: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #ffffff;
}
.rs-breadcrumbs ul li a:before,
.rs-breadcrumbs ul li a:after {
  background-color: rgba(255, 255, 255, 0.8);
  content: "";
  height: 15px;
  width: 2px;
  position: absolute;
  right: 7px;
  top: 2px;
  transform: rotate(26deg);
}
.rs-breadcrumbs ul li a:before {
  right: 13px;
}
.rs-breadcrumbs ul li a:hover {
  color: #ff3115;
}
.breadcrumbs-overlay {
  position: relative;
  z-index: 1;
}
.breadcrumbs-overlay:after {
  content: '';
  position: absolute;
  background-color: rgba(17, 17, 17, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
/* ------------------------------------
    07. Services
---------------------------------------*/
.rs-services-style7 .Services-wrap .Services-item {
  position: relative;
  overflow: hidden;
}
.rs-services-style7 .Services-wrap .Services-item:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  border-radius: 8px;
}
.rs-services-style7 .Services-wrap .Services-item .Services-icon img {
  border-radius: 8px;
}
.rs-services-style7 .Services-wrap .Services-item .Services-desc {
  position: absolute;
  bottom: 25px;
  left: 0;
  text-align: center;
  width: 100%;
  transition: all .5s ease 0s;
  z-index: 10;
}
.rs-services-style7 .Services-wrap .Services-item .Services-desc i {
  webkit-animation-name: rs-animation-scale-up;
  animation-name: rs-animation-scale-up;
  display: none;
}
.rs-services-style7 .Services-wrap .Services-item .Services-desc i:before {
  color: #ff3115;
  font-size: 40px;
}
.rs-services-style7 .Services-wrap .Services-item .Services-desc .services-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
}
.rs-services-style7 .Services-wrap .Services-item .Services-desc .services-title a {
  color: #ffffff;
}
.rs-services-style7 .Services-wrap .Services-item:hover:before {
  opacity: 1;
  visibility: visible;
}
.rs-services-style7 .Services-wrap .Services-item:hover .Services-desc {
  bottom: 50%;
  transform: translateY(50%);
}
.rs-services-style7 .Services-wrap .Services-item:hover .Services-desc i {
  display: block;
}
.rs-services-style7 .content-part .sub-text {
  display: block;
  font-size: 18px;
  line-height: 34px;
  color: #ff3115;
  font-weight: 600;
}
.rs-services-style7 .content-part .title {
  font-size: 30px;
  line-height: 40px;
  color: #212121;
  font-weight: 700;
  margin: 0;
}
.rs-services-style7 .content-part p {
  font-size: 16px;
  line-height: 27px;
  color: #505050;
  margin: 0;
}
/* ------------------------------------
    07. Services
---------------------------------------*/
.rs-services-style1 .services-item {
  background-color: #212121;
  padding: 25px 17px 17px;
  box-shadow: 0 4px 2px -2px #000000;
  z-index: 111;
  position: relative;
  top: -30px;
  transition: all 0.3s ease 0s;
}
.rs-services-style1 .services-item:hover {
  background-color: #ff3115;
}
.rs-services-style1 .services-item:hover .services-icon {
  background-color: #ff3115;
}
.rs-services-style1 .services-item:hover .services-icon,
.rs-services-style1 .services-item:hover .services-desc p {
  color: #fff;
}
.rs-services-style1 .services-desc {
  text-align: center;
  margin-top: 6px;
}
.rs-services-style1 .services-desc p {
  color: #cccccc;
  margin-bottom: 0;
}
.rs-services-style1 .services-desc .services-title {
  position: relative;
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 7px;
  z-index: 10;
}
.rs-services-style1 .services-icon {
  height: 130px;
  width: 130px;
  background-color: #212121;
  line-height: 88px;
  text-align: center;
  position: absolute;
  top: -50px;
  z-index: -1;
  font-size: 40px;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff3115;
  transition: all 0.3s ease 0s;
}
.rs-services-style1 .services-icon i {
  display: inline-block;
}
.home5 .rs-services-style1 .services-item {
  margin-top: 0;
  padding-bottom: 20px;
  border-radius: 0 0 10px 10px;
}
.home5 .rs-services-style1 .services-item.blue-color {
  box-shadow: 0px 4px 13px #046875;
}
.home5 .rs-services-style1 .services-item.blue-color,
.home5 .rs-services-style1 .services-item.blue-color .services-icon {
  background: #112170;
}
.home5 .rs-services-style1 .services-item.orange-color {
  box-shadow: 0px 4px 13px #9c510b;
}
.home5 .rs-services-style1 .services-item.orange-color,
.home5 .rs-services-style1 .services-item.orange-color .services-icon {
  background: #fc7f0c;
}
.home5 .rs-services-style1 .services-item.purple-color {
  box-shadow: 0px 4px 13px #50154f;
}
.home5 .rs-services-style1 .services-item.purple-color,
.home5 .rs-services-style1 .services-item.purple-color .services-icon {
  background: #92278f;
}
.home5 .rs-services-style1 .services-item.red-color {
  box-shadow: 0px 4px 13px #75231a;
}
.home5 .rs-services-style1 .services-item.red-color,
.home5 .rs-services-style1 .services-item.red-color .services-icon {
  background: #e84b3a;
}
.home5 .rs-services-style1 .services-item .services-icon {
  color: #fff;
  top: -50px;
  line-height: 90px;
  font-size: 40px;
}
.home5 .rs-services-style1 .services-item .services-desc {
  position: relative;
  z-index: 111;
  margin-top: 6px;
}
.home5 .rs-services-style1 .services-item .services-desc .services-title {
  margin-bottom: 6px;
  position: relative;
}
.home5 .rs-services-style1 .services-item .services-desc p {
  color: #eee;
}
/* ------------------------------------
    34. About Us Style8
---------------------------------------*/
.rs-about-style8 ul {
  list-style: none;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  padding-top: 0;
}
.rs-about-style8 ul li {
  flex-basis: 34%;
  position: relative;
  padding-left: 24px;
  padding-bottom: 7px;
}
.rs-about-style8 ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: "\f05d";
  font-family: FontAwesome;
  color: #ff3115;
  transition: all .5s ease;
}
.rs-about-style8 .author-section .course-author {
  display: flex;
  align-items: center;
}
.rs-about-style8 .author-section .align-img {
  margin-right: 20px;
}
.rs-about-style8 .author-section .align-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0;
}
.rs-about-style8 .author-section .author-content h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #212121;
}
.rs-about-style8 .author-section .author-content p {
  margin-bottom: 0;
}
/* ------------------------------------
    08. About Us Style
---------------------------------------*/
.rs-about-style7 .content-part .play-btn {
  position: relative;
}
.rs-about-style7 .content-part .play-btn .pulse-btn {
  position: absolute;
  background: #e41f05;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  font-size: 30px;
  color: #ffffff;
  z-index: 1;
}
.rs-about-style7 .content-part .play-btn .pulse-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: transparent;
  border: 2px solid #e41f05;
  border-radius: 50%;
  transition: all 200ms;
  animation: pulse-border 1500ms ease-out infinite;
}
.rs-about-style7 .content-part .play-btn .pulse-btn:after {
  content: "";
  position: absolute;
  z-index: -2;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  transition: all 200ms;
  border: 2px solid #e41f05;
}
.rs-about-style7 .content-part .sub-title {
  display: block;
  font-size: 18px;
  color: #ff3115;
  font-weight: 600;
}
.rs-about-style7 .content-part .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
}
.rs-about-style7 .content-part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
}
.rs-about-style8 .content-part .play-btn {
  position: relative;
}
.rs-about-style8 .content-part .play-btn .pulse-btn {
  position: absolute;
  background: #e41f05;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: #ffffff;
  z-index: 1;
}
.rs-about-style8 .content-part .play-btn .pulse-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: transparent;
  border: 2px solid #e41f05;
  border-radius: 50%;
  transition: all 200ms;
  animation: pulse-border 1500ms ease-out infinite;
}
.rs-about-style8 .content-part .play-btn .pulse-btn:after {
  content: "";
  position: absolute;
  z-index: -2;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  transition: all 200ms;
  border: 2px solid #e41f05;
}
.rs-about-style8 .content-part .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
}
/* ------------------------------------
    08. About Us
---------------------------------------*/
.rs-about .about-img {
  position: relative;
}
.rs-about .about-img:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.rs-about .about-img:hover:after {
  opacity: 1;
}
.rs-about .about-img .popup-youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.rs-about .about-img .popup-youtube:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f04b";
  font-size: 36px;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease 0s;
  display: inline-block;
  height: 80px;
  line-height: 80px;
  width: 80px;
  text-align: center;
  background: #ff3115;
  border-radius: 50%;
  padding-left: 8px;
  color: #fff;
}
.rs-about .about-desc {
  margin-bottom: 30px;
}
.rs-about .about-desc h2 {
  font-size: 30px;
  line-height: normal;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
}
.rs-about .about-desc p {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 0;
}
.vision-desc p:last-child,
.about-desc p:last-child {
  margin-bottom: 0;
}
.home5 .sec-title {
  margin-bottom: 20px;
}
.home5 .sec-title h3:after {
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.home5 .acdn-title {
  color: #92278f;
}
.home5 #accordion .card .card-header .acdn-title:not(.collapsed) {
  background: #92278f;
}
.rs-about-2 .sec-title h3 {
  font-size: 30px;
}
.rs-about-2 .about-signature h4 {
  margin-bottom: 0;
  font-size: 20px;
}
.rs-about-2 .about-signature span {
  font-size: 14px;
}
#rs-about {
  margin-top: -30px;
}
/* ------------------------------------
    30. Course Categories
---------------------------------------*/
.rs-courses-style7 .item {
  text-align: center;
  margin-bottom: 50px;
  border-radius: 8px;
  position: relative;
}
.rs-courses-style7 .item .cource-img img {
  border-radius: 8px;
}
.rs-courses-style7 .item .content-part {
  position: absolute;
  bottom: 30px;
  left: 0;
  text-align: center;
  width: 100%;
  transition: all .5s ease 0s;
  z-index: 10;
}
.rs-courses-style7 .item .content-part .btn-part {
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-courses-style7 .item .content-part .btn-part i:before {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  border-radius: 50%;
  font-size: 30px;
}
.rs-courses-style7 .item .content-part .cource-title {
  font-size: 23px;
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 600;
}
.rs-courses-style7 .item .content-part .cource-title a {
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
.rs-courses-style7 .item .content-part .courses-sub {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.rs-courses-style7 .item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
  opacity: 0;
  visibility: hidden;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
}
.rs-courses-style7 .item:hover .content-part .btn-part {
  opacity: 1 !important;
}
.rs-courses-style7 .item:hover .content-part .cource-title a {
  color: #212121;
}
.rs-courses-style7 .item:hover .content-part .courses-sub {
  color: #505050;
}
.rs-courses-style7 .item:hover:after {
  opacity: 0.8;
  visibility: visible;
}
/* ------------------------------------
    09. rs courses style
---------------------------------------*/
.rs-courses .cource-item {
  border: 1px solid #dddddd;
}
.rs-courses .cource-item .cource-img {
  position: relative;
  margin-bottom: 22px;
}
.rs-courses .cource-item .cource-img img {
  width: 100%;
  transition: all 0.3s ease 0s;
}
.rs-courses .cource-item .cource-img .course-value {
  position: absolute;
  bottom: -25px;
  right: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
  z-index: 1;
}
.rs-courses .cource-item .course-body {
  text-align: center;
  padding: 0 20px;
}
.rs-courses .cource-item .course-body .course-category {
  display: inline-block;
}
.rs-courses .cource-item .course-body .course-title {
  font-size: 20px;
  margin-bottom: 7px;
}
.rs-courses .cource-item .course-body .course-title a {
  color: #212121;
}
.rs-courses .cource-item .course-body .course-title a:hover {
  color: rgba(33, 33, 33, 0.6);
}
.rs-courses .cource-item .course-body .review-wrap .rating {
  display: inline-block;
  color: #ff3115;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #ddd;
  line-height: 10px;
}
.rs-courses .cource-item .course-body .review-wrap .review {
  line-height: 10px;
  display: inline-block;
}
.rs-courses .cource-item .course-body .course-desc {
  margin-top: 10px;
}
.rs-courses .cource-item .course-body .course-desc p {
  margin: 0 0 22px;
}
.rs-courses .cource-item .course-footer {
  padding: 15px 20px;
  background-color: #f0f0f0;
  text-align: center;
}
.rs-courses .cource-item .course-footer div {
  display: inline-block;
  text-align: center;
  position: relative;
  line-height: 19px;
}
.rs-courses .cource-item .course-footer div span {
  display: block;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
}
.rs-courses .cource-item .course-footer div span.label {
  font-weight: 700;
}
.rs-courses .cource-item .course-footer div div {
  padding-left: 8px;
  margin-left: 8px;
  border-left: 1px solid #ccc;
}
.rs-courses .cource-item.blue-color .course-footer {
  background: #112170;
}
.rs-courses .cource-item.orange-color .course-footer {
  background: #fc7f0c;
}
.rs-courses .cource-item.purple-color .course-footer {
  background: #92278f;
}
.rs-courses .cource-item.red-color .course-footer {
  background: #e84b3a;
  box-shadow: 0 12px 20px #f4f5ff;
}
.rs-courses.rs-courses-style6 .cource-item {
  margin-bottom: 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.06) !important;
  background: #ffffff !important;
  min-height: 375px;
  border-radius: 8px;
}
.rs-courses.rs-courses-style6 .cource-item .cource-img img {
  border-radius: 8px;
}
.rs-courses.rs-courses-style6 .cource-item .cource-img .course-value {
  bottom: 0;
  right: 0;
  top: 20px;
  height: 30px;
  width: 85px;
  line-height: 30px;
  text-align: center;
  background-color: #ff3115;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px 0 0 5px;
}
.rs-courses.rs-courses-style6 .cource-item .cource-img .img-part img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ff3115;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.rs-courses.rs-courses-style6 .cource-item .course-body {
  background: #ffffff !important;
}
.rs-courses.rs-courses-style6 .cource-item .course-body span {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
}
.rs-courses.rs-courses-style6 .cource-item .course-body span a {
  color: #505050;
}
.rs-courses.rs-courses-style6 .cource-item .course-body span a:hover {
  color: #ff3115;
}
.rs-courses.rs-courses-style6 .cource-item .course-body .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
}
.rs-courses.rs-courses-style6 .cource-item .course-body .title a {
  color: #212121;
}
.rs-courses.rs-courses-style6 .cource-item .course-body .title a:hover {
  color: #ff3115;
}
.rs-courses.rs-courses-style6 .cource-item .course-footer {
  background: none;
}
.rs-courses.rs-courses-style6 .cource-item .course-footer .courses-seats {
  margin-right: 30px;
}
.rs-courses.rs-courses-style6 .cource-item .course-footer .courses-seats i:before {
  color: #505050;
  font-size: 18px;
  padding-right: 5px;
}
.rs-courses.rs-courses-style6 .cource-item .course-footer .review-wrap .rating li {
  color: #ff3115;
  font-size: 18px;
}
.rs-courses.rs-courses-style7-new2 .cource-item {
  margin-bottom: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.06) !important;
  background: #ffffff !important;
  min-height: 345px;
  border-radius: 0 0 8px 4px !important;
}
.rs-courses.rs-courses-style7-new2 .cource-item .cource-img .course-value {
  bottom: 0;
  right: 0;
  height: 30px;
  width: 85px;
  line-height: 30px;
  text-align: center;
  background-color: #ff3115;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px 0 0 5px;
}
.rs-courses.rs-courses-style7-new2 .cource-item .cource-img .img-part img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ff3115;
  position: absolute;
  bottom: -20px;
  left: 45px;
  transform: translateX(-50%);
  z-index: 1;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body {
  background: #ffffff !important;
  text-align: left;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body span {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body span a {
  color: #505050;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body span a:hover {
  color: #ff3115;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body .title a {
  color: #212121;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-body .title a:hover {
  color: #ff3115;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-footer {
  background: none;
  text-align: left;
  padding-bottom: 20px;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-footer .courses-seats {
  margin-right: 30px;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-footer .courses-seats i:before {
  color: #505050;
  font-size: 18px;
  padding-right: 5px;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-footer .review-wrap .rating li {
  color: #ff3115;
  font-size: 18px;
}
.rs-courses.rs-courses-style7-new2 .cource-item .course-footer .desc {
  font-size: 15px;
  color: #505050;
  line-height: 27px;
  margin: 0;
}
.event-item .events-details {
  margin-top: -20px;
  padding: 15px;
}
.event-item .events-details.white-bg {
  background: #fff;
}
.event-item .event-img,
.cource-item .event-img,
.event-item .cource-img,
.cource-item .cource-img {
  position: relative;
}
.event-item .event-img:after,
.cource-item .event-img:after,
.event-item .cource-img:after,
.cource-item .cource-img:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.event-item .event-img .image-link,
.cource-item .event-img .image-link,
.event-item .cource-img .image-link,
.cource-item .cource-img .image-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  color: #ff3115;
  z-index: 11;
}
.event-item .event-img .image-link:hover,
.cource-item .event-img .image-link:hover,
.event-item .cource-img .image-link:hover,
.cource-item .cource-img .image-link:hover {
  color: #fff;
}
.event-item .event-img .image-link i,
.cource-item .event-img .image-link i,
.event-item .cource-img .image-link i,
.cource-item .cource-img .image-link i {
  font-size: 22px;
  font-weight: 400;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s ease 0s;
}
.event-item:hover .event-img:after,
.cource-item:hover .event-img:after,
.event-item:hover .cource-img:after,
.cource-item:hover .cource-img:after {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.event-item:hover .event-img .image-link i,
.cource-item:hover .event-img .image-link i,
.event-item:hover .cource-img .image-link i,
.cource-item:hover .cource-img .image-link i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.home5 .rs-courses .cource-item {
  border-color: #eee;
  background: #eee;
  border-radius: 0 0 30px 30px;
}
.home5 .rs-courses .cource-item .cource-img {
  margin-bottom: 0;
}
.home5 .rs-courses .cource-item .course-body {
  padding-top: 30px;
  background: #eee;
}
.home5 .rs-courses .cource-item .course-body .course-title a {
  color: #92278f;
}
.home5 .rs-courses .cource-item .course-footer {
  border-radius: 30px;
}
.home5 .rs-courses .cource-item .course-footer > div span {
  color: #fff;
}
.rs-courses-2 {
  padding-bottom: 70px;
}
.rs-courses-2 .cource-item {
  margin-bottom: 30px;
  border: 1px solid #dddddd;
}
.rs-courses-2 .cource-item .cource-img {
  position: relative;
  overflow: hidden;
}
.rs-courses-2 .cource-item .cource-img img {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 100%;
}
.rs-courses-2 .cource-item .cource-img .course-value {
  position: absolute;
  bottom: -25px;
  right: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 50%;
}
.rs-courses-2 .cource-item .course-body {
  padding: 20px 25px 25px;
  transition: all 0.5s ease 0s;
}
.rs-courses-2 .cource-item .course-body .course-category {
  display: inline-block;
}
.rs-courses-2 .cource-item .course-body .course-title {
  font-size: 20px;
  margin-bottom: 7px;
}
.rs-courses-2 .cource-item .course-body .course-title a {
  color: #212121;
  transition: all 0.35s ease 0s;
}
.rs-courses-2 .cource-item .course-body .course-title a:hover {
  color: rgba(33, 33, 33, 0.6);
}
.rs-courses-2 .cource-item .course-body .course-desc {
  margin-top: 12px;
}
.rs-courses-2 .cource-item .course-body .course-desc p {
  margin-bottom: 0;
  transition: all 0.35s ease 0s;
}
.rs-courses-2 .cource-item .cource-btn {
  background-color: #ff3115;
  border: 2px solid #ff3115;
  display: inline-block;
  margin-top: 20px;
  padding: 2px 10px;
  font-size: 11px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
.rs-courses-2 .cource-item .cource-btn:hover {
  background-color: #e41f05;
  border-color: #e41f05;
}
.rs-courses-2 .cource-item:hover .course-body {
  background-color: #212121;
}
.rs-courses-2 .cource-item:hover .course-body .course-title a {
  color: #fff;
}
.rs-courses-2 .cource-item:hover .course-body p {
  color: rgba(255, 255, 255, 0.7);
}
.rs-courses-2 .cource-item:hover .cource-btn {
  background-color: #fff;
  border-color: #fff;
  color: #ff3115;
}
.rs-courses-2 .cource-item:hover .cource-btn:hover {
  background-color: #e41f05;
  border-color: #e41f05;
  color: #fff;
}
.rs-courses-2 .cource-item:hover .cource-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.8;
}
.rs-courses-2 .view-more {
  bottom: auto;
}
.rs-courses-2 .container {
  overflow: hidden;
}
.rs-courses-2 .row.grid {
  min-height: 500px !important;
}
.rs-courses-3 .row.grid {
  min-height: 500px;
  overflow: hidden;
}
.rs-courses-3 .course-item {
  margin-bottom: 30px;
  border: 1px solid #dddddd;
  transition: all 0.35s ease 0s;
}
.rs-courses-3 .course-item .course-img {
  position: relative;
  margin: -1px -1px 0 -1px;
}
.rs-courses-3 .course-item .course-img:before {
  background: rgba(0, 0, 0, 0.5);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.rs-courses-3 .course-item .course-img img {
  width: 100%;
}
.rs-courses-3 .course-item .course-img .course-value {
  position: absolute;
  bottom: -30px;
  right: 22px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
}
.rs-courses-3 .course-item .course-toolbar {
  position: absolute;
  width: 100%;
  bottom: 15px;
  padding: 0 20px;
}
.rs-courses-3 .course-item .course-toolbar .course-category {
  margin-bottom: 3px;
}
.rs-courses-3 .course-item .course-toolbar .course-category a {
  font-size: 24px;
  color: #ffffff;
}
.rs-courses-3 .course-item .course-toolbar .course-category a:hover {
  color: #ff3115;
}
.rs-courses-3 .course-item .course-toolbar .course-date,
.rs-courses-3 .course-item .course-toolbar .course-duration {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
}
.rs-courses-3 .course-item .course-toolbar .course-date i,
.rs-courses-3 .course-item .course-toolbar .course-duration i {
  margin-right: 3px;
}
.rs-courses-3 .course-item .course-toolbar .course-duration {
  margin-left: 12px;
}
.rs-courses-3 .course-item .course-body .course-title {
  font-size: 20px;
  margin-bottom: 7px;
}
.rs-courses-3 .course-item .course-body .course-title a {
  color: #212121;
}
.rs-courses-3 .course-item .course-body .course-title a:hover {
  color: rgba(33, 33, 33, 0.6);
}
.rs-courses-3 .course-item .course-body .course-desc {
  padding: 20px;
}
.rs-courses-3 .course-item .course-body .course-desc p {
  margin-bottom: 0;
  transition: all 0.35s ease 0s;
}
.rs-courses-3 .course-item:hover {
  box-shadow: 0 6px 40px rgba(0, 0, 0, 0.1);
}
.rs-courses-3 .course-item:hover .course-img:before {
  background: rgba(0, 0, 0, 0.5);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
.rs-courses-3 .course-footer {
  padding: 15px 20px;
  background-color: #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
}
.rs-courses-3 .course-footer .course-button a {
  color: #505050;
  display: inline-block;
  position: relative;
  padding-right: 12px;
}
.rs-courses-3 .course-footer .course-button a:hover {
  color: #ff3115;
}
.rs-courses-3 .course-footer .course-button a:after {
  font-family: fontAwesome;
  content: "\f101";
  position: absolute;
  right: 0;
}
.rs-courses-3 .view-more {
  bottom: auto;
}
.rs-courses-list .course-item {
  margin-bottom: 40px;
}
.rs-courses-list .course-item .course-img {
  position: relative;
}
.rs-courses-list .course-item .course-img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.8);
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-courses-list .course-item .course-img .image-link {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  line-height: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  font-size: 18px;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-courses-list .course-item .course-img .image-link:hover {
  background-color: #e41f05;
}
.rs-courses-list .course-item .course-img:hover:before {
  opacity: 1;
}
.rs-courses-list .course-item .course-img:hover .image-link {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.rs-courses-list .course-category {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.rs-courses-list .course-category a {
  color: #505050;
}
.rs-courses-list .course-title {
  font-size: 24px;
  margin-bottom: 7px;
}
.rs-courses-list .course-title a {
  color: #212121;
}
.rs-courses-list .course-title a:hover {
  color: #ff3115;
}
.rs-courses-list .course-date,
.rs-courses-list .course-value {
  display: inline-block;
  font-size: 14px;
  color: #777777;
  font-weight: 600;
}
.rs-courses-list .course-value {
  margin-left: 12px;
}
.rs-courses-list .course-value span {
  color: #ff3115;
}
.rs-courses-list .course-body {
  margin-top: 12px;
}
.rs-courses-list .course-button a {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  min-width: 170px;
  padding: 0 20px;
  background-color: #ff3115;
  color: #fff;
  transition: all 0.3s ease 0s;
  font-weight: 500;
}
.rs-courses-list .course-button a:hover {
  background-color: #e41f05;
}
.gridFilter {
  margin-bottom: 35px;
}
.gridFilter button {
  background: transparent;
  border: 0;
  font-size: 15px;
  font-weight: 700;
  outline: none;
  color: #505050;
  cursor: pointer;
}
.gridFilter button.active {
  color: #ff3115;
}
.gridFilter button + button {
  margin-left: 20px;
}
.rs-courses-categories .container {
  overflow: hidden;
}
.rs-courses-categories .container .col-lg-3 {
  margin-bottom: 30px;
}
.rs-courses-categories .courses-item {
  text-align: center;
  background-color: #f0f0f0;
  padding: 30px 30px 22px;
}
.rs-courses-categories .courses-item .courses-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 18px;
}
.rs-courses-categories .courses-item .courses-title a {
  color: #212121;
}
.rs-courses-categories .courses-item .courses-title a:hover {
  color: #ff3115;
}
.rs-courses-categories .courses-item .courses-amount {
  font-size: 13px;
  font-weight: 600;
  color: #505050;
}
.rs-courses-categories .courses-item i {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  border-radius: 50%;
  font-size: 36px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
}
.rs-courses-categories .courses-item:hover {
  background-color: #d0d0d0;
}
.rs-courses-categories .courses-item:hover i {
  background-color: #212121;
  color: #ff3115;
  transform: rotate(360deg);
}
.rs-learning-objectives .container {
  overflow: hidden;
}
.rs-learning-objectives .container .col-lg-3 {
  margin-bottom: 30px;
}
.rs-learning-objectives .courses-item {
  text-align: center;
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 65px 15px 15px;
  margin-bottom: 30px;
  transition: 0.4s;
}
.rs-learning-objectives .courses-item .courses-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 18px;
}
.rs-learning-objectives .courses-item .courses-title a {
  color: #212121;
}
.rs-learning-objectives .courses-item .courses-title a:hover {
  color: #ff3115;
}
.rs-learning-objectives .courses-item i {
  color: #ff3115;
  font-size: 60px;
}
.rs-learning-objectives .courses-item:hover {
  border-color: #ff3115;
}
.sidebar-area .title {
  font-size: 20px;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  margin-bottom: 25px;
}
.sidebar-area .search-box {
  margin-bottom: 45px;
}
.sidebar-area .search-box .form-control {
  border-radius: 0;
  box-shadow: none;
  color: #101010;
  padding: 5px 15px;
  height: 45px;
}
.sidebar-area .search-box .box-search {
  position: relative;
}
.sidebar-area .search-box .btn {
  position: absolute;
  right: 3px;
  background: transparent;
  border: none;
  box-shadow: none;
  top: 50%;
  outline: none;
  transform: translateY(-50%);
}
.sidebar-area .cate-box {
  margin-bottom: 45px;
}
.sidebar-area .cate-box ul {
  padding: 0;
  margin: 0;
}
.sidebar-area .cate-box ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  cursor: pointer;
}
.sidebar-area .cate-box ul li a {
  color: #505050;
  padding: 0 0 0 5px;
}
.sidebar-area .cate-box ul li a span {
  float: right;
}
.sidebar-area .cate-box ul li:first-child {
  padding-top: 0;
}
.sidebar-area .cate-box ul li:hover i,
.sidebar-area .cate-box ul li:hover a {
  color: #ff3115;
}
.sidebar-area .latest-courses {
  margin-bottom: 45px;
}
.sidebar-area .latest-courses .post-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}
.sidebar-area .latest-courses .post-item .post-img {
  width: 33.33%;
  transition: all 0.3s ease 0s;
  float: left;
}
.sidebar-area .latest-courses .post-item .post-desc {
  width: 66.66%;
  padding-left: 20px;
  float: left;
}
.sidebar-area .latest-courses .post-item .post-desc h4 {
  margin-bottom: 5px;
}
.sidebar-area .latest-courses .post-item .post-desc h4 a {
  font-size: 16px;
  color: #212121;
}
.sidebar-area .latest-courses .post-item .post-desc h4 a:hover {
  color: #ff3115;
}
.sidebar-area .latest-courses .post-item .post-desc .duration {
  font-weight: 600;
  color: #777777;
}
.sidebar-area .latest-courses .post-item .post-desc .price {
  margin-left: 8px;
  font-weight: 600;
  color: #777777;
}
.sidebar-area .latest-courses .post-item .post-desc .price span {
  color: #ff3115;
}
.sidebar-area .latest-courses .post-item + .post-item {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}
.sidebar-area .latest-courses .post-item:hover .post-img {
  opacity: 0.7;
}
.sidebar-area .tags-cloud {
  margin-bottom: 39px;
}
.sidebar-area .tags-cloud ul {
  padding: 0;
  margin: 0;
}
.sidebar-area .tags-cloud ul li {
  float: left;
  list-style: none;
}
.sidebar-area .tags-cloud ul li a {
  border: 1px solid #d7d7d7;
  padding: 5px 13px;
  margin: 0 6px 6px 0;
  display: block;
  color: #505050;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 28px;
}
.sidebar-area .tags-cloud ul li a:hover {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #ffffff;
}
.sidebar-area .newsletter {
  background: #f0f0f0;
  text-align: center;
  padding: 30px 40px;
}
.sidebar-area .newsletter h4 {
  font-size: 20px;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  margin: 0;
}
.sidebar-area .newsletter p {
  font-size: 15px;
  color: #505050;
  margin: 7px 0 20px;
}
.sidebar-area .newsletter .box-newsletter {
  position: relative;
}
.sidebar-area .newsletter .box-newsletter .form-control {
  border-radius: 0;
  box-shadow: none;
  color: #212121;
  padding: 5px 20px;
  height: 50px;
  border-color: #ff3115;
}
.sidebar-area .newsletter .box-newsletter .btn {
  position: absolute;
  right: 3px;
  background: #ff3115;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #ffffff;
  display: block;
  padding: 11px 17px;
  border-radius: 0;
  text-align: center;
}
.sidebar-area .newsletter .box-newsletter .btn:hover {
  background: #e41f05;
}
.sidebar-area .course-features-info {
  margin-bottom: 20px;
  background: #212121;
  padding: 15px;
  color: #fff;
}
.sidebar-area .course-features-info .desc-title {
  color: #ff3115;
  margin: 10px 0 8px;
}
.sidebar-area .course-features-info ul li {
  display: block;
  overflow: hidden;
  padding: 10px 0;
}
.sidebar-area .course-features-info ul li + li {
  border-top: 1px solid #414141;
}
.sidebar-area .course-features-info ul li i {
  color: #ff3115;
}
.sidebar-area .course-features-info ul li .label {
  padding-left: 10px;
}
.sidebar-area .course-features-info ul li .value {
  float: right;
  padding-right: 5px;
}
.space-bt30 {
  margin-bottom: 30px;
}
.space-bt30 .event-item {
  margin-bottom: 0 !important;
}
.rs-search-courses {
  padding: 70px 0;
  position: relative;
  margin-top: -100px;
  z-index: 10;
}
.rs-search-courses:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  background-color: #212121;
  z-index: -1;
  display: block;
}
.rs-search-courses select,
.rs-search-courses button,
.rs-search-courses input {
  width: 100%;
  height: 60px;
  padding: 0 15px;
  color: #777;
  border: none;
}
.rs-search-courses ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  opacity: 1 !important;
}
.rs-search-courses ::-moz-placeholder {
  /* Firefox 19+ */
  color: #777;
  opacity: 1 !important;
}
.rs-search-courses :-ms-input-placeholder {
  /* IE 10+ */
  color: #777;
  opacity: 1 !important;
}
.rs-search-courses :-moz-placeholder {
  /* Firefox 18- */
  color: #777;
  opacity: 1 !important;
}
.rs-search-courses select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.rs-search-courses .categories,
.rs-search-courses .level {
  position: relative;
}
.rs-search-courses .categories:before,
.rs-search-courses .level:before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f0d7";
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.rs-search-courses .search-btn button {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.rs-search-courses .search-btn button:hover,
.rs-search-courses .search-btn button:focus {
  background-color: #e41f05;
}
/* ------------------------------------
    10. Courses Details
---------------------------------------*/
.rs-courses-details .detail-img {
  position: relative;
  margin-bottom: 40px;
}
.rs-courses-details .detail-img img {
  transition: all 0.3s ease 0s;
}
.rs-courses-details .detail-img:hover img {
  opacity: 0.9;
}
.rs-courses-details .detail-img .course-seats {
  position: absolute;
  bottom: 25px;
  right: 25px;
  font-size: 15px;
  width: 78px;
  height: 78px;
  line-height: 21px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff3115;
  color: #fff;
  font-weight: 600;
  padding-top: 18px;
}
.rs-courses-details .detail-img .course-seats span {
  display: block;
}
.rs-courses-details .detail-img .course-seats.price {
  right: auto;
  left: 25px;
  padding-top: 0;
  line-height: 78px;
}
.rs-courses-details .course-title {
  font-size: 24px;
  margin-bottom: 30px;
}
.rs-courses-details .course-instructor {
  padding: 20px 25px 16px;
  background-color: #f9f9f9;
  position: relative;
}
.rs-courses-details .course-instructor p {
  margin-bottom: 0;
}
.rs-courses-details .course-instructor .instructor-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
}
.rs-courses-details .instructor-inner {
  display: -webkit-flex;
  display: flex;
}
.rs-courses-details .instructor-inner .instructor-img img {
  height: 100px;
  width: 100px;
  transition: all 0.3s ease 0s;
}
.rs-courses-details .instructor-inner .instructor-img:hover img {
  opacity: 0.8;
}
.rs-courses-details .instructor-inner .instructor-body {
  padding-left: 18px;
}
.rs-courses-details .instructor-inner .instructor-body .name {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0;
}
.rs-courses-details .instructor-inner .instructor-body .designation {
  font-size: 14px;
  font-weight: 600;
}
.rs-courses-details .instructor-inner .instructor-body .social-icon {
  margin-top: 15px;
}
.rs-courses-details .instructor-inner .instructor-body .social-icon a i {
  font-size: 17px;
  margin-right: 6px;
}
.rs-courses-details .short-desc {
  margin-top: 15px;
}
.rs-courses-details .info-list {
  font-weight: 600;
  font-size: 15px;
}
.rs-courses-details .info-list ul li {
  color: #777777;
  margin-bottom: 6px;
}
.rs-courses-details .info-list ul li span {
  color: #212121;
}
.rs-courses-details .apply-btn {
  position: absolute;
  right: 16px;
  bottom: 10px;
}
.rs-courses-details .apply-btn a {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  min-width: 147px;
  padding: 0 20px;
  background-color: #ff3115;
  color: #fff;
  transition: all 0.3s ease 0s;
  font-weight: 600;
}
.rs-courses-details .apply-btn a:hover {
  background-color: #e41f05;
}
.rs-courses-details .course-desc {
  margin-top: 30px;
}
.rs-courses-details .course-desc .desc-title {
  font-size: 19px;
  margin-bottom: 12px;
}
.rs-courses-details .course-desc .desc-text p {
  margin-bottom: 18px;
}
.rs-courses-details .course-desc .share-area {
  padding: 15px;
  border: 1px solid #ccc;
}
.rs-courses-details .course-desc .share-area h3 {
  margin-bottom: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 15px;
}
.rs-courses-details .course-desc .share-area .share-inner {
  text-align: right;
}
.rs-courses-details .course-desc .share-area .share-inner a {
  display: inline-block;
  min-width: 88px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 13px;
  color: #505050;
  padding: 0 12px;
  font-weight: 600;
}
.rs-courses-details .course-desc .share-area .share-inner a + a {
  margin-left: 8px;
}
.rs-courses-details .course-desc .share-area .share-inner a:hover {
  border-color: #ff3115;
  color: #ff3115;
}
.rs-courses-details .course-desc .course-syllabus {
  padding: 15px 0 35px;
}
.rs-courses-details .course-desc .course-syllabus h3.desc-title {
  padding-bottom: 10px;
}
.rs-courses-details .course-desc .course-syllabus ul.syllabus {
  padding-left: 15px;
}
.rs-courses-details .course-desc .course-syllabus ul.syllabus li {
  display: block;
  padding-top: 6px;
}
.rs-courses-details .course-desc .course-syllabus ul.syllabus li strong {
  padding-right: 5px;
  display: block;
}
.rs-courses-details .course-desc .course-syllabus ul.syllabus li span {
  padding-left: 20px;
  position: relative;
}
.rs-courses-details .course-desc .course-syllabus ul.syllabus li span:before {
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 10px;
  left: 0;
  position: absolute;
  color: #505050;
  top: 0;
}
.rs-courses-details .rs-testimonial .testimonial-item {
  text-align: center;
}
.rs-courses-details .rs-testimonial .testimonial-item .testi-desc:after,
.rs-courses-details .rs-testimonial .testimonial-item .testi-desc:before {
  display: none;
}
.rs-courses-details .rs-testimonial .testimonial-item .cl-client-rating i {
  color: #ffaa30;
}
.rs-courses-details ul.course-meta-style li {
  float: left;
  padding-right: 30px;
  position: relative;
}
.rs-courses-details ul.course-meta-style li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 1px;
  height: 40px;
  background: #e1e1e1;
}
.rs-courses-details ul.course-meta-style li:last-child {
  padding-right: 0;
}
.rs-courses-details ul.course-meta-style li:last-child:before {
  display: none;
}
.rs-courses-details ul.course-meta-style li.author {
  overflow: hidden;
}
.rs-courses-details ul.course-meta-style li.author .author-name,
.rs-courses-details ul.course-meta-style li.author .image {
  float: left;
}
.rs-courses-details ul.course-meta-style li.author .author-name {
  padding-left: 15px;
}
.rs-courses-details ul.course-meta-style li a {
  color: #212121;
  font-weight: 600;
}
.rs-courses-details ul.course-meta-style li i {
  color: #ffaa30;
}
.rs-courses-details .btn-area {
  text-align: right;
}
.rs-courses-details .btn-area a {
  background: #ff3115;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 20px;
}
.rs-courses-details .btn-area a:hover {
  background: #e41f05;
}
.rs-courses-details .course-des-tabs {
  margin-top: 15px;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan {
  margin: 0;
  border: 0;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan .nav-item {
  width: 25%;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan .nav-item a {
  border: 1px solid #eee;
  background: #f9f9f9;
  border-right: 0;
  color: #212121;
  font-weight: 600;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan .nav-item a.active {
  position: relative;
  background: #fff;
  border-bottom: none;
  color: #ff3115;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan .nav-item a.active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 3px;
  top: 0;
  height: 2px;
  z-index: 10;
  width: 100%;
  background: #ff3115;
}
.rs-courses-details .course-des-tabs .tab-btm .tabs-cyan .nav-item:last-child a {
  border-right: 1px solid #eee;
}
.rs-courses-details .course-des-tabs .tab-content {
  border-radius: 0;
  border: 1px solid #eee;
  border-top: 0;
  padding: 60px 30px;
}
.rs-courses-details .course-des-tabs .tab-content .tab-pane h4.desc-title {
  padding-bottom: 15px;
  position: relative;
}
.rs-courses-details .course-des-tabs .tab-content .tab-pane h4.desc-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 50px;
  background: #ff3115;
}
.rs-courses-details .course-des-tabs .tab-content .tab-pane ul.requirements-list li {
  position: relative;
  padding-left: 20px;
}
.rs-courses-details .course-des-tabs .tab-content .tab-pane ul.requirements-list li:before {
  border: none;
  color: #ff3115;
  content: "\f101";
  font-family: fontawesome;
  font-size: 14px !important;
  font-size: 9px;
  margin-right: 10px;
  padding: 0;
  left: 0;
  position: absolute;
  top: 1px;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list {
  overflow: hidden;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .image {
  float: left;
  padding-right: 20px;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .author-name {
  overflow: hidden;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .author-name h4 {
  margin: 0 0 5px;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .author-name span {
  margin: 0 0 10px;
  display: block;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .author-name i {
  color: #ffaa30;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list p.dsc {
  display: block;
  clear: both;
  padding-top: 15px;
  margin-bottom: 0;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .social-icon li {
  display: inline-block;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .social-icon li a {
  display: block;
  background: #ff3115;
  padding: 3px 0;
  width: 40px;
  text-align: center;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .social-icon li a i {
  color: #fff;
}
.rs-courses-details .course-des-tabs .tab-content .instructor-list .social-icon li a:hover {
  background: #ff3115;
}
/* ------------------------------------
    11. Rs-counter
---------------------------------------*/
.rs-counter-style7 .rs-counter-list {
  display: flex;
  text-align: left;
}
.rs-counter-style7 .rs-counter-list .icon-part {
  margin-top: 30px;
  margin-right: 25px;
}
.rs-counter-style7 .rs-counter-list .icon-part i:before {
  font-size: 60px;
  color: #ff3115;
}
.rs-counter-style7 .rs-counter-list .text-part .counter-number {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 6px;
}
.rs-counter-style7 .rs-counter-list .text-part .counter-desc {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 20px;
  text-transform: uppercase;
}
.rs-counter-style8 .rs-counter-list {
  display: flex;
  text-align: left;
}
.rs-counter-style8 .rs-counter-list .icon-part {
  margin-top: 30px;
  margin-right: 20px;
}
.rs-counter-style8 .rs-counter-list .icon-part i:before {
  font-size: 60px;
  color: #ff3115;
}
.rs-counter-style8 .rs-counter-list .text-part .counter-number {
  font-size: 45px;
  color: #212121;
  margin: 0;
  padding-bottom: 10px;
}
.rs-counter-style8 .rs-counter-list .text-part .counter-desc {
  font-size: 20px;
  color: #505050;
}
/* ------------------------------------
    11. OUR BEST
---------------------------------------*/
.rs-our-best .rs-calltoaction .sub-title {
  display: block;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #ff3115;
}
.rs-our-best .rs-calltoaction .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #212121;
  margin: 0;
}
.rs-our-best .rs-calltoaction .desc {
  font-size: 15px;
  line-height: 27px;
  color: #505050;
  margin: 0;
}
/* ------------------------------------
    11. Rs-counter
---------------------------------------*/
.rs-counter .rs-counter-list {
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
  text-align: center;
  padding: 23px;
  transition: .3s all ease;
}
.rs-counter .rs-counter-list .counter-number {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 6px;
}
.rs-counter .rs-counter-list .counter-desc {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 20px;
  text-transform: uppercase;
}
.rs-counter .rs-counter-list:hover {
  border-color: #ff3115;
}
.rs-counter .counter-title {
  color: #fff;
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 25px;
}
.rs-counter .counter-text p {
  margin-bottom: 35px;
  color: rgba(255, 255, 255, 0.6);
}
.rs-counter .counter-img {
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.1);
}
.rs-counter .counter-img img {
  border-radius: 8px;
}
.rs-counter-style2 .rs-counter-list {
  border: none;
  padding: 0;
}
.rs-counter-style2 .count-icon i {
  font-size: 60px;
  color: #ff3115;
}
.home5 .rs-counter .rs-counter-list {
  border-radius: 30px;
  border: none;
  background: #92278f;
}
.home5 .rs-counter .rs-counter-list .counter-number {
  color: #fff;
}
.home5 .rs-counter .rs-counter-list:hover {
  border-color: #92278f;
}
.home5 .rs-counter .rs-counter-list.blue-color {
  background: #269aa9;
}
.home5 .rs-counter .rs-counter-list.orange-color {
  background: #fc7f0c;
}
.home5 .rs-counter .rs-counter-list.red-color {
  background: #e84b3a;
}
/* ------------------------------------
    12. Upcoming Events
---------------------------------------*/
.rs-events .event-item .event-img {
  margin-bottom: 20px;
}
.rs-events .event-item .event-date {
  font-size: 14px;
  font-weight: 500;
}
.rs-events .event-item .event-date i {
  font-size: 13px;
  display: inline-block;
  margin-right: 5px;
  color: #ff3115;
}
.rs-events .event-item .event-date span {
  color: #505050;
}
.rs-events .event-item .event-title {
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 4px;
}
.rs-events .event-item .event-title a {
  color: #212121;
}
.rs-events .event-item .event-title a:hover {
  color: #ff3115;
}
.rs-events .event-item .event-meta {
  font-size: 14px;
}
.rs-events .event-item .event-meta > div {
  display: inline-block;
}
.rs-events .event-item .event-meta > div i {
  font-size: 14px;
  display: inline-block;
  margin-right: 3px;
  color: #ff3115;
}
.rs-events .event-item .event-meta .event-time {
  margin-right: 10px;
}
.rs-events .event-item .event-btn {
  margin-top: 18px;
}
.rs-events .event-item .event-btn a {
  font-weight: 500;
}
.rs-events-2 .event-item {
  margin-bottom: 30px;
  background: #f9f9f9;
  border: none;
}
.rs-events-2 .event-item .row {
  margin: 0;
}
.rs-events-2 .event-item [class*="col-"] {
  padding: 0;
}
.rs-events-2 .event-item .event-content {
  padding: 15px 20px 20px 0;
}
.rs-events-2 .event-item .event-img {
  margin: -1px 0 -1px -1px;
  margin-right: 20px;
  transition: all 0.3s ease 0s;
}
.rs-events-2 .event-item .event-img:hover {
  opacity: 0.8;
}
.rs-events-2 .event-item .event-location {
  font-size: 13px;
}
.rs-events-2 .event-item .event-location i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  color: #ff3115;
}
.rs-events-2 .event-item .event-location span {
  color: #505050;
}
.rs-events-2 .event-item .event-title {
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 1px;
  line-height: 25px;
  font-weight: 600;
  text-transform: capitalize;
}
.rs-events-2 .event-item .event-title a {
  color: #212121;
}
.rs-events-2 .event-item .event-meta {
  font-size: 13px;
}
.rs-events-2 .event-item .event-meta > div {
  display: inline-block;
}
.rs-events-2 .event-item .event-meta > div i {
  font-size: 15px;
  display: inline-block;
  margin-right: 3px;
  color: #ff3115;
}
.rs-events-2 .event-item .event-meta .event-time {
  margin-left: 10px;
}
.rs-events-2 .event-item .event-desc {
  margin-top: 7px;
}
.rs-events-2 .event-item .event-desc p {
  margin-bottom: 15px;
}
.rs-events-2 .event-item .event-btn a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  min-width: 130px;
  padding: 0 15px;
  background: #ff3115;
  color: #fff;
  transition: all 0.3s ease 0s;
  font-weight: 600;
}
.rs-events-2 .event-item .event-btn a:hover {
  background-color: #e41f05;
  color: #fff;
}
.home5 .event-item .event-date {
  max-width: 170px;
  margin: 0 auto;
  height: 30px;
  background: #92278f;
  margin-top: -32px;
  position: relative;
  top: -13px;
  border-radius: 15px 15px 0 0;
  padding-top: 2px;
  text-align: center;
}
.home5 .event-item .event-date i,
.home5 .event-item .event-date span {
  color: #fff;
}
.home5 .red-color .event-date {
  background: #e84b3a;
}
.home5 .orange-color .event-date {
  background: #fc7f0c;
}
.home5 .blue-color .event-date {
  background: #112170;
}
.rs-event-details #googleMap {
  height: 270px;
  margin-bottom: 30px;
}
.rs-event-details .event-title {
  font-size: 24px;
  margin-bottom: 6px;
}
.rs-event-details .event-title a {
  color: #212121;
}
.rs-event-details .event-title a:hover {
  color: #ff3115;
}
.rs-event-details .event-meta {
  margin-top: 10px;
  margin-bottom: 30px;
}
.rs-event-details .event-meta > div {
  display: inline-block;
  color: #666;
}
.rs-event-details .event-meta > div + div {
  margin-left: 14px;
}
.rs-event-details .event-meta > div i {
  margin-right: 3px;
  color: #ff3115;
}
.rs-event-details .event-img {
  margin-bottom: 25px;
}
.rs-event-details .share-area .share-inner {
  text-align: right;
}
.rs-event-details .share-area .share-inner span {
  display: inline-block;
  margin-right: 5px;
  color: #101010;
  font-weight: 600;
}
.rs-event-details .share-area .share-inner a i {
  border: 1px solid #ddd;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #212121;
  transition: all 0.3s ease 0s;
}
.rs-event-details .share-area .share-inner a i:hover {
  border-color: #ff3115;
  color: #ff3115;
}
.rs-event-details .share-area .book-btn a {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  text-align: center;
  min-width: 147px;
  padding: 0 20px;
  border: 2px solid #ff3115;
  color: #212121;
  transition: all 0.3s ease 0s;
  font-weight: 600;
}
.rs-event-details .share-area .book-btn a:hover {
  background-color: #ff3115;
  color: #ffffff;
}
/* ------------------------------------
    14. Countdown
---------------------------------------*/
.rs-latest-news-style7 .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #212121;
  margin: 0;
}
.rs-latest-news-style7 .rs-latest-list {
  padding: 50px;
  background: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item {
  display: flex;
  align-items: center;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-img {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 30px;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-img img {
  border-radius: 6px;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-content .news-date span {
  font-size: 15px;
  color: #505050;
  font-weight: 500;
  line-height: 26px;
  display: block;
  padding-bottom: 8px;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-content .news-title {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 600;
  padding-bottom: 18px;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-content .news-title a {
  color: #212121;
}
.rs-latest-news-style7 .rs-latest-list .latest-wrap .news-list-block .news-list-item .news-content .news-title a:hover {
  color: #ff3115;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new {
  display: flex;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-date {
  margin-right: 30px;
  min-width: 125px;
  border-radius: 10px;
  height: 110px;
  background: #f2f2f2;
  text-align: center;
  display: table;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-date .vertical-align {
  display: table-cell;
  vertical-align: middle;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-date .vertical-align .day {
  font-size: 50px;
  font-weight: 600;
  color: #ff3115;
  margin: 0 0 6px;
  line-height: 40px;
  display: block;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-date .vertical-align .month {
  font-size: 20px;
  color: #505050;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-des .title {
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 15px;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-des .title a {
  color: #212121;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-des .title a:hover {
  color: #ff3115;
}
.rs-latest-news-style7 .rs-latest-list .event-item-new .event-des p {
  font-size: 15px;
  color: #505050;
  line-height: 27px;
  margin: 0;
}
/* ------------------------------------
    14. PUBLICATION
---------------------------------------*/
.rs-our-publication .owl-stage-outer {
  padding-bottom: 30px;
}
.rs-our-publication .inner-shadow {
  padding: 0 2px;
}
.rs-our-publication .inner-shadow .product-item {
  box-shadow: 0 0 3px #ccc;
  text-align: center;
  transition: all .3s ease 0s;
  background-color: #fff;
  padding-bottom: 40px;
}
.rs-our-publication .inner-shadow .product-item .img-box {
  overflow: hidden;
  margin-bottom: 32px;
}
.rs-our-publication .inner-shadow .product-item .img-box img {
  transform: scale(1);
  transition: all 0.3s ease 0s;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-title {
  font-size: 20px;
  margin-bottom: 3px;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-title a {
  color: #212121;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-title a:hover {
  color: #ff3115;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-price {
  margin-bottom: 20px;
  font-size: 16px;
  color: #444;
  display: block;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-btn a {
  background: #ff3115;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 30px;
  text-transform: uppercase;
}
.rs-our-publication .inner-shadow .product-item .content-part .product-btn a:hover {
  color: #ffffff;
  background: #e41f05;
}
.rs-our-publication .inner-shadow:hover .product-item {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
.rs-our-publication .inner-shadow:hover .product-item .img-box img {
  transform: scale(1.1);
}
/* ------------------------------------
    14. Countdown
---------------------------------------*/
.rs-countdown-part .countdown-part .sub-title {
  display: block;
  font-size: 18px;
  line-height: 34px;
  color: #ff3115;
  font-weight: 600;
}
.rs-countdown-part .countdown-part .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
  color: #ffffff;
}
.rs-countdown-part .countdown-part .description {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  color: #ffffff;
}
.rs-countdown-part .countdown-part .counter-wrap {
  max-width: 550px;
}
.rs-countdown-part .countdown-part .counter-wrap .timecounter-inner .time_circles {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
}
.rs-countdown-part .countdown-part .counter-wrap .timecounter-inner .time_circles canvas {
  opacity: 1;
}
.rs-countdown-part .countdown-part .counter-wrap .timecounter-inner .time_circles div {
  position: absolute;
  text-align: left;
  padding-right: 0;
}
.rs-countdown-part .countdown-part .counter-wrap .timecounter-inner .time_circles div span {
  padding: 0;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 40px !important;
  margin: 0 0 9px 0;
  color: #ff3115;
}
.rs-countdown-part .countdown-part .counter-wrap .timecounter-inner .time_circles div h4 {
  color: #ffffff;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 17px;
  font-size: 12px !important;
}
.rs-countdown-part .register-form {
  background: #fff;
}
.rs-countdown-part .register-form .form-title {
  position: relative;
  padding: 30px 65px 36px;
  margin-bottom: 50px;
}
.rs-countdown-part .register-form .form-title .title {
  font-weight: 400;
  color: #fff;
  position: relative;
  z-index: 10;
  line-height: 1.4;
  font-size: 22px;
}
.rs-countdown-part .register-form .form-title:after {
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(-115.5% 0, 113% 0, 76% 100%);
  left: 0;
  background-color: #ff3115;
  top: 0;
  content: "";
}
.rs-countdown-part .register-form .form-group {
  padding: 0 50px 5px;
}
.rs-countdown-part .register-form .form-group .from-control {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  padding: 10px 14px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: 0;
}
.rs-countdown-part .register-form .form-group input[type=submit] {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  -webkit-appearance: button;
  cursor: pointer;
  background: #ff3115;
  border: 0;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  outline: 0;
}
.rs-countdown-part .register-form .form-group input[type=submit]:hover {
  color: #ffffff;
  background: #e41f05;
}
.rs-countdown-part .register-form .form-group .select-option {
  position: relative;
}
.rs-countdown-part .register-form .form-group .select-option select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: #b1b1b1;
  opacity: 1;
  z-index: 11;
  position: relative;
}
.rs-countdown-part .register-form .form-group .select-option:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #b1b1b1;
  right: 15px;
  position: absolute;
  top: 11px;
  z-index: 12;
  font-size: 22px;
  pointer-events: none;
}
/* ------------------------------------
    13. Team Member
---------------------------------------*/
.rs-team-style7 .owl-stage-outer {
  padding-bottom: 30px;
}
.rs-team-style7 .item .item-team .team-img {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.rs-team-style7 .item .item-team .team-img:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: .3s all ease;
  position: absolute;
  opacity: 0;
}
.rs-team-style7 .item .item-team .team-img .social-icon {
  width: 240px;
  height: 55px;
  background: #ff3115;
  line-height: 58px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  bottom: -50px;
  position: absolute;
  margin: 0 auto;
  visibility: hidden;
  transition: 0.3s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.rs-team-style7 .item .item-team .team-img .social-icon a {
  background: #ff3115;
  line-height: 58px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  margin: 0 15px;
}
.rs-team-style7 .item .item-team .team-img .social-icon a i {
  color: #ffffff;
  margin: 4px;
}
.rs-team-style7 .item .item-team .team-img .social-icon a i:hover {
  color: #212121;
}
.rs-team-style7 .item .item-team .team-content {
  border-radius: 0 0 10px 10px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.06);
  text-align: center;
}
.rs-team-style7 .item .item-team .team-content .team-name {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.rs-team-style7 .item .item-team .team-content .team-name a {
  color: #212121;
}
.rs-team-style7 .item .item-team .team-content .team-name a:hover {
  color: #ff3115;
}
.rs-team-style7 .item .item-team .team-content .sub-title {
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  color: #505050;
  display: block;
}
.rs-team-style7 .item .item-team:hover .team-img:after {
  opacity: 1;
}
.rs-team-style7 .item .item-team:hover .team-img .social-icon {
  bottom: 0;
  visibility: visible;
}
/* ------------------------------------
    13. Team Member
---------------------------------------*/
.rs-team-style8 .owl-stage-outer {
  padding-bottom: 10px;
}
.rs-team-style8 .item .item-team .team-img {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.rs-team-style8 .item .item-team .team-img:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: .3s all ease;
  position: absolute;
  opacity: 0;
}
.rs-team-style8 .item .item-team .team-img .social-icon {
  width: 240px;
  height: 55px;
  line-height: 58px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  bottom: -50px;
  position: absolute;
  margin: 0 auto;
  visibility: hidden;
  transition: 0.3s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.rs-team-style8 .item .item-team .team-img .social-icon a {
  margin: 4px;
  color: #fff;
}
.rs-team-style8 .item .item-team .team-img .social-icon a i {
  color: #ffffff;
  margin: 4px;
}
.rs-team-style8 .item .item-team .team-img .social-icon a i:hover {
  color: #212121;
}
.rs-team-style8 .item .item-team .team-content {
  border-radius: 0 0 10px 10px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.06);
  text-align: center;
}
.rs-team-style8 .item .item-team .team-content .team-name {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.rs-team-style8 .item .item-team .team-content .team-name a {
  color: #212121;
}
.rs-team-style8 .item .item-team .team-content .team-name a:hover {
  color: #ff3115;
}
.rs-team-style8 .item .item-team .team-content .sub-title {
  font-size: 14px;
  line-height: 27px;
  font-weight: 600;
  color: #505050;
  display: block;
}
.rs-team-style8 .item .item-team:hover .team-img:after {
  opacity: 1;
}
.rs-team-style8 .item .item-team:hover .team-img .social-icon {
  bottom: 0;
  visibility: visible;
}
/* ------------------------------------
    13. Experienced Staffs
---------------------------------------*/
.rs-team .team-item {
  position: relative;
  overflow: hidden;
}
.rs-team .team-item .team-img {
  position: relative;
}
.rs-team .team-item .team-img .normal-text {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  min-width: 175px;
  background-color: rgba(34, 34, 34, 0.8);
  transition: .3s ease all;
}
.rs-team .team-item .team-img .normal-text .team-name {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  display: block;
}
.rs-team .team-item .team-img .normal-text .subtitle {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  display: block;
}
.rs-team .team-item .team-img img {
  width: 100%;
}
.rs-team .team-item .team-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 0;
  text-align: center;
  z-index: 11;
  padding: 30px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.rs-team .team-item .team-content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  -webkit-transition: .25s opacity ease;
  transition: .25s opacity ease;
}
.rs-team .team-item .team-name {
  margin-bottom: 2px;
}
.rs-team .team-item .team-name a {
  margin-bottom: 6px;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  z-index: 111;
}
.rs-team .team-item .team-name a:hover {
  color: #fff;
}
.rs-team .team-item .team-title {
  position: relative;
  z-index: 111;
  font-size: 14px;
  color: #fff;
  padding-bottom: 12px;
}
.rs-team .team-item .team-title:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: #ff3115;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.rs-team .team-item .team-desc {
  color: #fff;
  margin-bottom: 0;
  padding-top: 12px;
  margin-top: 15px;
}
.rs-team .team-item .team-social {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 111;
  opacity: 0;
  margin-top: 25px;
  -webkit-transition: .4s all ease;
  transition: .4s all ease;
}
.rs-team .team-item .team-social .social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ff3115;
  margin-right: 6px;
  border-radius: 50%;
}
.rs-team .team-item .team-social .social-icon i {
  font-size: 14px;
  color: #fff;
}
.rs-team .team-item .team-social .social-icon:hover {
  background-color: #e41f05;
}
.rs-team .team-item:hover .team-content {
  opacity: 1;
  top: 0;
}
.rs-team .team-item:hover .team-social {
  opacity: 1;
}
.rs-team .team-item:hover .normal-text {
  opacity: 0;
  visibility: hidden;
}
.rs-team-2 .team-item {
  margin-bottom: 30px;
}
.rs-team-2 .team-item .team-img {
  position: relative;
  overflow: hidden;
}
.rs-team-2 .team-item .team-img .social-icon {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: .8s all ease;
}
.rs-team-2 .team-item .team-img .social-icon a i {
  margin-right: 8px;
  font-size: 20px;
}
.rs-team-2 .team-item .team-img:before {
  content: '';
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: transparent;
  border: 5px solid rgba(255, 255, 255, 0.7);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: .5s all ease;
}
.rs-team-2 .team-item .team-img:hover .social-icon {
  opacity: 1;
  visibility: visible;
}
.rs-team-2 .team-item .team-img:hover .social-icon a:hover {
  color: #fff;
}
.rs-team-2 .team-item .team-img:hover:before {
  transform: translateX(-50%) translateY(-50%) rotate(-270deg);
  border: 0;
  background: rgba(33, 33, 33, 0.8);
}
.rs-team-2 .team-item .team-body {
  padding: 18px;
  text-align: center;
  background-color: #f0f0f0;
}
.rs-team-2 .team-item .team-body .name {
  font-size: 20px;
  margin-bottom: 2px;
  color: #212121;
  transition: all 0.3s ease 0s;
}
.rs-team-2 .team-item .team-body .name:hover {
  color: #ff3115;
}
.rs-team-2 .team-item .team-body .designation {
  font-size: 15px;
}
.rs-team-2.team-page {
  padding-top: 93px;
}
.rs-team-2 .row.grid {
  min-height: 400px !important;
  overflow: hidden;
}
.team-all .row {
  margin-bottom: 30px;
}
.team-all .row .team-item {
  margin-bottom: 0;
}
.home5 .rs-team .team-item {
  margin-bottom: 30px;
  overflow: inherit;
}
.home5 .rs-team .team-item .team-desc {
  font-size: 14px;
  line-height: 22px;
}
.home5 .rs-team .team-item .team-social .social-icon {
  background: #92278f;
}
.home5 .rs-team .team-item .team-social .social-icon:hover {
  background: #5d0b5b;
}
.home5 .rs-team .team-item .team-img .normal-text {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
  border-radius: 30px;
  width: 200px;
  padding: 15px 0 0;
  height: 70px;
  bottom: -30px;
}
.pagination {
  margin-top: 25px;
}
.pagination .page-item > * {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  padding: 0;
  font-weight: 600;
  color: #505050;
  outline: none;
}
.pagination .page-item > *:hover,
.pagination .page-item > *.active {
  color: #ff3115;
}
.pagination .page-item > *.dotted {
  line-height: 40px;
}
/* ------------------------------------
    14. Staffs Single
---------------------------------------*/
.rs-team-single .team-name {
  font-size: 30px;
  margin-bottom: 10px;
}
.rs-team-single .team-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 5px;
}
.rs-team-single .team-title span {
  display: block;
}
.rs-team-single .team-contact {
  margin-bottom: 15px;
}
.rs-team-single .team-icons {
  padding: 30px;
  text-align: center;
}
.rs-team-single .team-icons a {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #f0f0f0;
  margin-right: 15px;
  border-radius: 2px;
  transition: all 0.3s ease 0s;
}
.rs-team-single .team-icons a:hover {
  background-color: #ff3115;
  color: #fff;
}
.rs-team-single .rs-progress {
  height: 8px;
  border: 1px solid #ff3115;
  border-radius: 0;
  overflow: visible;
  padding: 1px;
  background: #fff;
  margin-top: 25px;
}
.rs-team-single .rs-progress + .rs-progress {
  margin-top: 50px;
}
.rs-team-single .rs-progress .progress-bar {
  position: relative;
  text-align: left;
  line-height: 4px;
  border-radius: 0;
  box-shadow: none;
  background-color: #ff3115;
}
.rs-team-single .rs-progress .progress-bar .pb-label {
  position: absolute;
  left: 0px;
  top: -24px;
  color: #666;
  font-size: 15px;
  font-weight: 600;
}
.rs-team-single .rs-progress .progress-bar .pb-percent {
  position: absolute;
  right: -13px;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: -30px;
  background: #ff3115;
  padding: 8px 5px;
}
.rs-team-single .rs-progress .progress-bar .pb-percent:after {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  left: 50%;
  border-top-color: #ff3115;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* ------------------------------------
    15. Calltoaction
---------------------------------------*/
.rs-calltoaction .cta-content .cta-subtitle {
  margin-bottom: 10px;
  color: #ff3115;
}
.rs-calltoaction .cta-content .cta-title {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 32px;
}
.rs-calltoaction .cta-content p {
  color: #ffffff;
}
.rs-calltoaction .cta-button {
  display: inline-block;
  text-decoration: none;
  background-color: #ff3115;
  color: #ffffff;
  font-weight: 500;
  min-width: 170px;
  line-height: 45px;
  height: 45px;
  text-align: center;
  text-transform: uppercase;
}
.rs-calltoaction .cta-button:hover,
.rs-calltoaction .cta-button:focus,
.rs-calltoaction .cta-button:active {
  background-color: #e41f05;
  color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.04);
}
.rs-webdevelopment-course .cta-subtitle {
  margin-bottom: 10px;
  color: #ff3115;
}
.rs-webdevelopment-course .cta-title {
  margin-bottom: 12px;
  color: #ffffff;
  font-size: 32px;
}
.rs-webdevelopment-course .cta-button {
  display: inline-block;
  text-decoration: none;
  background-color: #ff3115;
  color: #ffffff;
  font-weight: 500;
  min-width: 170px;
  line-height: 45px;
  height: 45px;
  text-align: center;
  text-transform: uppercase;
}
.rs-webdevelopment-course .cta-button:hover,
.rs-webdevelopment-course .cta-button:focus,
.rs-webdevelopment-course .cta-button:active {
  background-color: #e41f05;
  color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.04);
}
/* ------------------------------------
    16. Latest News
---------------------------------------*/
.rs-latest-news .news-normal-block {
  padding: 15px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  transition: all 0.3s ease 0s;
}
.rs-latest-news .news-normal-block:hover {
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
.rs-latest-news .news-normal-block .news-img {
  margin-bottom: 20px;
}
.rs-latest-news .news-normal-block .news-img img {
  width: 100%;
}
.rs-latest-news .news-normal-block .news-date {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.rs-latest-news .news-normal-block .news-date i {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-right: 4px;
  color: #ff3115;
}
.rs-latest-news .news-normal-block .news-date span {
  color: #505050;
}
.rs-latest-news .news-normal-block .news-title {
  font-size: 20px;
  margin-bottom: 12px;
}
.rs-latest-news .news-normal-block .news-title a {
  color: #212121;
}
.rs-latest-news .news-normal-block .news-title a:hover,
.rs-latest-news .news-normal-block .news-title a:focus {
  color: #ff3115;
}
.rs-latest-news .news-normal-block .news-desc p {
  margin-bottom: 20px;
}
.rs-latest-news .news-normal-block .news-btn a {
  display: inline-block;
  margin-left: auto;
  padding: 4px 16px;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  background-color: #ff3115;
  color: #fff;
}
.rs-latest-news .news-normal-block .news-btn a:hover {
  background-color: #e41f05;
}
.rs-latest-news .news-list-block {
  overflow: hidden;
}
.rs-latest-news .news-list-block .news-list-item {
  overflow: hidden;
  padding: 15px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  transition: all 0.3s ease 0s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.rs-latest-news .news-list-block .news-list-item:hover {
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
.rs-latest-news .news-list-block .news-list-item + .news-list-item {
  margin-top: 23px;
}
.rs-latest-news .news-list-block .news-list-item .news-img {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  float: left;
}
.rs-latest-news .news-list-block .news-list-item .news-content {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  float: left;
}
.rs-latest-news .news-list-block .news-list-item .news-date {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.rs-latest-news .news-list-block .news-list-item .news-date i {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-right: 4px;
  color: #ff3115;
}
.rs-latest-news .news-list-block .news-list-item .news-date span {
  color: #505050;
}
.rs-latest-news .news-list-block .news-list-item .news-title {
  font-size: 16px;
  margin-bottom: 7px;
}
.rs-latest-news .news-list-block .news-list-item .news-title a {
  color: #212121;
}
.rs-latest-news .news-list-block .news-list-item .news-title a:hover,
.rs-latest-news .news-list-block .news-list-item .news-title a:focus {
  color: #ff3115;
}
.rs-latest-news .news-list-block .news-list-item .news-btn a {
  display: inline-block;
  margin-left: auto;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background-color: #ff3115;
  color: #fff;
}
.rs-latest-news .news-list-block .news-list-item .news-btn a:hover {
  background-color: #e41f05;
}
.rs-latest-news .news-list-block .news-list-item .news-desc p {
  margin-bottom: 0;
}
.latest-news-slider .slick-arrow {
  position: absolute;
  z-index: 11;
  bottom: 0;
  right: -104px;
  height: 42px;
  width: 42px;
  font-size: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ff3115;
  cursor: pointer;
  outline: 0;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  line-height: 0;
}
.latest-news-slider .slick-arrow:after {
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 22px;
}
.latest-news-slider .slick-arrow.slick-prev:after {
  content: "\f104";
}
.latest-news-slider .slick-arrow.slick-next {
  right: -160px;
}
.latest-news-slider .slick-arrow.slick-next:after {
  content: "\f105";
}
.latest-news-slider .slick-arrow:hover {
  background-color: #ff3115;
  color: #fff;
}
.latest-news-nav .slick-track {
  width: 100% !important;
}
.latest-news-nav .slick-slide {
  width: 100% !important;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.1);
  padding: 8px;
  transition: all 0.3s ease 0s;
}
.latest-news-nav .slick-slide.slick-current,
.latest-news-nav .slick-slide:hover {
  background-color: #ff3115;
  border-color: #ff3115;
}
.latest-news-nav .slick-slide.slick-current img,
.latest-news-nav .slick-slide:hover img {
  opacity: 0.8;
}
/* ------------------------------------
    17. Our Publications
---------------------------------------*/
.rs-products .owl-stage-outer {
  padding-bottom: 30px;
}
.rs-products .product-item {
  text-align: center;
  transition: all 0.3s ease 0s;
  background-color: #ffffff;
}
.rs-products .product-item:hover {
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}
.rs-products .product-item .product-img {
  margin-bottom: 17px;
  overflow: hidden;
}
.rs-products .product-item .product-img img {
  width: 100%;
  transition: all 0.3s ease 0s;
}
.rs-products .product-item .product-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.rs-products .product-item .product-title {
  font-size: 20px;
  margin-bottom: 3px;
}
.rs-products .product-item .product-title a {
  color: #212121;
}
.rs-products .product-item .product-title a:hover,
.rs-products .product-item .product-title a:focus,
.rs-products .product-item .product-title a:active {
  color: #ff3115;
}
.rs-products .product-item .product-price {
  font-size: 16px;
  color: #444;
  display: block;
}
.rs-products .product-item .product-btn {
  margin-top: 20px;
  padding-bottom: 25px;
}
.rs-products .product-item .product-btn a {
  display: inline-block;
  margin-left: auto;
  padding: 2px 15px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #212121;
  color: #212121;
}
.rs-products .product-item .product-btn a:hover {
  border-color: #ff3115;
  background-color: #ff3115;
  color: #fff;
}
.rs-products .view-btn {
  text-align: center;
  margin-top: 20px;
}
.rs-products .view-btn a {
  display: inline-block;
  margin-left: auto;
  min-width: 170px;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background-color: #ff3115;
  color: #fff;
}
.rs-products .view-btn a:hover {
  background-color: #e41f05;
}
/* ------------------------------------
    18. Testimonial
---------------------------------------*/
.rs-testimonial .owl-stage-outer {
  padding-top: 50px;
  padding-bottom: 55px;
}
.rs-testimonial .testimonial-item {
  padding: 35px;
  background-color: #ffffff;
}
.rs-testimonial .testimonial-item .testi-img {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  padding: 5px;
  background: #fff;
  border-radius: 50%;
}
.rs-testimonial .testimonial-item .testi-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.rs-testimonial .testimonial-item .testi-desc {
  padding: 42px 0;
  position: relative;
}
.rs-testimonial .testimonial-item .testi-desc .testi-name {
  font-size: 20px;
  margin-bottom: 10px;
}
.rs-testimonial .testimonial-item .testi-desc p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.rs-testimonial .testimonial-item .testi-desc:before,
.rs-testimonial .testimonial-item .testi-desc:after {
  position: absolute;
  font-family: FontAwesome;
  font-size: 26px;
  color: #ff3115;
}
.rs-testimonial .testimonial-item .testi-desc:before {
  content: "\f10d";
  top: 0;
  left: 0;
}
.rs-testimonial .testimonial-item .testi-desc:after {
  content: "\f10e";
  bottom: 0;
  right: 0;
}
.rs-testimonial .owl-controls .owl-dots {
  position: absolute;
  left: 50%;
  bottom: -8px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.rs-testimonial .owl-controls .owl-dots .owl-dot {
  height: 6px;
  width: 22px;
  background-color: #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.rs-testimonial .owl-controls .owl-dots .owl-dot + .owl-dot {
  margin-left: 8px;
}
.rs-testimonial .owl-controls .owl-dots .owl-dot.active {
  background-color: #ff3115;
}
.rs-testimonial .owl-controls .owl-dots .owl-dot:first-child {
  border-radius: 3px 0 0 3px;
}
.rs-testimonial .owl-controls .owl-dots .owl-dot:last-child {
  border-radius: 0 3px 3px 0;
}
.rs-testimonial-2 .owl-stage-outer {
  padding-top: 55px;
  padding-bottom: 30px;
}
.rs-testimonial-2 .testimonial-item {
  padding: 35px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin: 0 3px;
}
.rs-testimonial-2 .testimonial-item .testi-img {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  padding: 5px;
  background: #fff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.rs-testimonial-2 .testimonial-item .testi-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.rs-testimonial-2 .testimonial-item .testi-desc {
  padding: 42px 0;
  position: relative;
}
.rs-testimonial-2 .testimonial-item .testi-desc .testi-name {
  font-size: 20px;
  margin-bottom: 15px;
}
.rs-testimonial-2 .testimonial-item .testi-desc p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.rs-testimonial-2 .testimonial-item .testi-desc:before,
.rs-testimonial-2 .testimonial-item .testi-desc:after {
  position: absolute;
  font-family: FontAwesome;
  font-size: 26px;
  color: #ff3115;
}
.rs-testimonial-2 .testimonial-item .testi-desc:before {
  content: "\f10d";
  top: 0;
  left: 0;
}
.rs-testimonial-2 .testimonial-item .testi-desc:after {
  content: "\f10e";
  bottom: 0;
  right: 0;
}
.rs-testimonial-2 .owl-controls .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.rs-testimonial-2 .owl-controls .owl-dots .owl-dot {
  height: 6px;
  width: 22px;
  background-color: #fff;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.rs-testimonial-2 .owl-controls .owl-dots .owl-dot + .owl-dot {
  margin-left: 8px;
}
.rs-testimonial-2 .owl-controls .owl-dots .owl-dot.active {
  background-color: #ff3115;
}
.rs-testimonial-2 .owl-controls .owl-dots .owl-dot:first-child {
  border-radius: 3px 0 0 3px;
}
.rs-testimonial-2 .owl-controls .owl-dots .owl-dot:last-child {
  border-radius: 0 3px 3px 0;
}
.rs-testimonial-3 .testimonial-style3 {
  padding-top: 70px;
  margin: 50px 0 0;
  border: 1px solid #e0e0e0;
  text-align: center;
  position: relative;
  transition: all 0.7s ease 0s;
  background: #e0e0e0;
}
.rs-testimonial-3 .testimonial-style3:hover {
  border-color: #ff3115;
}
.rs-testimonial-3 .testimonial-style3:hover .image {
  border-color: #ff3115;
}
.rs-testimonial-3 .testimonial-style3:hover .testimonial-content {
  background-color: #ff3115;
}
.rs-testimonial-3 .testimonial-style3:hover .testimonial-content .testimonial-profile .name {
  color: #fff;
}
.rs-testimonial-3 .testimonial-style3:hover .testimonial-content .testimonial-profile .post {
  color: lightblue;
}
.rs-testimonial-3 .testimonial-style3:hover .testimonial-content .social-links li {
  background-color: #fff;
}
.rs-testimonial-3 .testimonial-style3:hover .testimonial-content .social-links li a {
  color: #ff3115;
}
.rs-testimonial-3 .testimonial-style3 .image {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.7s ease 0s;
  border: 5px solid #f2f2f2;
}
.rs-testimonial-3 .testimonial-style3 .image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.rs-testimonial-3 .testimonial-style3 .title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
.rs-testimonial-3 .testimonial-style3 .description {
  font-size: 15px;
  color: #000;
  line-height: 25px;
  padding: 0 25px 15px;
  margin: 0;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content {
  padding: 15px 25px;
  border-top: none;
  text-align: center;
  transition: all 500ms ease 0s;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .testimonial-profile .name {
  font-size: 16px;
  font-weight: bold;
  color: #ff3115;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 0;
  transition: all 700ms ease 0s;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .testimonial-profile .post {
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .rating {
  display: inline-block;
  margin-bottom: 14px;
  margin-top: 0;
  list-style: none;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .rating li {
  display: inline-block;
  font-size: 14px;
  color: #debe0f;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .social-links {
  padding: 0;
  margin: 0;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .social-links li {
  list-style: none;
  margin-right: 5px;
  display: inline-block;
  background-color: #7f352f;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .social-links li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}
.rs-testimonial-3 .testimonial-style3 .testimonial-content .social-links li a:hover {
  text-decoration: none;
  color: #150504;
}
.rs-testimonial-3 .owl-theme .owl-controls {
  width: 100%;
  position: absolute;
  top: 50%;
}
.rs-testimonial-3 .owl-theme .owl-controls .owl-buttons div {
  width: 45px;
  height: 40px;
  line-height: 37px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #ececec;
  padding: 0;
  opacity: 1;
  transition: all 0.4s ease-in-out 0s;
}
.rs-testimonial-3 .owl-theme .owl-controls .owl-buttons div:hover {
  background: #eabd44;
  border-color: #eabd44;
}
.rs-testimonial-3 .owl-prev,
.rs-testimonial-3 .owl-next {
  position: absolute;
  left: -3%;
}
.rs-testimonial-3 .owl-next {
  left: auto;
  right: -3%;
}
.rs-testimonial-3 .owl-prev:before {
  content: "\f104";
  font-family: "fontAwesome";
  font-size: 25px;
  color: #ececec;
}
.rs-testimonial-3 .owl-next:before {
  content: "\f105";
  font-family: "fontAwesome";
  font-size: 25px;
  color: #ececec;
}
.rs-testimonial-3 .owl-stage-outer {
  padding-top: 50px;
  padding-bottom: 60px;
}
.rs-testimonial-5 .testimonial-item {
  background: #92278f;
  border-radius: 30px;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
  padding: 45px 35px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}
.rs-testimonial-5 .testimonial-item .testi-img {
  top: -125px;
}
.rs-testimonial-5 .testimonial-item .testi-desc {
  padding: 0 30px;
}
.rs-testimonial-5 .testimonial-item .testi-desc .testi-name {
  font-size: 20px;
  margin-top: 4px;
}
.rs-testimonial-5 .testimonial-item .testi-desc .testi-name span {
  display: block;
  font-size: 15px;
  font-weight: 400;
  padding-top: 5px;
}
.rs-testimonial-5 .testimonial-item .testi-desc:before,
.rs-testimonial-5 .testimonial-item .testi-desc .testi-name,
.rs-testimonial-5 .testimonial-item .testi-desc .testi-name span,
.rs-testimonial-5 .testimonial-item .testi-desc p {
  color: #fff;
}
.rs-testimonial-5 .testimonial-item .testi-desc:before {
  display: none;
}
.rs-testimonial-5 .testimonial-item .testi-desc:after {
  display: none;
}
.rs-testimonial-5 .owl-stage-outer {
  padding-top: 125px;
  padding-bottom: 35px;
}
.rs-testimonial-5 .owl-controls .owl-dots .owl-dot.active {
  background: #92278f;
}
/* ------------------------------------
    19. Newsletter
---------------------------------------*/
.rs-newslatter .newslatter-title {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 30px;
}
.rs-newslatter .newslatter-form .form-input {
  width: calc(69% - 25px);
  height: 50px;
  padding: 0 17px;
  border: 0;
  font-size: 16px;
  margin-right: 25px;
}
.rs-newslatter .newslatter-form .form-button {
  width: 30%;
  height: 50px;
  border: 0;
  background-color: #ff3115;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
}
.rs-newslatter .newslatter-form .form-button:hover {
  background-color: #e41f05;
}
/* ------------------------------------
    20. Rs-video
---------------------------------------*/
.rs-video {
  padding: 150px 0;
  position: relative;
}
.rs-video:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.rs-video .video-content {
  text-align: center;
  position: relative;
  z-index: 11;
}
.rs-video .video-content a {
  display: inline-block;
  color: #ffffff;
  z-index: 99;
  border: 2px solid #ff3115;
  border-radius: 50%;
  width: 124px;
  height: 124px;
  margin: 0 auto;
  padding: 5px;
  margin-bottom: 30px;
}
.rs-video .video-content a i {
  font-size: 50px;
  line-height: 110px;
  border: 2px solid #fff;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  text-align: center;
  color: #ff3115;
  display: block;
  background: #fff;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
  margin-bottom: 24px;
  padding-left: 4px;
}
.rs-video .video-content a:hover i {
  border-color: #ff3115;
  background: transparent;
}
.rs-video .video-content span {
  display: block;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}
/* ------------------------------------
    21. Why Choose Us
---------------------------------------*/
.rs-why-choose .sec-title {
  margin-bottom: 44px;
}
.rs-why-choose .choose-item {
  text-align: center;
}
.rs-why-choose .choose-item .choose-title {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 25px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.rs-why-choose .choose-item i {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  border-radius: 50%;
  font-size: 36px;
}
/* ------------------------------------
    22. Pricing Table
---------------------------------------*/
.rs-pricing .pricing-plan {
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
}
.rs-pricing .pricing-plan .pricing-head {
  background-color: #f0f0f0;
  padding: 35px;
  border-radius: 4px 4px 0px 0px;
}
.rs-pricing .pricing-plan .pricing-head .name {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
  text-transform: uppercase;
}
.rs-pricing .pricing-plan .pricing-head .price {
  color: #444;
}
.rs-pricing .pricing-plan .pricing-head .price sup {
  font-size: 20px;
  line-height: 40px;
  top: -3px;
  margin-right: -7px;
  vertical-align: top;
  font-weight: 700;
}
.rs-pricing .pricing-plan .pricing-head .price .duration {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.rs-pricing .pricing-plan .pricing-head .value {
  font-size: 60px;
  line-height: 60px;
}
.rs-pricing .pricing-plan .pricing-body ul {
  padding-left: 0;
  list-style: none;
  font-size: 13px;
  color: #444;
  margin: 0;
}
.rs-pricing .pricing-plan .pricing-body ul li {
  padding: 15px 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #303030;
}
.rs-pricing .pricing-plan .pricing-body ul li:nth-child(even) {
  background-color: #f0f0f0;
  padding: 20px 0;
}
.rs-pricing .pricing-plan .pricing-footer .pricing-btn {
  display: block;
  padding: 20px;
  background-color: #212121;
  color: #fff;
  font-size: 15px;
  border-radius: 0 0 4px 4px;
  font-weight: 600;
}
.rs-pricing .pricing-plan:hover .pricing-head .price,
.rs-pricing .pricing-plan.featured .pricing-head .price,
.rs-pricing .pricing-plan:hover .pricing-head .name,
.rs-pricing .pricing-plan.featured .pricing-head .name {
  color: #ff3115;
}
.rs-pricing .pricing-plan:hover .pricing-footer .pricing-btn,
.rs-pricing .pricing-plan.featured .pricing-footer .pricing-btn {
  background-color: #ff3115;
}
.rs-pricing .pricing-plan:hover .pricing-footer .pricing-btn:hover,
.rs-pricing .pricing-plan.featured .pricing-footer .pricing-btn:hover {
  background-color: #e41f05;
}
/* ------------------------------------
    23. Instagram
---------------------------------------*/
.rs-instagram {
  position: relative;
}
.rs-instagram .instagram-desc {
  max-width: 630px;
  background: #ff3115;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 70px 0;
  z-index: 10;
  text-align: center;
}
.rs-instagram .instagram-desc .title {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 10px;
}
.rs-instagram .instagram-desc .sub-title {
  font-size: 30px;
  color: #101010;
  margin-bottom: 0;
}
.rs-instagram .instagram-item {
  margin-left: -1px;
}
.rs-instagram .instagram-item a {
  display: block;
  position: relative;
}
.rs-instagram .instagram-item a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.5);
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.rs-instagram .instagram-item:hover a:after {
  opacity: 0;
}
.rs-instagram .rs-carousel:last-child {
  margin-top: -1px;
}
/* ------------------------------------
    24. About Us Page
---------------------------------------*/
.abt-title h2 {
  font-size: 30px;
  line-height: normal;
  margin-bottom: 20px;
  padding-bottom: 16px;
  text-transform: uppercase;
  position: relative;
}
.abt-title h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100px;
  background-color: #ff3115;
}
.abt-title.text-center h2:after {
  left: 50%;
  transform: translateX(-50%);
}
.rs-vision .vision-img {
  position: relative;
}
.rs-vision .vision-img:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.rs-vision .vision-img:hover:after {
  opacity: 1;
}
.rs-vision .vision-img .popup-youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.rs-vision .vision-img .popup-youtube:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f04b";
  font-size: 36px;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease 0s;
  display: inline-block;
  height: 80px;
  line-height: 80px;
  width: 80px;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 50%;
  padding-left: 8px;
  color: #fff;
}
.rs-branches .branches-item {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.rs-branches .branches-item img {
  margin-bottom: 15px;
}
.rs-branches .branches-item h3 {
  font-size: 20px;
  margin-bottom: 7px;
}
.rs-branches .branches-item h3 span {
  display: block;
}
.rs-branches .branches-item p {
  margin-bottom: 0;
}
.rs-branches [class*="col-"] + [class*="col-"] .branches-item:after {
  position: absolute;
  content: '';
  width: 64%;
  height: 2px;
  background-color: #ff3115;
  left: -38%;
  top: 70px;
}
/* ------------------------------------
    25. Rs Timeline
---------------------------------------*/
.rs-timeline .rs-timeline-icon i {
  color: #ff3115;
}
.rs-timeline .rs-timeline-content .rs-read-more {
  border-color: #ff3115;
  color: #ff3115;
}
.rs-timeline .rs-timeline-content .rs-read-more:hover {
  background-color: #ff3115;
  color: #ffffff;
}
.rs-timeline .rs-timeline-content .rs-date {
  color: #ff3115;
}
.rs-timeline-2 {
  position: relative;
  padding-bottom: 40px;
}
.rs-timeline-2 .arrows {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.rs-timeline-2 .arrows button {
  height: 42px;
  width: 42px;
  font-size: 0;
  margin: 0 5px;
  border: 0;
  background-color: #212121;
  color: #ff3115;
  cursor: pointer;
  outline: 0;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.rs-timeline-2 .arrows button:hover {
  background-color: #ff3115;
  color: #fff;
}
.rs-timeline-2 .arrows button:after {
  font-family: FontAwesome;
  font-size: 22px;
  margin-top: 5px;
  display: inline-block;
}
.rs-timeline-2 .arrows button.arrow__prev:after {
  content: "\f104";
}
.rs-timeline-2 .arrows button.arrow__next:after {
  content: "\f105";
}
.rs-timeline-2 ol li:not(:last-child)::after {
  background: #ff3115;
}
/* ------------------------------------
    26. Elements
---------------------------------------*/
.rs-accordion-style1 .card {
  border: 0;
}
.rs-accordion-style1 .card .card-header {
  padding: 0;
  border: 0;
  margin-bottom: 10px;
  background: transparent;
}
.rs-accordion-style1 .card .card-header .acdn-title {
  background-color: rgba(240, 240, 240, 0.8);
  position: relative;
  margin-bottom: 0;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.2px;
  -webkit-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.rs-accordion-style1 .card .card-header .acdn-title:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f0da";
  right: 20px;
  transition: all 0.3s ease 0s;
}
.rs-accordion-style1 .card .card-header .acdn-title:not(.collapsed) {
  background-color: #ff3115;
  color: #ffffff;
}
.rs-accordion-style1 .card .card-header .acdn-title:not(.collapsed):after {
  transform: rotate(90deg);
  color: #ffffff;
}
.rs-accordion-style1 .card .card-body {
  padding: 5px 15px 18px;
}
/* ------------------------------------
    27. Blog Pages
---------------------------------------*/
.blog-page-area .blog-inner {
  overflow: hidden;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
}
.blog-page-area .blog-inner .blog-content {
  margin-top: 6px;
}
.blog-page-area .blog-inner .blog-content .date {
  font-size: 13px;
  font-weight: 600;
}
.blog-page-area .blog-inner .blog-content ul.blog-meta {
  margin: 0 0 10px;
}
.blog-page-area .blog-inner .blog-content ul.blog-meta li {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: #ff3115;
  padding-right: 10px;
}
.blog-page-area .blog-inner .blog-content ul.blog-meta li:last-child {
  padding-right: 0;
}
.blog-page-area .blog-inner .blog-content ul.blog-meta li i {
  font-size: 13px;
}
.blog-page-area .blog-inner .blog-content ul.blog-meta li a {
  display: block;
}
.blog-page-area .blog-inner .blog-content p {
  font-size: 15px;
}
.blog-page-area .blog-inner .blog-content h4 {
  margin: 0 0 8px;
}
.blog-page-area .blog-inner .blog-content h4 a {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
}
.blog-page-area .blog-inner .blog-content a.primary-btn {
  margin-top: 12px;
}
.blog-page-area .blog-inner .blog-images {
  overflow: hidden;
  position: relative;
}
.blog-page-area .blog-inner .blog-images i {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari */
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #ff3115;
  color: #ff3115;
  opacity: 0;
}
.blog-page-area .blog-inner .blog-images:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
}
.blog-page-area .blog-inner .blog-images img {
  transform: scale(1);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  width: 100%;
}
.blog-page-area .blog-inner:hover .blog-images i {
  opacity: 1;
}
.blog-page-area .blog-inner:hover .blog-images img {
  transform: scale(1.1);
  transition: all 0.3s ease 0s;
}
.blog-page-area .blog-inner:hover .blog-images:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  ms-transform: scale(1) !important;
}
.blog-page-area .blog-inner:hover .blog-content h4 a {
  color: #ff3115;
}
.blog-page-area .pagination {
  margin-top: 0;
}
/* ------------------------------------
    28 .Blog Details 
---------------------------------------*/
.single-blog-details .single-image {
  margin-bottom: 25px;
  overflow: hidden;
}
.single-blog-details .single-image img {
  filter: grayscale(0%);
  transition: all 0.8s ease-in-out 0s;
}
.single-blog-details .single-image img:hover {
  filter: grayscale(100%);
  transform: scale(1.1);
}
.single-blog-details .like-section h5,
.single-blog-details h5.top-title {
  font-size: 16px;
  color: #212121;
  margin: 20px 0 15px;
  padding: 0;
  line-height: 25px;
}
.single-blog-details .like-section h5 a {
  color: #212121;
}
.single-blog-details .like-section h5 a:hover {
  color: #ff3115;
}
.single-blog-details h3.top-title,
.single-blog-details .like-section h3.title-bg {
  font-size: 20px;
}
.single-blog-details .like-section span.date {
  font-size: 12px;
}
.single-blog-details blockquote {
  background: #f0f0f0;
  border: medium none;
  margin: 25px 0 25px;
  padding: 24px 25px 24px 63px;
  font-size: 16px;
  color: #212121;
  font-style: italic;
  position: relative;
  font-weight: 400;
}
.single-blog-details blockquote i {
  position: absolute;
  left: 22px;
  font-size: 25px;
  top: 21px;
}
.single-blog-details h2 {
  font-size: 20px;
  margin: 0 0 25px;
}
.single-blog-details .share-section {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 20px;
}
.single-blog-details .share-section .share-link1 {
  padding: 0;
  margin: 0;
  float: right;
}
.single-blog-details .share-section .share-link1 li {
  float: left;
  list-style: none;
}
.single-blog-details .share-section .share-link1 li:first-child a {
  margin-left: 0;
}
.single-blog-details .share-section .share-link1 li a {
  padding: 7px 8px;
  border: 1px solid #ddd;
  font-size: 13px;
  font-weight: 400;
  color: #505050;
  border-radius: 4px;
  margin-left: 14px;
}
.single-blog-details .share-section .share-link1 li a:hover {
  background: #ff3115;
  color: #212121;
  border: 1px solid #ff3115;
}
.single-blog-details .share-section .share-link1 li:first-child a {
  background: #ff3115;
  color: #212121;
  border: 1px solid #ff3115;
}
.single-blog-details .share-section .life-style span {
  font-size: 13px;
  font-weight: 400;
  color: #505050;
  margin-right: 12px;
}
.single-blog-details .share-section .life-style span:last-child {
  margin-right: 0;
}
.single-blog-details .share-section .life-style span i {
  margin-right: 4px;
}
.single-blog-details .share-section .life-style span a {
  font-size: 13px;
  font-weight: 400;
  color: #505050;
}
.single-blog-details .share-section .life-style span a i {
  font-size: 13px;
  margin-right: 4px;
}
.single-blog-details .share-section .life-style span a:hover {
  color: #ff3115;
}
.single-blog-details .share-section .life-style span.author a {
  color: #777777;
}
.single-blog-details .share-section .life-style span.author a:hover {
  color: #ff3115;
}
.single-blog-details .share-section2 {
  border: 1px solid #ddd;
  padding: 15px 15px 15px 15px;
}
.single-blog-details .share-section2 span {
  font-size: 15px;
  font-weight: 400;
  color: #212121;
}
.single-blog-details .share-section2 .share-link {
  float: right;
}
.single-blog-details .share-section2 .share-link li {
  float: left;
  list-style: none;
}
.single-blog-details .share-section2 .share-link li:first-child a {
  margin-left: 0;
}
.single-blog-details .share-section2 .share-link li a {
  font-size: 13px;
  font-weight: 400;
  color: #505050;
  padding: 7px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 14px;
}
.single-blog-details .share-section2 .share-link li a:hover {
  background: #ff3115;
  color: #fff;
  border: 1px solid #ff3115;
}
.single-blog-details .like-section .col-xs-12 h3 {
  margin: 14px 0 6px;
}
.single-blog-details .like-section .col-xs-12 h3 a {
  font-size: 15px;
  font-weight: 400;
  color: #212121;
}
.single-blog-details .like-section .col-xs-12 span {
  font-size: 13px;
  font-weight: 400;
}
.single-blog-details .like-section .col-xs-12 span i {
  font-size: 13px;
}
.single-blog-details .like-section .col-xs-12 .popular-post-img img {
  transition: all 0.3s ease 0s;
}
.single-blog-details .like-section .col-xs-12:hover a {
  color: #ff3115;
}
.single-blog-details .like-section .col-xs-12:hover .popular-post-img img {
  opacity: .5;
}
.single-blog-details .next-pre-section {
  padding: 23px 0 15px;
  margin: 0;
  overflow: hidden;
}
.single-blog-details .next-pre-section li {
  margin: 0;
}
.single-blog-details .next-pre-section li a {
  font-size: 15px;
  color: #505050;
}
.single-blog-details .next-pre-section li a i {
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  color: #212121;
}
.single-blog-details .next-pre-section li a:hover {
  color: #ff3115;
}
.single-blog-details .next-pre-section li a:hover i {
  color: #ff3115;
}
.single-blog-details .next-pre-section li.left-arrow {
  float: left;
  width: 50%;
}
.single-blog-details .next-pre-section li.left-arrow a {
  font-size: 15px;
  font-weight: 500;
  color: #505050;
}
.single-blog-details .next-pre-section li.left-arrow a i {
  padding-right: 5px;
}
.single-blog-details .next-pre-section li.right-arrow {
  float: right;
  width: 50%;
  text-align: right;
}
.single-blog-details .next-pre-section li.right-arrow a {
  font-size: 15px;
  font-weight: 500;
}
.single-blog-details .next-pre-section li.right-arrow a i {
  padding-left: 5px;
}
.single-blog-details .author-comment h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 42px 0 6px;
}
.single-blog-details .author-comment span {
  font-size: 13px;
  font-weight: 500;
}
.single-blog-details .author-comment span a {
  font-size: 13px;
  font-weight: 500;
}
.single-blog-details .author-comment span i {
  font-size: 13px;
}
.single-blog-details .author-comment h4 {
  font-size: 15px;
  font-weight: 700;
}
.single-blog-details .author-comment ul {
  padding: 0;
  margin: 0;
}
.single-blog-details .author-comment ul li {
  margin: 0 0 2px;
  padding: 20px 22px;
  border-bottom: 1px solid #ddd;
}
.single-blog-details .author-comment ul li:nth-child(2) {
  margin-left: 100px;
}
.single-blog-details .author-comment ul li:nth-child(2) .col-sm-2 {
  padding: 0;
}
.single-blog-details .author-comment ul li:last-child {
  border-bottom: none;
}
.single-blog-details .author-comment ul li .image-comments {
  margin-top: 0;
}
.single-blog-details .author-comment ul li .image-comments img {
  width: 90px;
  border-radius: 100%;
}
.single-blog-details .author-comment ul li .reply {
  display: block;
  position: absolute;
  text-align: right;
  width: 95%;
}
.single-blog-details .author-comment ul li .reply a {
  color: #212121;
}
.single-blog-details .author-comment ul li .reply a:hover {
  color: #ff3115;
}
.single-blog-details .author-comment ul li .reply i {
  color: #212121;
}
.single-blog-details .author-comment ul li .dsc-comments h4 {
  margin: 0 0 12px;
}
.single-blog-details .author-comment ul li .dsc-comments a {
  color: #ff3115;
}
.single-blog-details .author-comment ul li .dsc-comments p {
  margin: 6px 0 0;
}
.single-blog-details .leave-comments-area {
  padding-top: 20px;
}
.single-blog-details .leave-comments-area .form-group {
  margin-bottom: 20px;
}
.single-blog-details .leave-comments-area h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 19px;
  font-family: 'Roboto Condensed', sans-serif;
}
.single-blog-details .leave-comments-area label {
  color: #505050;
  font-weight: normal;
  letter-spacing: 1px;
}
.single-blog-details .leave-comments-area input,
.single-blog-details .leave-comments-area textarea {
  background: transparent;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  height: 45px;
  width: 100%;
}
.single-blog-details .leave-comments-area textarea {
  height: 150px;
  width: 100%;
  max-width: 100%;
}
.single-blog-details .leave-comments-area .btn-send {
  background: #ff3115;
  font-weight: 600;
  color: #ffffff;
  font-size: 15px;
  line-height: 24px;
  border: none;
  border-radius: 0px;
  padding: 14px 23px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 14px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.single-blog-details .leave-comments-area .btn-send:hover {
  background: #212121;
  color: #ffffff;
}
/* ------------------------------------
    29. Shop Page Start Here 
---------------------------------------*/
.shop-page-area.single-product-page {
  padding: 100px 0 0;
}
.shop-page-area .single-details {
  padding: 0 0 35px;
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s ease 0s;
  margin-bottom: 15px;
}
.shop-page-area .single-details .triangle_left {
  width: 0;
  height: 0;
  border-top: 70px solid transparent;
  border-right: 140px solid #ff3115;
  border-bottom: 70px solid transparent;
  position: absolute;
  top: -75px;
  left: -117px;
  transform: rotate(48deg);
  opacity: 0;
}
.shop-page-area .single-details:hover {
  background: #f5f5f5;
}
.shop-page-area .single-details:hover .triangle_left {
  opacity: 1;
}
.shop-page-area .single-details:hover h3 a {
  color: #ff3115;
}
.shop-page-area .single-details:hover .add-to-cart {
  background: #ff3115;
  color: #fff !important;
}
.shop-page-area .single-details:hover .images .overley {
  opacity: 1;
  transform: scaleY(1);
}
.shop-page-area .single-details .images {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.shop-page-area .single-details .images a {
  display: block;
}
.shop-page-area .single-details .images a img {
  transition: all 0.3s ease-out;
  width: 100%;
}
.shop-page-area .single-details .images .overley {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  transform: scaleY(0);
  transform-origin: 0 1 0;
  opacity: 0;
  transition: all 0.3s ease 0s;
  padding: 30px;
}
.shop-page-area .single-details .images .overley .winners-details {
  padding: 20% 0;
}
.shop-page-area .single-details .images .overley .winners-details h4 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #ffffff;
  font-size: 18px;
  position: relative;
}
.shop-page-area .single-details .images .overley .winners-details h4:after {
  display: none;
}
.shop-page-area .single-details .images .overley .winners-details .product-info {
  margin: 0;
  padding: 0px;
  list-style: none;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  transition: all .9s;
  text-align: center;
  left: 0;
  right: 0;
}
.shop-page-area .single-details .images .overley .winners-details .product-info li {
  display: inline-block;
}
.shop-page-area .single-details .images .overley .winners-details .product-info li a {
  color: #ffffff;
  text-transform: capitalize;
  text-decoration: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.shop-page-area .single-details .images .overley .winners-details .product-info li a:hover {
  color: #ff3115;
  border: 1px solid #ff3115;
}
.shop-page-area .single-details .images .overley .winners-details .product-info li a i {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  transition: all 0.3s ease 0s;
}
.shop-page-area .single-details .images .overley .winners-details .product-info li a i:hover {
  color: #ff3115;
}
.shop-page-area .single-details .images .overley .winners-details p {
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 13px;
}
.shop-page-area .single-details .images .overley .winners-details p i {
  color: #ffffff;
  margin-right: 8px;
}
.shop-page-area .single-details h4 {
  margin-bottom: 10px !important;
  margin-left: 0;
  font-weight: 700;
}
.shop-page-area .single-details h4 a {
  color: #212121;
  transition: all 0.3s ease 0s;
  font-size: 20px;
  margin: 0;
}
.shop-page-area .single-details h4 a:hover {
  color: #ff3115;
}
.shop-page-area .single-details .price-details ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
.shop-page-area .single-details .price-details ul li {
  display: block;
}
.shop-page-area .single-details .price-details ul li:first-child {
  font-weight: 600;
  font-size: 15px;
}
.shop-page-area .single-details .price-details ul li:first-child del {
  margin-right: 5px;
  font-weight: 400;
  font-size: 15px;
}
.shop-page-area .single-details .price-details ul li .add-to-cart {
  border: 1px solid #ff3115;
  padding: 8px 18px;
  color: #212121;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  margin-top: 15px;
}
.shop-page-area .topbar-area {
  overflow: hidden;
  padding-bottom: 54px;
}
.shop-page-area .topbar-area .showing-Short .seclec-box {
  float: right;
}
.shop-page-area .topbar-area .showing-result ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}
.shop-page-area .topbar-area .showing-result ul li {
  display: inline-block;
  font-weight: 400;
  color: #505050;
  margin-right: 0;
  font-size: 14px;
  height: 25px;
}
.shop-page-area .topbar-area .showing-result ul li .form-group {
  margin: 0;
}
.shop-page-area .topbar-area .showing-result ul li .form-group.seclect-box select.form-control {
  display: inline-block;
  border: 0;
  background: transparent;
  border-radius: 0px;
}
.shop-page-area .pagination {
  margin-top: 0;
}
.shop-page-area .classic-tabs.tabs-cyan {
  display: block;
}
/* ------------------------------------
    30.Shop Single Page Start Here 
---------------------------------------*/
.shop-single-page-area .shop-single-btm-page-area {
  padding-bottom: 74px;
  overflow: hidden;
  padding-top: 60px;
}
.shop-single-page-area .shop-single-btm-page-area .sec-title {
  font-size: 30px;
}
.shop-single-page-area .shop-single-btm-page-area .sec-title:after {
  height: 5px;
}
.shop-single-page-area .shop-single-btm-page-area .sec-sub-title {
  margin-bottom: 42px;
  margin-top: 10px;
  font-size: 30px;
}
.shop-single-page-area .inner-single-product-slider .inner {
  background: #f5f5f5;
  margin-bottom: 26px;
}
.shop-single-page-area .inner-single-product-slider .single-product-nav {
  max-width: 300px;
  margin: 0 auto;
}
.shop-single-page-area .inner-single-product-slider .single-product-nav .slick-slide {
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}
.shop-single-page-area .inner-single-product-slider .single-product-nav .slick-slide:after {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.shop-single-page-area .inner-single-product-slider .single-product-nav .slick-current:after {
  opacity: 0;
}
.shop-single-page-area .inner-single-product-slider .slick-prev:before {
  content: "\f104";
}
.shop-single-page-area .inner-single-product-slider .slick-prev:before,
.shop-single-page-area .inner-single-product-slider .slick-next:before {
  font-family: FontAwesome;
  opacity: 1;
  transition: all 0.5s ease 0s;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}
.shop-single-page-area .inner-single-product-slider .slick-prev:hover:before,
.shop-single-page-area .inner-single-product-slider .slick-next:hover:before {
  color: #ff3115;
}
.shop-single-page-area .inner-single-product-slider .slick-next:before {
  content: "\f105";
}
.shop-single-page-area .left-area h4 {
  margin: 0 0 12px;
  color: #212121;
  font-size: 20px;
}
.shop-single-page-area .left-area .cat,
.shop-single-page-area .left-area .tag {
  font-size: 13px;
  margin: 0 0 5px;
}
.shop-single-page-area .left-area .cat strong,
.shop-single-page-area .left-area .tag strong {
  font-weight: 500;
}
.shop-single-page-area .left-area .cat {
  margin-top: 27px;
}
.shop-single-page-area .tab-btm {
  margin-top: 20px;
  vertical-align: top;
}
.shop-single-page-area .tab-btm .nav-item {
  position: relative;
  display: block;
  width: 100%;
}
.shop-single-page-area .tab-btm .nav-item a {
  font-size: 15px;
  color: #505050;
}
.shop-single-page-area .tab-btm .nav-item .active {
  background: #eee;
}
.shop-single-page-area .tab-btm .nav-item .active:before {
  content: "\f105";
  font-family: FontAwesome;
  opacity: 1;
  transition: all 0.5s ease 0s;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 14px;
  z-index: 99;
  right: 5px;
}
.shop-single-page-area .tab-btm .tab-content.card {
  border: none;
}
.shop-single-page-area .tab-btm h4 {
  margin: 15px 0 19px;
  font-weight: 500;
  color: #505050;
}
/*-----------------------------------
    25. Check Out css Start Here
-------------------------------------*/
.rs-check-out .title-bg {
  font-size: 24px;
  margin-bottom: 30px;
}
.rs-check-out .check-out-box {
  padding: 0 0 40px 0;
}
.rs-check-out .check-out-box label {
  color: #505050;
  font-weight: 400;
}
.rs-check-out .check-out-box textarea,
.rs-check-out .check-out-box input {
  border-color: #bfbfbf;
}
.rs-check-out .check-out-box input {
  height: 40px;
  padding: 0 15px;
}
.rs-check-out .check-out-box select {
  width: 100%;
  min-height: 40px;
  display: block;
  padding: 10px 15px;
  color: #505050;
}
.rs-check-out .check-out-box .checkbox input {
  height: auto;
  margin-top: 7px;
}
.rs-check-out .shipping-box h3.title {
  font-size: 24px;
  margin: 0 0 30px;
}
.rs-check-out .shipping-box .form-group input {
  height: 80px;
}
.rs-check-out .shipping-box input,
.rs-check-out .checkbox input {
  margin-right: 15px;
}
.rs-check-out .product-demo {
  width: 100%;
  display: inline-block;
  padding-bottom: 20px;
  padding-top: 8px;
}
.rs-check-out .product-demo h5 {
  margin: 0;
  font-size: 15px;
  color: #505050;
}
.rs-check-out .product-demo .product-image {
  width: 15%;
  float: left;
}
.rs-check-out .product-demo .product-name {
  width: 30%;
  float: left;
  transform: translateY(100%);
}
.rs-check-out .product-demo .product-quantity {
  width: 25%;
  float: left;
  text-align: center;
  transform: translateY(100%);
}
.rs-check-out .product-demo .product-ititial-price {
  width: 30%;
  float: left;
  text-align: center;
  transform: translateY(100%);
}
.rs-check-out .product-price table {
  border: 1px solid #b2b2b2;
  margin-bottom: 25px;
}
.rs-check-out .product-price table tr td {
  padding: 15px 25px;
  border-bottom: 1px solid #b2b2b2;
}
.rs-check-out .product-price table tr td input {
  margin-right: 5px;
}
.rs-check-out .product-price table tr td:last-child {
  text-align: right;
}
.rs-check-out .product-price table tr td.no-border {
  border: none;
}
.rs-check-out .rs-payment-system .payment-radio-btn1 {
  padding: 15px 25px;
  border: 1px solid #b2b2b2;
}
.rs-check-out .rs-payment-system .payment-radio-btn1 p {
  margin: 0;
  padding: 15px 0 15px 25px;
  font-size: 13px;
}
.rs-check-out .rs-payment-system .payment-radio-btn2 {
  padding: 15px 25px;
  border: 1px solid #b2b2b2;
  border-top: none;
}
.rs-check-out .rs-payment-system input {
  margin-right: 10px;
}
.rs-check-out .rs-payment-system input.btn-send {
  width: 100%;
  margin-top: 25px;
  background-color: #ff3115;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  height: 50px;
  line-height: 50px;
  text-align: c center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  cursor: pointer;
}
.rs-check-out .rs-payment-system input.btn-send:hover {
  background: #212121;
}
/*-----------------------------------
    32. Shipping Area Start Here
------------------------------------*/
.shipping-area .button-area ul li a {
  display: block;
  padding: 15px;
  background: #f8f8f8;
  color: #646464;
  font-size: 18px;
}
.shipping-area .button-area ul li.active a {
  background: #ff3115;
  color: #fff;
}
.shipping-area .product-list table {
  margin: 0 0 30px;
}
.shipping-area .product-list table tr {
  border: 1px solid #e7e7e7;
  padding: 25px;
  display: block;
  margin-bottom: -1px;
}
.shipping-area .product-list table tr td {
  padding-right: 52px;
}
.shipping-area .product-list table tr td img {
  width: 100%;
  display: block;
  max-width: 80px;
}
.shipping-area .product-list table tr td .des-pro {
  display: block;
  padding-right: 50px;
  width: 210px;
}
@media screen and (max-width: 991px) {
  .shipping-area .product-list table tr td .des-pro {
    width: auto;
  }
}
.shipping-area .product-list table tr td .des-pro h4 {
  margin: 0 0 10px;
  font-size: 20px;
}
.shipping-area .product-list table tr td .des-pro p {
  color: #646464;
  margin: 0;
}
.shipping-area .product-list table tr td strong {
  font-size: 20px;
  display: block;
  padding-right: 100px;
  font-weight: 500;
}
@media screen and (max-width: 991px) {
  .shipping-area .product-list table tr td strong {
    padding-right: 10px;
  }
}
.shipping-area .product-list table tr td .order-pro {
  position: relative;
  display: block;
  margin-right: 100px;
}
.shipping-area .product-list table tr td .order-pro input {
  width: 110px;
  height: 46px;
  box-shadow: none;
  border: 1px solid #ccc;
  text-align: center;
  padding-right: 10px;
  color: #888888;
  font-size: 18px;
}
.shipping-area .product-list table tr td .order-pro div {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 999;
  cursor: pointer;
}
.shipping-area .product-list table tr td .order-pro div.btn-plus {
  right: 40px;
}
.shipping-area .product-list table tr td .order-pro div.btn-minus {
  right: 20px;
}
.shipping-area .product-list table tr td .prize {
  color: #ff3115;
  font-size: 18px;
  font-weight: 500;
  padding-right: 50px;
}
.shipping-area .product-list table tr td i {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  text-align: center;
  line-height: 28px;
  font-size: 15px;
  cursor: pointer;
  color: #ccc;
}
.shipping-area .product-list table tr td i:hover {
  background: #ff3115;
  color: #fff;
}
.shipping-area .product-list .total span {
  font-size: 20px;
  padding-right: 10px;
}
.shipping-area .product-list .total strong {
  font-size: 28px;
  font-weight: 400;
}
.shipping-area .next-step {
  text-align: right;
}
.shipping-area .next-step a {
  padding: 10px 30px;
  border: 1px solid #ff3115;
  background: #ff3115 !important;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  background: transparent;
  margin-top: 25px;
  transition: all 0.3s ease 0s;
  display: inline-block;
}
.shipping-area .next-step a:hover {
  background: #212121 !important;
  color: #fff;
  border: 1px solid #212121 !important;
}
.shipping-area .form-area h3 {
  font-weight: 500;
  padding: 15px 15px;
  font-size: 22px;
}
.shipping-area .form-area form fieldset {
  margin: 0 0 15px;
}
.shipping-area .form-area form fieldset label {
  display: block;
  width: 100%;
  color: #333333;
  font-weight: 400;
  margin: 0 0 10px;
  font-size: 14px;
}
.shipping-area .form-area form fieldset input {
  display: block;
  width: 100%;
  margin: 0 0 10px;
  height: 40px;
  border-radius: 0;
  padding: 0 15px;
  border: 1px solid #ccc;
}
.shipping-area .form-area form fieldset select {
  display: block;
  width: 100%;
  margin: 0 0 10px;
  height: 40px;
  border-radius: 0;
  padding: 0 15px;
  color: #646464;
  font-size: 13px;
  border: 1px solid #ccc;
}
.shipping-area .order-list h3 {
  padding: 15px 0;
  font-size: 24px;
}
.shipping-area .order-list table {
  width: 100%;
}
.shipping-area .order-list table tr {
  width: 100%;
  display: block;
}
.shipping-area .order-list table tr th {
  font-weight: bold;
  width: 50%;
}
.shipping-area .order-list table tr td {
  border: 1px solid #dedede;
  padding: 15px 15px;
  font-weight: normal;
}
.shipping-area .order-list table tr td:first-child {
  width: 400px;
}
@media screen and (max-width: 480px) {
  .shipping-area .order-list table tr td:first-child {
    width: 65%;
  }
}
.shipping-area .order-list table tr td:last-child {
  width: 150px;
  text-align: center;
}
.shipping-area .order-list table .row-bold td {
  border: 1px solid #dedede;
  font-weight: 700;
}
.shipping-area .panel-group .panel {
  border-radius: 0;
  margin: 0;
}
.shipping-area .panel-group .panel-body {
  padding-left: 40px;
  padding-right: 100px;
}
.shipping-area .panel-group a .checkbox {
  margin: 0;
  padding: 10px 0;
}
.shipping-area .panel-group a .checkbox .cr {
  position: relative;
  display: inline-block;
  background: #cccccc;
  border-radius: 100%;
  float: left;
  margin-top: 0px;
  margin-right: .5em;
  width: 15px;
  height: 15px;
}
.shipping-area .panel-group a .checkbox label {
  margin: 0 !important;
  padding: 0 !important;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
}
.shipping-area .panel-group a .checkbox label input[type="checkbox"] {
  display: none;
}
.shipping-area .panel-group a .checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 1;
  display: block;
  color: #ff3115;
  width: 15px;
  height: 15px;
}
.shipping-area .panel-group a .checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
  transition: all 0.3s ease-in;
  display: block;
  padding: 5px;
  color: #2962ff;
}
.shipping-area .panel-group .panel-default > .panel-heading {
  background: transparent;
}
.order-pro input[type=number]::-webkit-inner-spin-button,
.order-pro input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.coupon-fields .input-text {
  padding: 5px 8px;
  width: 75%;
  margin-right: 10px;
  margin-bottom: 25px;
}
.coupon-fields .apply-coupon {
  background: #ff3115;
  border: none;
  color: #fff;
  padding: 6px 8px;
  border: 1px solid #ff3115;
  transition: all 0.3s ease 0s;
}
.coupon-fields .apply-coupon:hover {
  background: #212121 !important;
  border: 1px solid #212121;
  color: #fff;
}
/*-------------------------------------
    32. Contact Page Section Start Here
--------------------------------------*/
.contact-page-section #googleMap {
  height: 490px;
}
.contact-page-section .map-text {
  padding-bottom: 22px;
}
.contact-page-section .map-text h3 {
  font-size: 20px;
  margin: 0;
  padding: 25px 0 10px;
  font-weight: 600;
}
.contact-page-section .map-text p {
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.contact-page-section .contact-address-section {
  text-align: center;
  margin: 50px 0;
}
.contact-page-section .contact-address-section .contact-info {
  background: #f0f0f0;
  border: 1px solid #e1e1e1;
  padding: 25px 0 23px;
}
.contact-page-section .contact-address-section .contact-info .contact-pd {
  padding: 0 10px;
}
.contact-page-section .contact-address-section .contact-info i {
  color: #ff3115;
  font-size: 36px;
  margin: 0;
  padding: 0;
}
.contact-page-section .contact-address-section .contact-info h4 {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #212121;
  margin: 0;
  padding: 15px 0;
}
.contact-page-section .contact-address-section .contact-info a {
  color: #505050;
  display: block;
}
.contact-page-section .contact-address-section .contact-info a:hover {
  color: #ff3115;
}
.contact-page-section .contact-address-section .contact-info p {
  color: #505050;
  margin: 0;
  padding: 0;
}
.contact-page-section .contact-comment-section h3 {
  font-size: 20px;
  margin: 0;
  padding-bottom: 30px;
  text-transform: uppercase;
  font-weight: 600;
}
.contact-page-section .contact-comment-section form .form-group input {
  height: 43px;
  padding: 0 15px;
}
.contact-page-section .contact-comment-section form .form-group input,
.contact-page-section .contact-comment-section form .form-group textarea {
  border: none;
  background: #f5f5f5;
  border-radius: 0;
  box-shadow: none;
}
.contact-page-section .contact-comment-section form .form-group label {
  color: #505050;
  font-weight: 400;
}
.contact-page-section .contact-comment-section form .form-group input.btn-send {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #ff3115;
  margin-top: 15px;
  border: none;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  padding: 0 50px;
  cursor: pointer;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.contact-page-section .contact-comment-section form .form-group input.btn-send:hover {
  background: #212121;
}
/* ------------------------------------
    33. Rs Gallery
---------------------------------------*/
.rs-gallery .gallery-item {
  position: relative;
}
.rs-gallery .gallery-item .gallery-desc {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 25px;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.rs-gallery .gallery-item .gallery-desc h3 {
  margin-bottom: 12px;
  font-size: 24px;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}
.rs-gallery .gallery-item .gallery-desc h3 a {
  color: #ffffff;
}
.rs-gallery .gallery-item .gallery-desc p {
  color: #e1e1e1;
  margin-bottom: 30px;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}
.rs-gallery .gallery-item .gallery-desc .image-popup {
  display: inline-block;
  width: 50px;
  line-height: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff3115;
  color: #ffffff;
  font-size: 18px;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-gallery .gallery-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.8);
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.rs-gallery .gallery-item:hover:before {
  opacity: 1;
}
.rs-gallery .gallery-item:hover h3,
.rs-gallery .gallery-item:hover p,
.rs-gallery .gallery-item:hover .image-popup {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
.rs-gallery .row {
  margin-bottom: 30px;
}
.rs-gallery-section .single-gallery {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.rs-gallery-section .single-gallery:after {
  content: "";
  width: 80%;
  height: 80%;
  background: #92278f;
  position: absolute;
  top: 10%;
  left: 10%;
  display: block;
  overflow: hidden;
  opacity: 0.8;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.rs-gallery-section .single-gallery .popup-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.rs-gallery-section .single-gallery .popup-icon a {
  font-size: 20px;
  display: inline-block;
  margin: 0 8px;
  color: #fff;
}
.rs-gallery-section .single-gallery:hover:after {
  opacity: 0.8;
  visibility: visible;
  transform: scale(1);
}
.rs-gallery-section .single-gallery:hover .popup-icon {
  opacity: 1;
  visibility: visible;
}
/* ------------------------------------
    34. ScrollUp
---------------------------------------*/
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 40px;
  z-index: 999;
}
#scrollUp i {
  background-color: #ff3115;
  height: 40px;
  font-size: 24px;
  width: 42px;
  color: #ffffff;
  line-height: 36px;
  transition: all 0.3s ease 0s;
  margin-left: 2px;
}
#scrollUp i:hover {
  background-color: #e41f05;
  color: rgba(255, 255, 255, 0.7);
}
/* ------------------------------------
    35.404 Page Area Start Here 
---------------------------------------*/
.error-page-area {
  text-align: center;
}
.error-page-area .error-page {
  background: #f0f0f0;
  background-position: center center;
  background-size: cover;
  padding: 60px 0 100px;
}
.error-page-area .error-page h1 {
  font-size: 250px;
  color: #212121;
  line-height: 230px;
  margin: 0;
}
.error-page-area .error-page p {
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  letter-spacing: 3px;
  margin-bottom: 50px;
}
.error-page-area .error-page-message {
  margin-top: 0;
}
.error-page-area .error-page-message p {
  font-size: 20px;
  color: #212121;
}
.error-page-area .error-page-message .home-page a {
  display: inline-block;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  background: #ff3115;
  padding: 15px 35px;
  transition: all 0.3s ease 0s;
  font-weight: 700;
}
.error-page-area .error-page-message .home-page a:hover {
  background: #212121;
}
/*-------------------------
    36.Preloader css
---------------------------*/
.book_preload {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ff3115;
  z-index: 999999;
}
.book {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  margin: 0 auto;
  border: 5px solid #ecf0f1;
  width: 100px;
  height: 60px;
}
.book__page {
  position: absolute;
  left: 50%;
  top: -5px;
  margin: 0 auto;
  border-top: 5px solid #ecf0f1;
  border-bottom: 5px solid #ecf0f1;
  border-right: 5px solid #ecf0f1;
  background: #e41f05;
  width: 50px;
  height: 60px;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: flip 1.2s infinite linear;
  animation: flip 1.2s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.book__page:nth-child(1) {
  z-index: -1;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.book__page:nth-child(2) {
  z-index: -2;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}
.book__page:nth-child(3) {
  z-index: -3;
  -webkit-animation-delay: 4.2s;
  animation-delay: 4.2s;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(600px) rotateY(0deg);
    transform: perspective(600px) rotateY(0deg);
  }
  20% {
    background: #ff3115;
  }
  29.9% {
    background: #ff3115;
  }
  30% {
    -webkit-transform: perspective(200px) rotateY(-90deg);
    transform: perspective(200px) rotateY(-90deg);
    background: #e41f05;
  }
  54.999% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #e41f05;
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #e41f05;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(600px) rotateY(0deg);
    transform: perspective(600px) rotateY(0deg);
  }
  20% {
    background: #ff3115;
  }
  29.9% {
    background: #ff3115;
  }
  30% {
    -webkit-transform: perspective(200px) rotateY(-90deg);
    transform: perspective(200px) rotateY(-90deg);
    background: #e41f05;
  }
  54.999% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #e41f05;
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #e41f05;
  }
}
/* ------------------------------------
    37. Rs Footer
---------------------------------------*/
.rs-footer {
  color: #e8e8e8;
  margin-top: 98px;
}
.rs-footer .footer-title {
  margin-bottom: 40px;
  padding-bottom: 5px;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  font-weight: 600;
}
.rs-footer .footer-title:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 50px;
  background-color: #ff3115;
}
.rs-footer .container {
  position: relative;
}
.rs-footer .footer-contact-desc {
  margin: 0;
  background: #222;
  text-align: center;
  padding: 35px;
  position: absolute;
  left: 0;
  right: 0;
  margin: -100px auto 0;
  z-index: 111;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}
.rs-footer .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner {
  border-left: 1px solid #e2e2e2;
}
.rs-footer .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:before,
.rs-footer .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:after {
  content: '';
  position: absolute;
  height: calc(100% - 40px);
  width: 1px;
  background-color: #e2e2e2;
  top: 50%;
  transform: translateY(-50%);
}
.rs-footer .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:before {
  left: 3px;
}
.rs-footer .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:after {
  left: -5px;
}
.rs-footer .footer-contact-desc .contact-inner {
  position: relative;
}
.rs-footer .footer-contact-desc .contact-inner i {
  font-size: 28px;
  margin-bottom: 12px;
  color: #ff3115;
}
.rs-footer .footer-contact-desc .contact-inner .contact-title {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 20px;
}
.rs-footer .footer-contact-desc .contact-inner .contact-desc {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  font-size: 15px;
}
.rs-footer .footer-top {
  padding-top: 140px;
}
.rs-footer .footer-top .recent-post-widget .post-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  float: left;
  background-color: #ff3115;
  color: #ffffff;
  margin-right: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date span {
  display: block;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date span:first-child {
  margin-top: 10px;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date span:last-child {
  font-size: 15px;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title a {
  font-family: 'Montserrat', sans-serif;
  color: #e8e8e8;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title a:hover,
.rs-footer .footer-top .recent-post-widget .post-item .post-title a:focus {
  color: #bbbbbb;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-category {
  font-size: 15px;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-item {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid rgba(102, 102, 102, 0.5);
}
.rs-footer .footer-top .sitemap-widget li {
  width: 50%;
  float: left;
  line-height: 33px;
}
.rs-footer .footer-top .sitemap-widget li a {
  color: #e8e8e8;
  display: inline-block;
  position: relative;
}
.rs-footer .footer-top .sitemap-widget li a:hover,
.rs-footer .footer-top .sitemap-widget li a:focus {
  color: #ff3115;
}
.rs-footer .footer-top .sitemap-widget li a i {
  padding-right: 10px;
}
.rs-footer .footer-top .flickr-feed li {
  display: inline-block;
  margin: 2px 3px;
  overflow: hidden;
  position: relative;
  width: 76px;
}
.rs-footer .footer-top .flickr-feed li img {
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.rs-footer .footer-top .flickr-feed li:hover img {
  opacity: 0.7;
}
.rs-footer .footer-top .news-form {
  position: relative;
}
.rs-footer .footer-top .news-form input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #ff3115;
  color: #ffffff;
  height: 50px;
  outline: 0 none;
  padding: 5px 15px;
  width: 100%;
}
.rs-footer .footer-top .news-form button {
  background: #ff3115;
  border: none;
  color: #ffffff;
  font-size: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.rs-footer .footer-top .news-form button:hover {
  background: #e41f05;
}
.rs-footer .footer-top .about-widget img {
  margin-bottom: 25px;
}
.rs-footer .footer-share {
  text-align: center;
  margin-top: 50px;
}
.rs-footer .footer-share ul li {
  display: inline-block;
}
.rs-footer .footer-share ul li a {
  font-size: 13px;
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  color: #fff;
  transition: all .3s ease 0s;
  background: rgba(255, 255, 255, 0.15);
}
.rs-footer .footer-share ul li a:hover {
  background-color: #ff3115;
  color: #ffffff;
}
.rs-footer .footer-share ul li + li {
  margin-left: 5px;
}
.rs-footer .footer-bottom {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 18px 0;
  margin-top: 35px;
}
.rs-footer .footer-bottom .copyright p {
  opacity: 0.95;
  margin-bottom: 0;
  font-size: 15px;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item:last-child {
  border: none;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  color: #fff;
  margin-right: 15px;
  transition: all .3s ease 0s;
  background: #ff3115 !important;
  line-height: 27px;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-date span {
  display: block;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-date span:first-child {
  margin-top: 10px;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-date span:last-child {
  font-size: 15px;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-title a {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-title a:hover,
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item .post-title a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner {
  position: relative;
  max-width: 280px;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #fff;
  pointer-events: none;
  z-index: 11;
  font-size: 20px;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner input[type=email] {
  font-size: 14px;
  padding: 12px 0 12px 16px;
  border: none;
  border-radius: 25px!important;
  height: 46px;
  position: relative;
  display: block;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  outline: 0;
  width: 100%;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner input[type=submit] {
  position: absolute;
  border-radius: 25px;
  top: 2px;
  right: 2px;
  background: #ff3115;
  color: #fff;
  padding: 10px 16px;
  border: 0;
  transition: .2s;
  font-size: 0;
  height: 42px;
  width: 74px;
  min-width: auto!important;
}
.rs-footer.rs-footer-style7 {
  position: relative;
  background: #f2f2f2;
}
.rs-footer.rs-footer-style7 .footer-top {
  padding-top: 70px !important;
}
.rs-footer.rs-footer-style7 .footer-top .footer-title {
  color: #212121;
}
.rs-footer.rs-footer-style7 .footer-top .footer-title:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 50px;
  background: #ff3115;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #e9e2e2;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item:last-child {
  border: none;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  color: #fff;
  margin-right: 15px;
  transition: all .3s ease 0s;
  background: #ff3115 !important;
  line-height: 27px;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-date span {
  display: block;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-date span:first-child {
  margin-top: 10px;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-date span:last-child {
  font-size: 15px;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-title a {
  font-family: 'Montserrat', sans-serif;
  color: #505050;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-title a:hover,
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item .post-title a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li {
  width: 50%;
  float: left;
  line-height: 33px;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a {
  color: #505050;
  display: inline-block;
  position: relative;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a:hover,
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a i {
  padding-right: 10px;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li {
  display: inline-block;
  margin: 2px 3px;
  overflow: hidden;
  position: relative;
  width: 76px;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li img {
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li:hover img {
  opacity: 0.7;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner {
  position: relative;
  max-width: 280px;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #fff;
  pointer-events: none;
  z-index: 11;
  font-size: 20px;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner input[type=email] {
  font-size: 14px;
  padding: 12px 0 12px 16px;
  border: none;
  border-radius: 25px!important;
  height: 46px;
  position: relative;
  display: block;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  outline: 0;
  width: 100%;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner input[type=submit] {
  position: absolute;
  border-radius: 25px;
  top: 2px;
  right: 2px;
  background: #ff3115;
  color: #fff;
  padding: 10px 16px;
  border: 0;
  transition: .2s;
  font-size: 0;
  height: 42px;
  width: 74px;
  min-width: auto!important;
}
.rs-footer.rs-footer-style7 .footer-top .about-widget img {
  margin-bottom: 25px;
}
.rs-footer.rs-footer-style7 .footer-share {
  text-align: center;
  margin-top: 50px;
}
.rs-footer.rs-footer-style7 .footer-share ul li {
  display: inline-block;
}
.rs-footer.rs-footer-style7 .footer-share ul li a {
  background: none !important;
  color: #212121 !important;
}
.rs-footer.rs-footer-style7 .footer-share ul li a:hover {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-share ul li li {
  margin-left: 5px;
}
.rs-footer.rs-footer-style7:before {
  background: #f2f2f2;
  transform: skewY(175deg);
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: -100px;
  width: 100%;
  z-index: -1;
}
.rs-footer.rs-footer-style7 .footer-contact-desc {
  padding-top: 0;
  border-bottom: 1px solid #e9e2e2;
  transform: translateY(0);
  position: static;
  border-radius: 0;
  box-shadow: none;
  margin: -100px auto 0;
  background: none;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner i:before {
  color: #ff3115;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner .contact-title {
  color: #212121;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner .contact-desc {
  color: #505050;
}
.rs-footer.rs-footer-style7 .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner {
  border-left: 1px solid #e2e2e2;
}
.rs-footer.rs-footer-style7 .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:before,
.rs-footer.rs-footer-style7 .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:after {
  content: '';
  position: absolute;
  height: calc(100% - 40px);
  width: 1px;
  background-color: #e2e2e2;
  top: 50%;
  transform: translateY(-50%);
}
.rs-footer.rs-footer-style7 .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:before {
  left: 3px;
}
.rs-footer.rs-footer-style7 .footer-contact-desc div[class*="col-"] + div[class*="col-"] .contact-inner:after {
  left: -5px;
}
.copyright_style7 {
  text-align: center;
  border-top: 1px solid #e9e2e2 !important;
  padding: 18px 0;
  margin-top: 35px;
  background: none !important;
}
.copyright_style7 .copyright p {
  opacity: 0.95;
  margin-bottom: 0;
  font-size: 15px;
}
.copyright_style7 .copyright p a {
  color: #ff3115;
}
.copyright_style7 .copyright p a:hover {
  color: #212121;
}
.rs-footer-2 .footer-share {
  margin-top: 20px;
}
@-webkit-keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media only screen and (max-width: 991px) {
  .instructor-home .rs-menu-toggle {
    color: #212121 !important;
  }
  .instructor-home .rs-menu-toggle i {
    color: #ff3115;
  }
}
/*---------------------
    Pulse Animation
---------------------*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/*------------------------------
    Swing Animation
------------------------------*/
@keyframes swing-anim {
  from {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
  }
  to {
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
  }
}
@-webkit-keyframes swing-anim {
  from {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
  }
  to {
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
  }
}
@keyframes swing-anim2 {
  from {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
  }
  to {
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
  }
}
@-webkit-keyframes swing-anim2 {
  from {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
  }
  to {
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
  }
}


.home5{
	min-height: calc(100vh);
}