.line-loader-container{
	position: fixed;
    top: 50px;
    width: 100%;
    left: 0px;
	z-index: 9999;
}

.line-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.line-loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  /*background-color: #2980b9;*/
  background-color: #ffab00;
  animation: lineloading 2s linear infinite;
}

@keyframes lineloading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}