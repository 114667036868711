ul.nav-submenu {
    
    padding-left: 37px;
}
/** SIDEBAR****/

.table thead tr{
	background-color: #f7f7f7;
}
.card-header{
	padding: 6px 20px;
}
.awssld__bullets{
    bottom: 10px !important;
    width: 100% !important;
    z-index: 9;
}

.slider_placeholder{
	padding-top: 20%;
    background: #2f2f2f;
    min-height: calc(100vh);
}
.myslider-title-container{
	position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #1717177a;
	z-index: -1;
}
.awssld__content > div{
	z-index:999;
	text-align:center;
	margin-top: -230px;
}
.awssld__bullets .awssld__bullets--active {
    border: 1px solid white;
}
.slide--subheadline{
	font-size: 50px;
    color: #fff;
    font-family: cursive;
}
.home5 .rs-toolbar {
    height: 32px;

}
.home5 .rs-header .logo-area {
    padding-top: 5px;
}
.card-color {
	border-radius: 0px;
	border:1px solid rgba(240, 240, 240, 0.8);
}
.card-color .card-header{
	background-color: #92278f;
	color: #fff;
	border-radius: 0px;
	padding: 10px 20px;
}
.rs-footer {
    margin-top: 150px;
}
@media screen and (max-width: 600px) {
	.logo-area img{
		width: 275px;
		margin-top: -13px;
	}
	
}


@media screen and (min-width: 600px) {
	
	.logo-area img{
			max-width: 125%;
		margin-top: 5px;
	}
}
.myslider-title-container{
	background: none;
}
.gallery-holder{
	background-color: grey;
	height: 150px;
}

.rs-footer .footer-top .sitemap-widget li a i {
    padding-right: 0px;
}
.rs-footer .footer-top .sitemap-widget li {
    width: 50%;
    float: left;
    line-height: 28px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    letter-spacing: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.cl__side__menu{
	padding-left: 10px;
}

ul.cl__side__menu li:before{
	content: "\F0A03";
	display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
	font-size: 18px;
    color: #e84b3a;
}

ul.cl__side__menu li a{
	color: black;
	font-size: 14px;
}


.blue-bg .blue-overlay {
    background: rgb(219 225 255 / 85%);
}