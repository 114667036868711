@-webkit-keyframes "progress-bar-stripes" {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}
@keyframes "progress-bar-stripes" {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}
@-webkit-keyframes "spinner-border" {
	to {
		transform: rotate(360deg);
	}
}
@keyframes "spinner-border" {
	to {
		transform: rotate(360deg);
	}
}
@-webkit-keyframes "spinner-grow" {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
	}
}
@keyframes "spinner-grow" {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
	}
}
:root {
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #007bff;
	--secondary: #6c757d;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
* {
	box-sizing: border-box;
	::before {
		box-sizing: border-box;
	}
	::after {
		box-sizing: border-box;
	}
}
html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.cl__admin{

article {
	display: block;
}
aside {
	display: block;
}
figcaption {
	display: block;
}
figure {
	display: block;
	margin: 0 0 1rem;
}
footer {
	display: block;
}
header {
	display: block;
}
hgroup {
	display: block;
}
main {
	display: block;
}
nav {
	display: block;
}
section {
	display: block;
}
.sb-nav-fixed {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
	height: calc(100vh);
	#layoutSidenav {
		#layoutSidenav_nav {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: 1030;
			width: 225px;
			height: 100vh;
			z-index: 1038;
			.sb-sidenav {
				padding-top: 56px;
				.sb-sidenav-menu {
					overflow-y: auto;
				}
			}
		}
		#layoutSidenav_content {
			padding-left: 225px;
			top: 56px;
		}
	}
	.sb-topnav {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1030;
		z-index: 1039;
	}
}
[tabindex="-1"] {
	:focus {
		:not(:focus-visible) {
			outline: 0 !important;
		}
	}
}
hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 2.5rem;
}
h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 2rem;
}
h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.75rem;
}
h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.5rem;
}
h5 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.25rem;
}
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
abbr[title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}
address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}
ol {
	margin-top: 0;
	margin-bottom: 1rem;
	ol {
		margin-bottom: 0;
	}
	ul {
		margin-bottom: 0;
	}
}
ul {
	margin-top: 0;
	margin-bottom: 1rem;
	ul {
		margin-bottom: 0;
	}
	ol {
		margin-bottom: 0;
	}
}
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}
dt {
	font-weight: 700;
}
dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}
blockquote {
	margin: 0 0 1rem;
}
b {
	font-weight: bolder;
}
strong {
	font-weight: bolder;
}
small {
	font-size: 80%;
	font-size: 80%;
	font-weight: 400;
}
sub {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
	bottom: -0.25em;
}
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
	top: -0.5em;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	:hover {
		color: #0056b3;
		text-decoration: underline;
	}
	:not([href]) {
		color: inherit;
		text-decoration: none;
		:hover {
			color: inherit;
			text-decoration: none;
		}
	}
	>code {
		color: inherit;
	}
}
pre {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	display: block;
	font-size: 87.5%;
	color: #212529;
	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
}
code {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
	font-size: 87.5%;
	color: #e83e8c;
	word-wrap: break-word;
}
kbd {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
	kbd {
		padding: 0;
		font-size: 100%;
		font-weight: 700;
	}
}
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
}
img {
	vertical-align: middle;
	border-style: none;
}
svg {
	overflow: hidden;
	vertical-align: middle;
}
table {
	border-collapse: collapse;
}
caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom;
}
th {
	text-align: inherit;
}
label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
button {
	border-radius: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
	:not(:disabled) {
		cursor: pointer;
	}
	::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
}
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: visible;
}
select {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	word-wrap: normal;
}
optgroup {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: auto;
	resize: vertical;
}
[type=button] {
	-webkit-appearance: button;
	:not(:disabled) {
		cursor: pointer;
	}
	::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
}
[type=reset] {
	-webkit-appearance: button;
	:not(:disabled) {
		cursor: pointer;
	}
	::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
}
[type=submit] {
	-webkit-appearance: button;
	:not(:disabled) {
		cursor: pointer;
	}
	::-moz-focus-inner {
		padding: 0;
		border-style: none;
	}
}
input[type=radio] {
	box-sizing: border-box;
	padding: 0;
}
input[type=checkbox] {
	box-sizing: border-box;
	padding: 0;
}
input[type=date] {
	-webkit-appearance: listbox;
}
input[type=time] {
	-webkit-appearance: listbox;
}
input[type=datetime-local] {
	-webkit-appearance: listbox;
}
input[type=month] {
	-webkit-appearance: listbox;
}
fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
	:disabled {
		a.btn {
			pointer-events: none;
		}
	}
}
legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}
progress {
	vertical-align: baseline;
}
[type=number] {
	::-webkit-inner-spin-button {
		height: auto;
	}
	::-webkit-outer-spin-button {
		height: auto;
	}
}
[type=search] {
	outline-offset: -2px;
	-webkit-appearance: none;
	::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}
output {
	display: inline-block;
}
summary {
	display: list-item;
	cursor: pointer;
}
template {
	display: none;
}
[hidden] {
	display: none !important;
}
.h1 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 2.5rem;
}
.h2 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 2rem;
}
.h3 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.75rem;
}
.h4 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.5rem;
}
.h5 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1.25rem;
}
.h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	font-size: 1rem;
}
.lead {
	font-size: 1.25rem;
	font-weight: 300;
}
.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2;
}
.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2;
}
.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2;
}
.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2;
}
.small {
	font-size: 80%;
	font-weight: 400;
}
mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.list-inline {
	padding-left: 0;
	list-style: none;
}
.list-inline-item {
	display: inline-block;
	:not(:last-child) {
		margin-right: 0.5rem;
	}
}
.initialism {
	font-size: 90%;
	text-transform: uppercase;
}
.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}
.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #6c757d;
	::before {
		content: "— ";
	}
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}
.figure {
	display: inline-block;
}
.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}
.figure-caption {
	font-size: 90%;
	color: #6c757d;
}
.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}
.container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.container-fluid {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.container-xl {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.container-lg {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.container-md {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.container-sm {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
	>.col {
		padding-right: 0;
		padding-left: 0;
	}
	>[class*=col-] {
		padding-right: 0;
		padding-left: 0;
	}
}
.col-xl {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-12 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-11 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-10 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-9 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-8 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-7 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-6 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-5 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-3 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-2 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-xl-1 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-auto {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-12 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-11 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-10 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-9 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-8 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-7 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-6 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-5 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-4 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-3 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-2 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-lg-1 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-auto {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-12 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-11 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-10 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-9 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-8 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-7 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-6 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-5 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-4 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-3 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-2 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-md-1 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-auto {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-12 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-11 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-10 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-9 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-8 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-7 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-6 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-5 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-4 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-3 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-2 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col-sm-1 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.col {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
.col-auto {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}
.col-12 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 100%;
	max-width: 100%;
}
.col-11 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}
.col-10 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}
.col-9 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 75%;
	max-width: 75%;
}
.col-8 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}
.col-7 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}
.col-6 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 50%;
	max-width: 50%;
}
.col-5 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}
.col-4 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}
.col-3 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 25%;
	max-width: 25%;
}
.col-2 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}
.col-1 {
	position: relative;
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}
.row-cols-1 {
	>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.row-cols-2 {
	>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
}
.row-cols-3 {
	>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
}
.row-cols-4 {
	>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
}
.row-cols-5 {
	>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
}
.row-cols-6 {
	>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
}
.order-first {
	order: -1;
}
.order-last {
	order: 13;
}
.order-0 {
	order: 0;
}
.order-1 {
	order: 1;
}
.order-2 {
	order: 2;
}
.order-3 {
	order: 3;
}
.order-4 {
	order: 4;
}
.order-5 {
	order: 5;
}
.order-6 {
	order: 6;
}
.order-7 {
	order: 7;
}
.order-8 {
	order: 8;
}
.order-9 {
	order: 9;
}
.order-10 {
	order: 10;
}
.order-11 {
	order: 11;
}
.order-12 {
	order: 12;
}
.offset-1 {
	margin-left: 8.3333333333%;
}
.offset-2 {
	margin-left: 16.6666666667%;
}
.offset-3 {
	margin-left: 25%;
}
.offset-4 {
	margin-left: 33.3333333333%;
}
.offset-5 {
	margin-left: 41.6666666667%;
}
.offset-6 {
	margin-left: 50%;
}
.offset-7 {
	margin-left: 58.3333333333%;
}
.offset-8 {
	margin-left: 66.6666666667%;
}
.offset-9 {
	margin-left: 75%;
}
.offset-10 {
	margin-left: 83.3333333333%;
}
.offset-11 {
	margin-left: 91.6666666667%;
}
.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	th {
		padding: 0.75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}
	td {
		padding: 0.75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}
	thead {
		th {
			vertical-align: bottom;
			border-bottom: 2px solid #dee2e6;
		}
	}
	tbody {
		+ {
			tbody {
				border-top: 2px solid #dee2e6;
			}
		}
	}
	.thead-dark {
		th {
			color: #fff;
			background-color: #343a40;
			border-color: #454d55;
		}
	}
	.thead-light {
		th {
			color: #495057;
			background-color: #e9ecef;
			border-color: #dee2e6;
		}
	}
}
.table-sm {
	th {
		padding: 0.3rem;
	}
	td {
		padding: 0.3rem;
	}
}
.table-bordered {
	border: 1px solid #dee2e6;
	th {
		border: 1px solid #dee2e6;
	}
	td {
		border: 1px solid #dee2e6;
	}
	thead {
		th {
			border-bottom-width: 2px;
		}
		td {
			border-bottom-width: 2px;
		}
	}
}
.table-borderless {
	th {
		border: 0;
	}
	td {
		border: 0;
	}
	thead {
		th {
			border: 0;
		}
	}
	tbody {
		+ {
			tbody {
				border: 0;
			}
		}
	}
}
.table-striped {
	tbody {
		tr {
			:nth-of-type(odd) {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
	}
}
.table-hover {
	tbody {
		tr {
			:hover {
				color: #212529;
				background-color: rgba(0, 0, 0, 0.075);
			}
		}
	}
	.table-primary {
		:hover {
			background-color: #9fcdff;
			>td {
				background-color: #9fcdff;
			}
			>th {
				background-color: #9fcdff;
			}
		}
	}
	.table-secondary {
		:hover {
			background-color: #c8cbcf;
			>td {
				background-color: #c8cbcf;
			}
			>th {
				background-color: #c8cbcf;
			}
		}
	}
	.table-success {
		:hover {
			background-color: #b1dfbb;
			>td {
				background-color: #b1dfbb;
			}
			>th {
				background-color: #b1dfbb;
			}
		}
	}
	.table-info {
		:hover {
			background-color: #abdde5;
			>td {
				background-color: #abdde5;
			}
			>th {
				background-color: #abdde5;
			}
		}
	}
	.table-warning {
		:hover {
			background-color: #ffe8a1;
			>td {
				background-color: #ffe8a1;
			}
			>th {
				background-color: #ffe8a1;
			}
		}
	}
	.table-danger {
		:hover {
			background-color: #f1b0b7;
			>td {
				background-color: #f1b0b7;
			}
			>th {
				background-color: #f1b0b7;
			}
		}
	}
	.table-light {
		:hover {
			background-color: #ececf6;
			>td {
				background-color: #ececf6;
			}
			>th {
				background-color: #ececf6;
			}
		}
	}
	.table-dark {
		:hover {
			background-color: #b9bbbe;
			>td {
				background-color: #b9bbbe;
			}
			>th {
				background-color: #b9bbbe;
			}
		}
	}
	.table-active {
		:hover {
			background-color: rgba(0, 0, 0, 0.075);
			>td {
				background-color: rgba(0, 0, 0, 0.075);
			}
			>th {
				background-color: rgba(0, 0, 0, 0.075);
			}
		}
	}
}
.table-primary {
	background-color: #b8daff;
	>th {
		background-color: #b8daff;
	}
	>td {
		background-color: #b8daff;
	}
	th {
		border-color: #7abaff;
	}
	td {
		border-color: #7abaff;
	}
	thead {
		th {
			border-color: #7abaff;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #7abaff;
			}
		}
	}
}
.table-secondary {
	background-color: #d6d8db;
	>th {
		background-color: #d6d8db;
	}
	>td {
		background-color: #d6d8db;
	}
	th {
		border-color: #b3b7bb;
	}
	td {
		border-color: #b3b7bb;
	}
	thead {
		th {
			border-color: #b3b7bb;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #b3b7bb;
			}
		}
	}
}
.table-success {
	background-color: #c3e6cb;
	>th {
		background-color: #c3e6cb;
	}
	>td {
		background-color: #c3e6cb;
	}
	th {
		border-color: #8fd19e;
	}
	td {
		border-color: #8fd19e;
	}
	thead {
		th {
			border-color: #8fd19e;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #8fd19e;
			}
		}
	}
}
.table-info {
	background-color: #bee5eb;
	>th {
		background-color: #bee5eb;
	}
	>td {
		background-color: #bee5eb;
	}
	th {
		border-color: #86cfda;
	}
	td {
		border-color: #86cfda;
	}
	thead {
		th {
			border-color: #86cfda;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #86cfda;
			}
		}
	}
}
.table-warning {
	background-color: #ffeeba;
	>th {
		background-color: #ffeeba;
	}
	>td {
		background-color: #ffeeba;
	}
	th {
		border-color: #ffdf7e;
	}
	td {
		border-color: #ffdf7e;
	}
	thead {
		th {
			border-color: #ffdf7e;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #ffdf7e;
			}
		}
	}
}
.table-danger {
	background-color: #f5c6cb;
	>th {
		background-color: #f5c6cb;
	}
	>td {
		background-color: #f5c6cb;
	}
	th {
		border-color: #ed969e;
	}
	td {
		border-color: #ed969e;
	}
	thead {
		th {
			border-color: #ed969e;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #ed969e;
			}
		}
	}
}
.table-light {
	background-color: #fdfdfe;
	>th {
		background-color: #fdfdfe;
	}
	>td {
		background-color: #fdfdfe;
	}
	th {
		border-color: #fbfcfc;
	}
	td {
		border-color: #fbfcfc;
	}
	thead {
		th {
			border-color: #fbfcfc;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #fbfcfc;
			}
		}
	}
}
.table-dark {
	background-color: #c6c8ca;
	color: #fff;
	background-color: #343a40;
	>th {
		background-color: #c6c8ca;
	}
	>td {
		background-color: #c6c8ca;
	}
	th {
		border-color: #95999c;
		border-color: #454d55;
	}
	td {
		border-color: #95999c;
		border-color: #454d55;
	}
	thead {
		th {
			border-color: #95999c;
			border-color: #454d55;
		}
	}
	tbody {
		+ {
			tbody {
				border-color: #95999c;
			}
		}
	}
}
.table-active {
	background-color: rgba(0, 0, 0, 0.075);
	>th {
		background-color: rgba(0, 0, 0, 0.075);
	}
	>td {
		background-color: rgba(0, 0, 0, 0.075);
	}
}
.table-dark.table-bordered {
	border: 0;
}
.table-dark.table-striped {
	tbody {
		tr {
			:nth-of-type(odd) {
				background-color: rgba(255, 255, 255, 0.05);
			}
		}
	}
}
.table-dark.table-hover {
	tbody {
		tr {
			:hover {
				color: #fff;
				background-color: rgba(255, 255, 255, 0.075);
			}
		}
	}
}
.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	>.table-bordered {
		border: 0;
	}
}
.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #495057;
	}
	:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}
	::-webkit-input-placeholder {
		color: #6c757d;
		opacity: 1;
	}
	::-moz-placeholder {
		color: #6c757d;
		opacity: 1;
	}
	:-ms-input-placeholder {
		color: #6c757d;
		opacity: 1;
	}
	::-ms-input-placeholder {
		color: #6c757d;
		opacity: 1;
	}
	::placeholder {
		color: #6c757d;
		opacity: 1;
	}
	:disabled {
		background-color: #e9ecef;
		opacity: 1;
	}
}
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}
select.form-control {
	:focus {
		::-ms-value {
			color: #495057;
			background-color: #fff;
		}
	}
}
.form-control-file {
	display: block;
	width: 100%;
}
.form-control-range {
	display: block;
	width: 100%;
}
.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}
.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem;
	line-height: 1.5;
}
.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.875rem;
	line-height: 1.5;
}
.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.375rem 0;
	margin-bottom: 0;
	font-size: 1rem;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}
.form-control-plaintext.form-control-sm {
	padding-right: 0;
	padding-left: 0;
}
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}
.form-control-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}
select.form-control[size] {
	height: auto;
}
select.form-control[multiple] {
	height: auto;
}
textarea.form-control {
	height: auto;
}
.form-group {
	margin-bottom: 1rem;
}
.form-text {
	display: block;
	margin-top: 0.25rem;
}
.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	>.col {
		padding-right: 5px;
		padding-left: 5px;
	}
	>[class*=col-] {
		padding-right: 5px;
		padding-left: 5px;
	}
}
.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}
.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
	:disabled {
		~ {
			.form-check-label {
				color: #6c757d;
			}
		}
	}
}
.form-check-input[disabled] {
	~ {
		.form-check-label {
			color: #6c757d;
		}
	}
}
.form-check-label {
	margin-bottom: 0;
}
.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-right: 0.3125rem;
		margin-left: 0;
	}
}
.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #28a745;
}
.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1.5;
	color: #fff;
	background-color: rgba(40, 167, 69, 0.9);
	border-radius: 0.25rem;
}
.was-validated {
	:valid {
		~ {
			.valid-feedback {
				display: block;
			}
			.valid-tooltip {
				display: block;
			}
		}
	}
	.form-control {
		:valid {
			border-color: #28a745;
			padding-right: calc(1.5em + 0.75rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right calc(0.375em + 0.1875rem) center;
			background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			:focus {
				border-color: #28a745;
				box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
			}
		}
		:invalid {
			border-color: #dc3545;
			padding-right: calc(1.5em + 0.75rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right calc(0.375em + 0.1875rem) center;
			background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			:focus {
				border-color: #dc3545;
				box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
			}
		}
	}
	textarea.form-control {
		:valid {
			padding-right: calc(1.5em + 0.75rem);
			background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
		}
		:invalid {
			padding-right: calc(1.5em + 0.75rem);
			background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
		}
	}
	.custom-select {
		:valid {
			border-color: #28a745;
			padding-right: calc(0.75em + 2.3125rem);
			background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			:focus {
				border-color: #28a745;
				box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
			}
		}
		:invalid {
			border-color: #dc3545;
			padding-right: calc(0.75em + 2.3125rem);
			background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			:focus {
				border-color: #dc3545;
				box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
			}
		}
	}
	.form-check-input {
		:valid {
			~ {
				.form-check-label {
					color: #28a745;
				}
				.valid-feedback {
					display: block;
				}
				.valid-tooltip {
					display: block;
				}
			}
		}
		:invalid {
			~ {
				.form-check-label {
					color: #dc3545;
				}
				.invalid-feedback {
					display: block;
				}
				.invalid-tooltip {
					display: block;
				}
			}
		}
	}
	.custom-control-input {
		:valid {
			~ {
				.custom-control-label {
					color: #28a745;
					::before {
						border-color: #28a745;
					}
				}
			}
			:checked {
				~ {
					.custom-control-label {
						::before {
							border-color: #34ce57;
							background-color: #34ce57;
						}
					}
				}
			}
			:focus {
				~ {
					.custom-control-label {
						::before {
							box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
						}
					}
				}
				:not(:checked) {
					~ {
						.custom-control-label {
							::before {
								border-color: #28a745;
							}
						}
					}
				}
			}
		}
		:invalid {
			~ {
				.custom-control-label {
					color: #dc3545;
					::before {
						border-color: #dc3545;
					}
				}
			}
			:checked {
				~ {
					.custom-control-label {
						::before {
							border-color: #e4606d;
							background-color: #e4606d;
						}
					}
				}
			}
			:focus {
				~ {
					.custom-control-label {
						::before {
							box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
						}
					}
				}
				:not(:checked) {
					~ {
						.custom-control-label {
							::before {
								border-color: #dc3545;
							}
						}
					}
				}
			}
		}
	}
	.custom-file-input {
		:valid {
			~ {
				.custom-file-label {
					border-color: #28a745;
				}
			}
			:focus {
				~ {
					.custom-file-label {
						border-color: #28a745;
						box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
					}
				}
			}
		}
		:invalid {
			~ {
				.custom-file-label {
					border-color: #dc3545;
				}
			}
			:focus {
				~ {
					.custom-file-label {
						border-color: #dc3545;
						box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
					}
				}
			}
		}
	}
	:invalid {
		~ {
			.invalid-feedback {
				display: block;
			}
			.invalid-tooltip {
				display: block;
			}
		}
	}
}
.is-valid {
	~ {
		.valid-feedback {
			display: block;
		}
		.valid-tooltip {
			display: block;
		}
	}
}
.form-control.is-valid {
	border-color: #28a745;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	:focus {
		border-color: #28a745;
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
	}
}
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-valid {
	border-color: #28a745;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	:focus {
		border-color: #28a745;
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
	}
}
.form-check-input.is-valid {
	~ {
		.form-check-label {
			color: #28a745;
		}
		.valid-feedback {
			display: block;
		}
		.valid-tooltip {
			display: block;
		}
	}
}
.custom-control-input.is-valid {
	~ {
		.custom-control-label {
			color: #28a745;
			::before {
				border-color: #28a745;
			}
		}
	}
	:checked {
		~ {
			.custom-control-label {
				::before {
					border-color: #34ce57;
					background-color: #34ce57;
				}
			}
		}
	}
	:focus {
		~ {
			.custom-control-label {
				::before {
					box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
				}
			}
		}
		:not(:checked) {
			~ {
				.custom-control-label {
					::before {
						border-color: #28a745;
					}
				}
			}
		}
	}
}
.custom-file-input.is-valid {
	~ {
		.custom-file-label {
			border-color: #28a745;
		}
	}
	:focus {
		~ {
			.custom-file-label {
				border-color: #28a745;
				box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
			}
		}
	}
}
.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
}
.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1.5;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.9);
	border-radius: 0.25rem;
}
.is-invalid {
	~ {
		.invalid-feedback {
			display: block;
		}
		.invalid-tooltip {
			display: block;
		}
	}
}
.form-control.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	:focus {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}
}
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid {
	border-color: #dc3545;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	:focus {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}
}
.form-check-input.is-invalid {
	~ {
		.form-check-label {
			color: #dc3545;
		}
		.invalid-feedback {
			display: block;
		}
		.invalid-tooltip {
			display: block;
		}
	}
}
.custom-control-input.is-invalid {
	~ {
		.custom-control-label {
			color: #dc3545;
			::before {
				border-color: #dc3545;
			}
		}
	}
	:checked {
		~ {
			.custom-control-label {
				::before {
					border-color: #e4606d;
					background-color: #e4606d;
				}
			}
		}
	}
	:focus {
		~ {
			.custom-control-label {
				::before {
					box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
				}
			}
		}
		:not(:checked) {
			~ {
				.custom-control-label {
					::before {
						border-color: #dc3545;
					}
				}
			}
		}
	}
}
.custom-file-input.is-invalid {
	~ {
		.custom-file-label {
			border-color: #dc3545;
		}
	}
	:focus {
		~ {
			.custom-file-label {
				border-color: #dc3545;
				box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
			}
		}
	}
}
.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	.form-check {
		width: 100%;
	}
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	:hover {
		color: #212529;
		text-decoration: none;
	}
	:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}
	:disabled {
		opacity: 0.65;
	}
	.badge {
		position: relative;
		top: -1px;
	}
}
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled {
	opacity: 0.65;
}
a.btn.disabled {
	pointer-events: none;
}
.btn-primary {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
	:hover {
		color: #fff;
		background-color: #0069d9;
		border-color: #0062cc;
	}
	:focus {
		color: #fff;
		background-color: #0069d9;
		border-color: #0062cc;
		box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #007bff;
		border-color: #007bff;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #0062cc;
				border-color: #005cbf;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #0062cc;
			border-color: #005cbf;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
			}
		}
	}
}
.btn-primary.focus {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
	box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}
.show {
	>.btn-primary.dropdown-toggle {
		color: #fff;
		background-color: #0062cc;
		border-color: #005cbf;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
		}
	}
	>.btn-secondary.dropdown-toggle {
		color: #fff;
		background-color: #545b62;
		border-color: #4e555b;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
		}
	}
	>.btn-success.dropdown-toggle {
		color: #fff;
		background-color: #1e7e34;
		border-color: #1c7430;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
		}
	}
	>.btn-info.dropdown-toggle {
		color: #fff;
		background-color: #117a8b;
		border-color: #10707f;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
		}
	}
	>.btn-warning.dropdown-toggle {
		color: #212529;
		background-color: #d39e00;
		border-color: #c69500;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
		}
	}
	>.btn-danger.dropdown-toggle {
		color: #fff;
		background-color: #bd2130;
		border-color: #b21f2d;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
		}
	}
	>.btn-light.dropdown-toggle {
		color: #212529;
		background-color: #dae0e5;
		border-color: #d3d9df;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
		}
	}
	>.btn-dark.dropdown-toggle {
		color: #fff;
		background-color: #1d2124;
		border-color: #171a1d;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
		}
	}
	>.btn-outline-primary.dropdown-toggle {
		color: #fff;
		background-color: #007bff;
		border-color: #007bff;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
		}
	}
	>.btn-outline-secondary.dropdown-toggle {
		color: #fff;
		background-color: #6c757d;
		border-color: #6c757d;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
		}
	}
	>.btn-outline-success.dropdown-toggle {
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
		}
	}
	>.btn-outline-info.dropdown-toggle {
		color: #fff;
		background-color: #17a2b8;
		border-color: #17a2b8;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
		}
	}
	>.btn-outline-warning.dropdown-toggle {
		color: #212529;
		background-color: #ffc107;
		border-color: #ffc107;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
		}
	}
	>.btn-outline-danger.dropdown-toggle {
		color: #fff;
		background-color: #dc3545;
		border-color: #dc3545;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
		}
	}
	>.btn-outline-light.dropdown-toggle {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
		}
	}
	>.btn-outline-dark.dropdown-toggle {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
		}
	}
}
.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
	:hover {
		color: #fff;
		background-color: #5a6268;
		border-color: #545b62;
	}
	:focus {
		color: #fff;
		background-color: #5a6268;
		border-color: #545b62;
		box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #6c757d;
		border-color: #6c757d;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #545b62;
				border-color: #4e555b;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #545b62;
			border-color: #4e555b;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
			}
		}
	}
}
.btn-secondary.focus {
	color: #fff;
	background-color: #5a6268;
	border-color: #545b62;
	box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-success {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
	:hover {
		color: #fff;
		background-color: #218838;
		border-color: #1e7e34;
	}
	:focus {
		color: #fff;
		background-color: #218838;
		border-color: #1e7e34;
		box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #1e7e34;
				border-color: #1c7430;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #1e7e34;
			border-color: #1c7430;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
			}
		}
	}
}
.btn-success.focus {
	color: #fff;
	background-color: #218838;
	border-color: #1e7e34;
	box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}
.btn-info {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
	:hover {
		color: #fff;
		background-color: #138496;
		border-color: #117a8b;
	}
	:focus {
		color: #fff;
		background-color: #138496;
		border-color: #117a8b;
		box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #17a2b8;
		border-color: #17a2b8;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #117a8b;
				border-color: #10707f;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #117a8b;
			border-color: #10707f;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
			}
		}
	}
}
.btn-info.focus {
	color: #fff;
	background-color: #138496;
	border-color: #117a8b;
	box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}
.btn-warning {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
	:hover {
		color: #212529;
		background-color: #e0a800;
		border-color: #d39e00;
	}
	:focus {
		color: #212529;
		background-color: #e0a800;
		border-color: #d39e00;
		box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
	}
	:disabled {
		color: #212529;
		background-color: #ffc107;
		border-color: #ffc107;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #212529;
				background-color: #d39e00;
				border-color: #c69500;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #212529;
			background-color: #d39e00;
			border-color: #c69500;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
			}
		}
	}
}
.btn-warning.focus {
	color: #212529;
	background-color: #e0a800;
	border-color: #d39e00;
	box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}
.btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
	:hover {
		color: #fff;
		background-color: #c82333;
		border-color: #bd2130;
	}
	:focus {
		color: #fff;
		background-color: #c82333;
		border-color: #bd2130;
		box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #dc3545;
		border-color: #dc3545;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #bd2130;
				border-color: #b21f2d;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #bd2130;
			border-color: #b21f2d;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
			}
		}
	}
}
.btn-danger.focus {
	color: #fff;
	background-color: #c82333;
	border-color: #bd2130;
	box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}
.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
	:hover {
		color: #212529;
		background-color: #e2e6ea;
		border-color: #dae0e5;
	}
	:focus {
		color: #212529;
		background-color: #e2e6ea;
		border-color: #dae0e5;
		box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
	}
	:disabled {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #212529;
				background-color: #dae0e5;
				border-color: #d3d9df;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #212529;
			background-color: #dae0e5;
			border-color: #d3d9df;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
			}
		}
	}
}
.btn-light.focus {
	color: #212529;
	background-color: #e2e6ea;
	border-color: #dae0e5;
	box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
	:hover {
		color: #fff;
		background-color: #23272b;
		border-color: #1d2124;
	}
	:focus {
		color: #fff;
		background-color: #23272b;
		border-color: #1d2124;
		box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
	}
	:disabled {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #1d2124;
				border-color: #171a1d;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #1d2124;
			border-color: #171a1d;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
			}
		}
	}
}
.btn-dark.focus {
	color: #fff;
	background-color: #23272b;
	border-color: #1d2124;
	box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-outline-primary {
	color: #007bff;
	border-color: #007bff;
	:hover {
		color: #fff;
		background-color: #007bff;
		border-color: #007bff;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}
	:disabled {
		color: #007bff;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #007bff;
				border-color: #007bff;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #007bff;
			border-color: #007bff;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
			}
		}
	}
}
.btn-outline-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled {
	color: #007bff;
	background-color: transparent;
}
.btn-outline-secondary {
	color: #6c757d;
	border-color: #6c757d;
	:hover {
		color: #fff;
		background-color: #6c757d;
		border-color: #6c757d;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
	}
	:disabled {
		color: #6c757d;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #6c757d;
				border-color: #6c757d;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #6c757d;
			border-color: #6c757d;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
			}
		}
	}
}
.btn-outline-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled {
	color: #6c757d;
	background-color: transparent;
}
.btn-outline-success {
	color: #28a745;
	border-color: #28a745;
	:hover {
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
	}
	:disabled {
		color: #28a745;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #28a745;
				border-color: #28a745;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #28a745;
			border-color: #28a745;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
			}
		}
	}
}
.btn-outline-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled {
	color: #28a745;
	background-color: transparent;
}
.btn-outline-info {
	color: #17a2b8;
	border-color: #17a2b8;
	:hover {
		color: #fff;
		background-color: #17a2b8;
		border-color: #17a2b8;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	}
	:disabled {
		color: #17a2b8;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #17a2b8;
				border-color: #17a2b8;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #17a2b8;
			border-color: #17a2b8;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
			}
		}
	}
}
.btn-outline-info.focus {
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled {
	color: #17a2b8;
	background-color: transparent;
}
.btn-outline-warning {
	color: #ffc107;
	border-color: #ffc107;
	:hover {
		color: #212529;
		background-color: #ffc107;
		border-color: #ffc107;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	}
	:disabled {
		color: #ffc107;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #212529;
				background-color: #ffc107;
				border-color: #ffc107;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #212529;
			background-color: #ffc107;
			border-color: #ffc107;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
			}
		}
	}
}
.btn-outline-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled {
	color: #ffc107;
	background-color: transparent;
}
.btn-outline-danger {
	color: #dc3545;
	border-color: #dc3545;
	:hover {
		color: #fff;
		background-color: #dc3545;
		border-color: #dc3545;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
	:disabled {
		color: #dc3545;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #dc3545;
				border-color: #dc3545;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #dc3545;
			border-color: #dc3545;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
			}
		}
	}
}
.btn-outline-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled {
	color: #dc3545;
	background-color: transparent;
}
.btn-outline-light {
	color: #f8f9fa;
	border-color: #f8f9fa;
	:hover {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
	}
	:disabled {
		color: #f8f9fa;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #212529;
				background-color: #f8f9fa;
				border-color: #f8f9fa;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #212529;
			background-color: #f8f9fa;
			border-color: #f8f9fa;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
			}
		}
	}
}
.btn-outline-light.focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled {
	color: #f8f9fa;
	background-color: transparent;
}
.btn-outline-dark {
	color: #343a40;
	border-color: #343a40;
	:hover {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
	}
	:focus {
		box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
	}
	:disabled {
		color: #343a40;
		background-color: transparent;
	}
	:not(:disabled) {
		:not(.disabled) {
			:active {
				color: #fff;
				background-color: #343a40;
				border-color: #343a40;
				:focus {
					box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
				}
			}
		}
		:not(.disabled).active {
			color: #fff;
			background-color: #343a40;
			border-color: #343a40;
			:focus {
				box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
			}
		}
	}
}
.btn-outline-dark.focus {
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled {
	color: #343a40;
	background-color: transparent;
}
.btn-link {
	font-weight: 400;
	color: #007bff;
	text-decoration: none;
	:hover {
		color: #0056b3;
		text-decoration: underline;
	}
	:focus {
		text-decoration: underline;
		box-shadow: none;
	}
	:disabled {
		color: #6c757d;
		pointer-events: none;
	}
}
.btn-link.focus {
	text-decoration: underline;
	box-shadow: none;
}
.btn-link.disabled {
	color: #6c757d;
	pointer-events: none;
}
.btn-lg {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0.3rem;
	+ {
		.dropdown-toggle-split {
			padding-right: 0.75rem;
			padding-left: 0.75rem;
		}
	}
}
.btn-group-lg {
	>.btn {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
		+ {
			.dropdown-toggle-split {
				padding-right: 0.75rem;
				padding-left: 0.75rem;
			}
		}
	}
}
.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
	+ {
		.dropdown-toggle-split {
			padding-right: 0.375rem;
			padding-left: 0.375rem;
		}
	}
}
.btn-group-sm {
	>.btn {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
		+ {
			.dropdown-toggle-split {
				padding-right: 0.375rem;
				padding-left: 0.375rem;
			}
		}
	}
}
.btn-block {
	display: block;
	width: 100%;
	+ {
		.btn-block {
			margin-top: 0.5rem;
		}
	}
}
input[type=submit].btn-block {
	width: 100%;
}
input[type=reset].btn-block {
	width: 100%;
}
input[type=button].btn-block {
	width: 100%;
}
.fade {
	transition: opacity 0.15s linear;
	:not(.show) {
		opacity: 0;
	}
}
.collapse {
	:not(.show) {
		display: none;
	}
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
.dropup {
	position: relative;
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}
	.dropdown-toggle {
		::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}
		:empty {
			::after {
				margin-left: 0;
			}
		}
	}
	.dropdown-toggle-split {
		::after {
			margin-left: 0;
		}
	}
}
.dropright {
	position: relative;
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}
	.dropdown-toggle {
		::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
			vertical-align: 0;
		}
		:empty {
			::after {
				margin-left: 0;
			}
		}
	}
	.dropdown-toggle-split {
		::after {
			margin-left: 0;
		}
	}
}
.dropdown {
	position: relative;
}
.dropleft {
	position: relative;
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}
	.dropdown-toggle {
		::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			display: none;
		}
		::before {
			display: inline-block;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
			vertical-align: 0;
		}
		:empty {
			::after {
				margin-left: 0;
			}
		}
	}
	.dropdown-toggle-split {
		::before {
			margin-right: 0;
		}
	}
}
.dropdown-toggle {
	white-space: nowrap;
	::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	:empty {
		::after {
			margin-left: 0;
		}
	}
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.dropdown-menu-left {
	right: auto;
	left: 0;
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropdown-menu[x-placement^=top] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^=right] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^=bottom] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^=left] {
	right: auto;
	bottom: auto;
}
.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	:hover {
		color: #16181b;
		text-decoration: none;
		background-color: #f8f9fa;
	}
	:focus {
		color: #16181b;
		text-decoration: none;
		background-color: #f8f9fa;
	}
	:active {
		color: #fff;
		text-decoration: none;
		background-color: #007bff;
	}
	:disabled {
		color: #6c757d;
		pointer-events: none;
		background-color: transparent;
	}
}
.dropdown-item.active {
	color: #fff;
	text-decoration: none;
	background-color: #007bff;
}
.dropdown-item.disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: transparent;
}
.dropdown-menu.show {
	display: block;
}
.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #6c757d;
	white-space: nowrap;
}
.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}
.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	>.btn {
		position: relative;
		flex: 1 1 auto;
		:hover {
			z-index: 1;
		}
		:focus {
			z-index: 1;
		}
		:active {
			z-index: 1;
		}
		:not(:first-child) {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		:not(:last-child) {
			:not(.dropdown-toggle) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	>.btn.active {
		z-index: 1;
	}
	>.btn-group {
		:not(:first-child) {
			margin-left: -1px;
			>.btn {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		:not(:last-child) {
			>.btn {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	>.btn {
		position: relative;
		flex: 1 1 auto;
		width: 100%;
		:hover {
			z-index: 1;
		}
		:focus {
			z-index: 1;
		}
		:active {
			z-index: 1;
		}
		:not(:first-child) {
			margin-top: -1px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		:not(:last-child) {
			:not(.dropdown-toggle) {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	>.btn.active {
		z-index: 1;
	}
	>.btn-group {
		width: 100%;
		:not(:first-child) {
			margin-top: -1px;
			>.btn {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		:not(:last-child) {
			>.btn {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}
.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	::after {
		margin-left: 0;
	}
}
.btn-group-toggle {
	>.btn {
		margin-bottom: 0;
		input[type=radio] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
		input[type=checkbox] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
	>.btn-group {
		>.btn {
			margin-bottom: 0;
			input[type=radio] {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;
			}
			input[type=checkbox] {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;
			}
		}
	}
}
.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	>.form-control {
		position: relative;
		flex: 1 1 0%;
		min-width: 0;
		margin-bottom: 0;
		+ {
			.form-control {
				margin-left: -1px;
			}
			.custom-select {
				margin-left: -1px;
			}
			.custom-file {
				margin-left: -1px;
			}
		}
		:focus {
			z-index: 3;
		}
		:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	>.form-control-plaintext {
		position: relative;
		flex: 1 1 0%;
		min-width: 0;
		margin-bottom: 0;
		+ {
			.form-control {
				margin-left: -1px;
			}
			.custom-select {
				margin-left: -1px;
			}
			.custom-file {
				margin-left: -1px;
			}
		}
	}
	>.custom-select {
		position: relative;
		flex: 1 1 0%;
		min-width: 0;
		margin-bottom: 0;
		+ {
			.form-control {
				margin-left: -1px;
			}
			.custom-select {
				margin-left: -1px;
			}
			.custom-file {
				margin-left: -1px;
			}
		}
		:focus {
			z-index: 3;
		}
		:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	>.custom-file {
		position: relative;
		flex: 1 1 0%;
		min-width: 0;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		+ {
			.form-control {
				margin-left: -1px;
			}
			.custom-select {
				margin-left: -1px;
			}
			.custom-file {
				margin-left: -1px;
			}
		}
		.custom-file-input {
			:focus {
				~ {
					.custom-file-label {
						z-index: 3;
					}
				}
				z-index: 4;
			}
		}
		:not(:last-child) {
			.custom-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				::after {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
		:not(:first-child) {
			.custom-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	>.input-group-prepend {
		>.btn {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		>.input-group-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		:not(:first-child) {
			>.btn {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
			>.input-group-text {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		:first-child {
			>.btn {
				:not(:first-child) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
			>.input-group-text {
				:not(:first-child) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
	>.input-group-append {
		:not(:last-child) {
			>.btn {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			>.input-group-text {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		:last-child {
			>.btn {
				:not(:last-child) {
					:not(.dropdown-toggle) {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
			}
			>.input-group-text {
				:not(:last-child) {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
		>.btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		>.input-group-text {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
.input-group-prepend {
	display: flex;
	margin-right: -1px;
	.btn {
		position: relative;
		z-index: 2;
		:focus {
			z-index: 3;
		}
		+ {
			.btn {
				margin-left: -1px;
			}
			.input-group-text {
				margin-left: -1px;
			}
		}
	}
	.input-group-text {
		+ {
			.input-group-text {
				margin-left: -1px;
			}
			.btn {
				margin-left: -1px;
			}
		}
	}
}
.input-group-append {
	display: flex;
	margin-left: -1px;
	.btn {
		position: relative;
		z-index: 2;
		:focus {
			z-index: 3;
		}
		+ {
			.btn {
				margin-left: -1px;
			}
			.input-group-text {
				margin-left: -1px;
			}
		}
	}
	.input-group-text {
		+ {
			.input-group-text {
				margin-left: -1px;
			}
			.btn {
				margin-left: -1px;
			}
		}
	}
}
.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	input[type=radio] {
		margin-top: 0;
	}
	input[type=checkbox] {
		margin-top: 0;
	}
}
.input-group-lg {
	>.form-control {
		:not(textarea) {
			height: calc(1.5em + 1rem + 2px);
		}
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
	}
	>.custom-select {
		height: calc(1.5em + 1rem + 2px);
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 0.3rem;
		padding-right: 1.75rem;
	}
	>.input-group-prepend {
		>.input-group-text {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
			line-height: 1.5;
			border-radius: 0.3rem;
		}
		>.btn {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
			line-height: 1.5;
			border-radius: 0.3rem;
		}
	}
	>.input-group-append {
		>.input-group-text {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
			line-height: 1.5;
			border-radius: 0.3rem;
		}
		>.btn {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
			line-height: 1.5;
			border-radius: 0.3rem;
		}
	}
}
.input-group-sm {
	>.form-control {
		:not(textarea) {
			height: calc(1.5em + 0.5rem + 2px);
		}
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}
	>.custom-select {
		height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
		padding-right: 1.75rem;
	}
	>.input-group-prepend {
		>.input-group-text {
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			line-height: 1.5;
			border-radius: 0.2rem;
		}
		>.btn {
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			line-height: 1.5;
			border-radius: 0.2rem;
		}
	}
	>.input-group-append {
		>.input-group-text {
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			line-height: 1.5;
			border-radius: 0.2rem;
		}
		>.btn {
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			line-height: 1.5;
			border-radius: 0.2rem;
		}
	}
}
.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}
.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem;
}
.custom-control-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.25rem;
	opacity: 0;
	:checked {
		~ {
			.custom-control-label {
				::before {
					color: #fff;
					border-color: #007bff;
					background-color: #007bff;
				}
			}
		}
	}
	:focus {
		~ {
			.custom-control-label {
				::before {
					box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
				}
			}
		}
		:not(:checked) {
			~ {
				.custom-control-label {
					::before {
						border-color: #80bdff;
					}
				}
			}
		}
	}
	:not(:disabled) {
		:active {
			~ {
				.custom-control-label {
					::before {
						color: #fff;
						background-color: #b3d7ff;
						border-color: #b3d7ff;
					}
				}
			}
		}
	}
	:disabled {
		~ {
			.custom-control-label {
				color: #6c757d;
				::before {
					background-color: #e9ecef;
				}
			}
		}
	}
}
.custom-control-input[disabled] {
	~ {
		.custom-control-label {
			color: #6c757d;
			::before {
				background-color: #e9ecef;
			}
		}
	}
}
.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	::before {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		background-color: #fff;
		border: #adb5bd solid 1px;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	::after {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background: no-repeat 50%/50% 50%;
	}
}
.custom-checkbox {
	.custom-control-label {
		::before {
			border-radius: 0.25rem;
		}
	}
	.custom-control-input {
		:checked {
			~ {
				.custom-control-label {
					::after {
						background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
					}
				}
			}
		}
		:indeterminate {
			~ {
				.custom-control-label {
					::before {
						border-color: #007bff;
						background-color: #007bff;
					}
					::after {
						background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
					}
				}
			}
		}
		:disabled {
			:checked {
				~ {
					.custom-control-label {
						::before {
							background-color: rgba(0, 123, 255, 0.5);
						}
					}
				}
			}
			:indeterminate {
				~ {
					.custom-control-label {
						::before {
							background-color: rgba(0, 123, 255, 0.5);
						}
					}
				}
			}
		}
	}
}
.custom-radio {
	.custom-control-label {
		::before {
			border-radius: 50%;
		}
	}
	.custom-control-input {
		:checked {
			~ {
				.custom-control-label {
					::after {
						background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
					}
				}
			}
		}
		:disabled {
			:checked {
				~ {
					.custom-control-label {
						::before {
							background-color: rgba(0, 123, 255, 0.5);
						}
					}
				}
			}
		}
	}
}
.custom-switch {
	padding-left: 2.25rem;
	.custom-control-label {
		::before {
			left: -2.25rem;
			width: 1.75rem;
			pointer-events: all;
			border-radius: 0.5rem;
		}
		::after {
			top: calc(0.25rem + 2px);
			left: calc(-2.25rem + 2px);
			width: calc(1rem - 4px);
			height: calc(1rem - 4px);
			background-color: #adb5bd;
			border-radius: 0.5rem;
			transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
	}
	.custom-control-input {
		:checked {
			~ {
				.custom-control-label {
					::after {
						background-color: #fff;
						transform: translateX(0.75rem);
					}
				}
			}
		}
		:disabled {
			:checked {
				~ {
					.custom-control-label {
						::before {
							background-color: rgba(0, 123, 255, 0.5);
						}
					}
				}
			}
		}
	}
}
.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	:focus {
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		::-ms-value {
			color: #495057;
			background-color: #fff;
		}
	}
	:disabled {
		color: #6c757d;
		background-color: #e9ecef;
	}
	::-ms-expand {
		display: none;
	}
	:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #495057;
	}
}
.custom-select[multiple] {
	height: auto;
	padding-right: 0.75rem;
	background-image: none;
}
.custom-select[size] {
	:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}
}
.custom-select-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
}
.custom-select-lg {
	height: calc(1.5em + 1rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
}
.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin-bottom: 0;
}
.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin: 0;
	opacity: 0;
	:focus {
		~ {
			.custom-file-label {
				border-color: #80bdff;
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}
		}
	}
	:disabled {
		~ {
			.custom-file-label {
				background-color: #e9ecef;
			}
		}
	}
	:lang(en) {
		~ {
			.custom-file-label {
				::after {
					content: "Browse";
				}
			}
		}
	}
	~ {
		.custom-file-label[data-browse] {
			::after {
				content: attr(data-browse);
			}
		}
	}
}
.custom-file-input[disabled] {
	~ {
		.custom-file-label {
			background-color: #e9ecef;
		}
	}
}
.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(1.5em + 0.75rem);
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		color: #495057;
		content: "Browse";
		background-color: #e9ecef;
		border-left: inherit;
		border-radius: 0 0.25rem 0.25rem 0;
	}
}
.custom-range {
	width: 100%;
	height: 1.4rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	:focus {
		outline: none;
		::-webkit-slider-thumb {
			box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
		::-moz-range-thumb {
			box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
		::-ms-thumb {
			box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
	}
	::-moz-focus-outer {
		border: 0;
	}
	::-webkit-slider-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: -0.25rem;
		background-color: #007bff;
		border: 0;
		border-radius: 1rem;
		-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		-webkit-appearance: none;
		appearance: none;
		:active {
			background-color: #b3d7ff;
		}
	}
	::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #dee2e6;
		border-color: transparent;
		border-radius: 1rem;
	}
	::-moz-range-thumb {
		width: 1rem;
		height: 1rem;
		background-color: #007bff;
		border: 0;
		border-radius: 1rem;
		-moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		-moz-appearance: none;
		appearance: none;
		:active {
			background-color: #b3d7ff;
		}
	}
	::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #dee2e6;
		border-color: transparent;
		border-radius: 1rem;
	}
	::-ms-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: 0;
		margin-right: 0.2rem;
		margin-left: 0.2rem;
		background-color: #007bff;
		border: 0;
		border-radius: 1rem;
		-ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		appearance: none;
		:active {
			background-color: #b3d7ff;
		}
	}
	::-ms-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;
		border-width: 0.5rem;
	}
	::-ms-fill-lower {
		background-color: #dee2e6;
		border-radius: 1rem;
	}
	::-ms-fill-upper {
		margin-right: 15px;
		background-color: #dee2e6;
		border-radius: 1rem;
	}
	:disabled {
		::-webkit-slider-thumb {
			background-color: #adb5bd;
		}
		::-webkit-slider-runnable-track {
			cursor: default;
		}
		::-moz-range-thumb {
			background-color: #adb5bd;
		}
		::-moz-range-track {
			cursor: default;
		}
		::-ms-thumb {
			background-color: #adb5bd;
		}
	}
}
.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	.nav-link {
		.sb-nav-link-icon {
			margin-right: 0.5rem;
		}
	}
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	:hover {
		text-decoration: none;
	}
	:focus {
		text-decoration: none;
	}
}
.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}
.nav-tabs {
	border-bottom: 1px solid #dee2e6;
	.nav-item {
		margin-bottom: -1px;
	}
	.nav-link {
		border: 1px solid transparent;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		:hover {
			border-color: #e9ecef #e9ecef #dee2e6;
		}
		:focus {
			border-color: #e9ecef #e9ecef #dee2e6;
		}
	}
	.nav-link.disabled {
		color: #6c757d;
		background-color: transparent;
		border-color: transparent;
	}
	.nav-link.active {
		color: #495057;
		background-color: #fff;
		border-color: #dee2e6 #dee2e6 #fff;
	}
	.nav-item.show {
		.nav-link {
			color: #495057;
			background-color: #fff;
			border-color: #dee2e6 #dee2e6 #fff;
		}
	}
	.dropdown-menu {
		margin-top: -1px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
.nav-pills {
	.nav-link {
		border-radius: 0.25rem;
	}
	.nav-link.active {
		color: #fff;
		background-color: #007bff;
	}
	.show {
		>.nav-link {
			color: #fff;
			background-color: #007bff;
		}
	}
}
.nav-fill {
	.nav-item {
		flex: 1 1 auto;
		text-align: center;
	}
}
.nav-justified {
	.nav-item {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
}
.tab-content {
	>.tab-pane {
		display: none;
	}
	>.active {
		display: block;
	}
}
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container-sm {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container-md {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container-lg {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container-xl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}
.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
	:hover {
		text-decoration: none;
	}
	:focus {
		text-decoration: none;
	}
}
.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	.nav-link {
		padding-right: 0;
		padding-left: 0;
	}
	.dropdown-menu {
		position: static;
		float: none;
	}
}
.navbar-text {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}
.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	:hover {
		text-decoration: none;
	}
	:focus {
		text-decoration: none;
	}
}
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}
.navbar-expand {
	flex-flow: row nowrap;
	justify-content: flex-start;
	>.container {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	>.container-fluid {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	>.container-sm {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	>.container-md {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	>.container-lg {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	>.container-xl {
		padding-right: 0;
		padding-left: 0;
		flex-wrap: nowrap;
	}
	.navbar-nav {
		flex-direction: row;
		.dropdown-menu {
			position: absolute;
		}
		.nav-link {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}
	}
	.navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-toggler {
		display: none;
	}
}
.navbar-light {
	.navbar-brand {
		color: rgba(0, 0, 0, 0.9);
		:hover {
			color: rgba(0, 0, 0, 0.9);
		}
		:focus {
			color: rgba(0, 0, 0, 0.9);
		}
	}
	.navbar-nav {
		.nav-link {
			color: rgba(0, 0, 0, 0.5);
			:hover {
				color: rgba(0, 0, 0, 0.7);
			}
			:focus {
				color: rgba(0, 0, 0, 0.7);
			}
		}
		.nav-link.disabled {
			color: rgba(0, 0, 0, 0.3);
		}
		.show {
			>.nav-link {
				color: rgba(0, 0, 0, 0.9);
			}
		}
		.active {
			>.nav-link {
				color: rgba(0, 0, 0, 0.9);
			}
		}
		.nav-link.show {
			color: rgba(0, 0, 0, 0.9);
		}
		.nav-link.active {
			color: rgba(0, 0, 0, 0.9);
		}
	}
	.navbar-toggler {
		color: rgba(0, 0, 0, 0.5);
		border-color: rgba(0, 0, 0, 0.1);
	}
	.navbar-toggler-icon {
		/*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");';*/
	}
	.navbar-text {
		color: rgba(0, 0, 0, 0.5);
		a {
			color: rgba(0, 0, 0, 0.9);
			:hover {
				color: rgba(0, 0, 0, 0.9);
			}
			:focus {
				color: rgba(0, 0, 0, 0.9);
			}
		}
	}
}
.navbar-dark {
	.navbar-brand {
		color: #fff;
		:hover {
			color: #fff;
		}
		:focus {
			color: #fff;
		}
	}
	.navbar-nav {
		.nav-link {
			color: rgba(255, 255, 255, 0.5);
			:hover {
				color: rgba(255, 255, 255, 0.75);
			}
			:focus {
				color: rgba(255, 255, 255, 0.75);
			}
		}
		.nav-link.disabled {
			color: rgba(255, 255, 255, 0.25);
		}
		.show {
			>.nav-link {
				color: #fff;
			}
		}
		.active {
			>.nav-link {
				color: #fff;
			}
		}
		.nav-link.show {
			color: #fff;
		}
		.nav-link.active {
			color: #fff;
		}
	}
	.navbar-toggler {
		color: rgba(255, 255, 255, 0.5);
		border-color: rgba(255, 255, 255, 0.1);
	}
	.navbar-toggler-icon {
		/*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");';*/
	}
	.navbar-text {
		color: rgba(255, 255, 255, 0.5);
		a {
			color: #fff;
			:hover {
				color: #fff;
			}
			:focus {
				color: #fff;
			}
		}
	}
}
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	>hr {
		margin-right: 0;
		margin-left: 0;
	}
	>.list-group {
		:first-child {
			.list-group-item {
				:first-child {
					border-top-left-radius: 0.25rem;
					border-top-right-radius: 0.25rem;
				}
			}
		}
		:last-child {
			.list-group-item {
				:last-child {
					border-bottom-right-radius: 0.25rem;
					border-bottom-left-radius: 0.25rem;
				}
			}
		}
	}
}
.card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}
.card-title {
	margin-bottom: 0.75rem;
}
.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}
.card-text {
	:last-child {
		margin-bottom: 0;
	}
}
.card-link {
	:hover {
		text-decoration: none;
	}
	+ {
		.card-link {
			margin-left: 1.25rem;
		}
	}
}
.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	:first-child {
		border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
	}
	+ {
		.list-group {
			.list-group-item {
				:first-child {
					border-top: 0;
				}
			}
		}
	}
}
.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
	:last-child {
		border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
	}
}
.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
}
.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}
.card-img {
	flex-shrink: 0;
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-img-top {
	flex-shrink: 0;
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
	flex-shrink: 0;
	width: 100%;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
	.card {
		margin-bottom: 15px;
	}
}
.card-group {
	>.card {
		margin-bottom: 15px;
	}
}
.card-columns {
	.card {
		margin-bottom: 0.75rem;
	}
}
.accordion {
	>.card {
		overflow: hidden;
		:not(:last-of-type) {
			border-bottom: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		:not(:first-of-type) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		>.card-header {
			border-radius: 0;
			margin-bottom: -1px;
		}
	}
}
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}
.breadcrumb-item {
	+ {
		.breadcrumb-item {
			padding-left: 0.5rem;
			::before {
				display: inline-block;
				padding-right: 0.5rem;
				color: #6c757d;
				content: "/";
			}
			:hover {
				::before {
					text-decoration: underline;
					text-decoration: none;
				}
			}
		}
	}
}
.breadcrumb-item.active {
	color: #6c757d;
}
.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #007bff;
	background-color: #fff;
	border: 1px solid #dee2e6;
	:hover {
		z-index: 2;
		color: #0056b3;
		text-decoration: none;
		background-color: #e9ecef;
		border-color: #dee2e6;
	}
	:focus {
		z-index: 3;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}
}
.page-item {
	:first-child {
		.page-link {
			margin-left: 0;
			border-top-left-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;
		}
	}
	:last-child {
		.page-link {
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
		}
	}
}
.page-item.active {
	.page-link {
		z-index: 3;
		color: #fff;
		background-color: #007bff;
		border-color: #007bff;
	}
}
.page-item.disabled {
	.page-link {
		color: #6c757d;
		pointer-events: none;
		cursor: auto;
		background-color: #fff;
		border-color: #dee2e6;
	}
}
.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.25rem;
		line-height: 1.5;
	}
	.page-item {
		:first-child {
			.page-link {
				border-top-left-radius: 0.3rem;
				border-bottom-left-radius: 0.3rem;
			}
		}
		:last-child {
			.page-link {
				border-top-right-radius: 0.3rem;
				border-bottom-right-radius: 0.3rem;
			}
		}
	}
}
.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.page-item {
		:first-child {
			.page-link {
				border-top-left-radius: 0.2rem;
				border-bottom-left-radius: 0.2rem;
			}
		}
		:last-child {
			.page-link {
				border-top-right-radius: 0.2rem;
				border-bottom-right-radius: 0.2rem;
			}
		}
	}
}
.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	:empty {
		display: none;
	}
}
a.badge {
	:hover {
		text-decoration: none;
	}
	:focus {
		text-decoration: none;
	}
}
.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}
.badge-primary {
	color: #fff;
	background-color: #007bff;
}
a.badge-primary {
	:hover {
		color: #fff;
		background-color: #0062cc;
	}
	:focus {
		color: #fff;
		background-color: #0062cc;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}
}
a.badge-primary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
	color: #fff;
	background-color: #6c757d;
}
a.badge-secondary {
	:hover {
		color: #fff;
		background-color: #545b62;
	}
	:focus {
		color: #fff;
		background-color: #545b62;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
	}
}
a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
	color: #fff;
	background-color: #28a745;
}
a.badge-success {
	:hover {
		color: #fff;
		background-color: #1e7e34;
	}
	:focus {
		color: #fff;
		background-color: #1e7e34;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
	}
}
a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
	color: #fff;
	background-color: #17a2b8;
}
a.badge-info {
	:hover {
		color: #fff;
		background-color: #117a8b;
	}
	:focus {
		color: #fff;
		background-color: #117a8b;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	}
}
a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
	color: #212529;
	background-color: #ffc107;
}
a.badge-warning {
	:hover {
		color: #212529;
		background-color: #d39e00;
	}
	:focus {
		color: #212529;
		background-color: #d39e00;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	}
}
a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
	color: #fff;
	background-color: #dc3545;
}
a.badge-danger {
	:hover {
		color: #fff;
		background-color: #bd2130;
	}
	:focus {
		color: #fff;
		background-color: #bd2130;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
}
a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
	color: #212529;
	background-color: #f8f9fa;
}
a.badge-light {
	:hover {
		color: #212529;
		background-color: #dae0e5;
	}
	:focus {
		color: #212529;
		background-color: #dae0e5;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
	}
}
a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
	color: #fff;
	background-color: #343a40;
}
a.badge-dark {
	:hover {
		color: #fff;
		background-color: #1d2124;
	}
	:focus {
		color: #fff;
		background-color: #1d2124;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
	}
}
a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
}
.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}
.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.alert-heading {
	color: inherit;
}
.alert-link {
	font-weight: 700;
}
.alert-dismissible {
	padding-right: 4rem;
	.close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.75rem 1.25rem;
		color: inherit;
	}
}
.alert-primary {
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
	hr {
		border-top-color: #9fcdff;
	}
	.alert-link {
		color: #002752;
	}
}
.alert-secondary {
	color: #383d41;
	background-color: #e2e3e5;
	border-color: #d6d8db;
	hr {
		border-top-color: #c8cbcf;
	}
	.alert-link {
		color: #202326;
	}
}
.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
	hr {
		border-top-color: #b1dfbb;
	}
	.alert-link {
		color: #0b2e13;
	}
}
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
	hr {
		border-top-color: #abdde5;
	}
	.alert-link {
		color: #062c33;
	}
}
.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
	hr {
		border-top-color: #ffe8a1;
	}
	.alert-link {
		color: #533f03;
	}
}
.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	hr {
		border-top-color: #f1b0b7;
	}
	.alert-link {
		color: #491217;
	}
}
.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}
.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}
.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}
.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #007bff;
	transition: width 0.6s ease;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
.media {
	display: flex;
	align-items: flex-start;
}
.media-body {
	flex: 1;
}
.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}
.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
	:hover {
		z-index: 1;
		color: #495057;
		text-decoration: none;
		background-color: #f8f9fa;
	}
	:focus {
		z-index: 1;
		color: #495057;
		text-decoration: none;
		background-color: #f8f9fa;
	}
	:active {
		color: #212529;
		background-color: #e9ecef;
	}
}
.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
	:first-child {
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}
	:last-child {
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}
	:disabled {
		color: #6c757d;
		pointer-events: none;
		background-color: #fff;
	}
	+ {
		.list-group-item {
			border-top-width: 0;
		}
		.list-group-item.active {
			margin-top: -1px;
			border-top-width: 1px;
		}
	}
}
.list-group-item.disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}
.list-group-horizontal {
	flex-direction: row;
	.list-group-item {
		:first-child {
			border-bottom-left-radius: 0.25rem;
			border-top-right-radius: 0;
		}
		:last-child {
			border-top-right-radius: 0.25rem;
			border-bottom-left-radius: 0;
		}
		+ {
			.list-group-item {
				border-top-width: 1px;
				border-left-width: 0;
			}
			.list-group-item.active {
				margin-left: -1px;
				border-left-width: 1px;
			}
		}
	}
	.list-group-item.active {
		margin-top: 0;
	}
}
.list-group-flush {
	.list-group-item {
		border-right-width: 0;
		border-left-width: 0;
		border-radius: 0;
		:first-child {
			border-top-width: 0;
		}
	}
	:last-child {
		.list-group-item {
			:last-child {
				border-bottom-width: 0;
			}
		}
	}
}
.list-group-item-primary {
	color: #004085;
	background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action {
	:hover {
		color: #004085;
		background-color: #9fcdff;
	}
	:focus {
		color: #004085;
		background-color: #9fcdff;
	}
}
.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #004085;
	border-color: #004085;
}
.list-group-item-secondary {
	color: #383d41;
	background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action {
	:hover {
		color: #383d41;
		background-color: #c8cbcf;
	}
	:focus {
		color: #383d41;
		background-color: #c8cbcf;
	}
}
.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #383d41;
	border-color: #383d41;
}
.list-group-item-success {
	color: #155724;
	background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action {
	:hover {
		color: #155724;
		background-color: #b1dfbb;
	}
	:focus {
		color: #155724;
		background-color: #b1dfbb;
	}
}
.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #155724;
	border-color: #155724;
}
.list-group-item-info {
	color: #0c5460;
	background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action {
	:hover {
		color: #0c5460;
		background-color: #abdde5;
	}
	:focus {
		color: #0c5460;
		background-color: #abdde5;
	}
}
.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #0c5460;
	border-color: #0c5460;
}
.list-group-item-warning {
	color: #856404;
	background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action {
	:hover {
		color: #856404;
		background-color: #ffe8a1;
	}
	:focus {
		color: #856404;
		background-color: #ffe8a1;
	}
}
.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #856404;
	border-color: #856404;
}
.list-group-item-danger {
	color: #721c24;
	background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action {
	:hover {
		color: #721c24;
		background-color: #f1b0b7;
	}
	:focus {
		color: #721c24;
		background-color: #f1b0b7;
	}
}
.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #721c24;
	border-color: #721c24;
}
.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action {
	:hover {
		color: #818182;
		background-color: #ececf6;
	}
	:focus {
		color: #818182;
		background-color: #ececf6;
	}
}
.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #818182;
	border-color: #818182;
}
.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action {
	:hover {
		color: #1b1e21;
		background-color: #b9bbbe;
	}
	:focus {
		color: #1b1e21;
		background-color: #b9bbbe;
	}
}
.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1b1e21;
	border-color: #1b1e21;
}
.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	:hover {
		color: #000;
		text-decoration: none;
	}
	:not(:disabled) {
		:not(.disabled) {
			:hover {
				opacity: 0.75;
			}
			:focus {
				opacity: 0.75;
			}
		}
	}
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
a.close.disabled {
	pointer-events: none;
}
.toast {
	max-width: 350px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	opacity: 0;
	border-radius: 0.25rem;
	:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}
.toast.showing {
	opacity: 1;
}
.toast.show {
	display: block;
	opacity: 1;
}
.toast.hide {
	display: none;
}
.toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
	padding: 0.75rem;
}
.modal-open {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade {
	.modal-dialog {
		transition: transform 0.3s ease-out;
		transform: translate(0, -50px);
	}
}
.modal.show {
	.modal-dialog {
		transform: none;
	}
}
.modal.modal-static {
	.modal-dialog {
		transform: scale(1.02);
	}
}
.modal-dialog-scrollable {
	display: flex;
	max-height: calc(100% - 1rem);
	.modal-content {
		max-height: calc(100vh - 1rem);
		overflow: hidden;
	}
	.modal-header {
		flex-shrink: 0;
	}
	.modal-footer {
		flex-shrink: 0;
	}
	.modal-body {
		overflow-y: auto;
	}
}
.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
	::before {
		display: block;
		height: calc(100vh - 1rem);
		content: "";
	}
}
.modal-dialog-centered.modal-dialog-scrollable {
	flex-direction: column;
	justify-content: center;
	height: 100%;
	.modal-content {
		max-height: none;
	}
	::before {
		content: none;
	}
}
.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: 0.5;
}
.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
	.close {
		padding: 1rem 1rem;
		margin: -1rem -1rem -1rem auto;
	}
}
.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}
.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}
.modal-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
	>* {
		margin: 0.25rem;
	}
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
	.arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;
		::before {
			position: absolute;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.tooltip.show {
	opacity: 0.9;
}
.bs-tooltip-top {
	padding: 0.4rem 0;
	.arrow {
		bottom: 0;
		::before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #000;
		}
	}
}
.bs-tooltip-auto[x-placement^=top] {
	padding: 0.4rem 0;
	.arrow {
		bottom: 0;
		::before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #000;
		}
	}
}
.bs-tooltip-right {
	padding: 0 0.4rem;
	.arrow {
		left: 0;
		width: 0.4rem;
		height: 0.8rem;
		::before {
			right: 0;
			border-width: 0.4rem 0.4rem 0.4rem 0;
			border-right-color: #000;
		}
	}
}
.bs-tooltip-auto[x-placement^=right] {
	padding: 0 0.4rem;
	.arrow {
		left: 0;
		width: 0.4rem;
		height: 0.8rem;
		::before {
			right: 0;
			border-width: 0.4rem 0.4rem 0.4rem 0;
			border-right-color: #000;
		}
	}
}
.bs-tooltip-bottom {
	padding: 0.4rem 0;
	.arrow {
		top: 0;
		::before {
			bottom: 0;
			border-width: 0 0.4rem 0.4rem;
			border-bottom-color: #000;
		}
	}
}
.bs-tooltip-auto[x-placement^=bottom] {
	padding: 0.4rem 0;
	.arrow {
		top: 0;
		::before {
			bottom: 0;
			border-width: 0 0.4rem 0.4rem;
			border-bottom-color: #000;
		}
	}
}
.bs-tooltip-left {
	padding: 0 0.4rem;
	.arrow {
		right: 0;
		width: 0.4rem;
		height: 0.8rem;
		::before {
			left: 0;
			border-width: 0.4rem 0 0.4rem 0.4rem;
			border-left-color: #000;
		}
	}
}
.bs-tooltip-auto[x-placement^=left] {
	padding: 0 0.4rem;
	.arrow {
		right: 0;
		width: 0.4rem;
		height: 0.8rem;
		::before {
			left: 0;
			border-width: 0.4rem 0 0.4rem 0.4rem;
			border-left-color: #000;
		}
	}
}
.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.25rem;
}
.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	.arrow {
		position: absolute;
		display: block;
		width: 1rem;
		height: 0.5rem;
		margin: 0 0.3rem;
		::before {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
		::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.bs-popover-top {
	margin-bottom: 0.5rem;
	>.arrow {
		bottom: calc(-0.5rem - 1px);
		::before {
			bottom: 0;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			bottom: 1px;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: #fff;
		}
	}
}
.bs-popover-auto[x-placement^=top] {
	margin-bottom: 0.5rem;
	>.arrow {
		bottom: calc(-0.5rem - 1px);
		::before {
			bottom: 0;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			bottom: 1px;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: #fff;
		}
	}
}
.bs-popover-right {
	margin-left: 0.5rem;
	>.arrow {
		left: calc(-0.5rem - 1px);
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		::before {
			left: 0;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			left: 1px;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: #fff;
		}
	}
}
.bs-popover-auto[x-placement^=right] {
	margin-left: 0.5rem;
	>.arrow {
		left: calc(-0.5rem - 1px);
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		::before {
			left: 0;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			left: 1px;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: #fff;
		}
	}
}
.bs-popover-bottom {
	margin-top: 0.5rem;
	>.arrow {
		top: calc(-0.5rem - 1px);
		::before {
			top: 0;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			top: 1px;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: #fff;
		}
	}
	.popover-header {
		::before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 1rem;
			margin-left: -0.5rem;
			content: "";
			border-bottom: 1px solid #f7f7f7;
		}
	}
}
.bs-popover-auto[x-placement^=bottom] {
	margin-top: 0.5rem;
	>.arrow {
		top: calc(-0.5rem - 1px);
		::before {
			top: 0;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			top: 1px;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: #fff;
		}
	}
	.popover-header {
		::before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 1rem;
			margin-left: -0.5rem;
			content: "";
			border-bottom: 1px solid #f7f7f7;
		}
	}
}
.bs-popover-left {
	margin-right: 0.5rem;
	>.arrow {
		right: calc(-0.5rem - 1px);
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		::before {
			right: 0;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			right: 1px;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: #fff;
		}
	}
}
.bs-popover-auto[x-placement^=left] {
	margin-right: 0.5rem;
	>.arrow {
		right: calc(-0.5rem - 1px);
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		::before {
			right: 0;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: rgba(0, 0, 0, 0.25);
		}
		::after {
			right: 1px;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: #fff;
		}
	}
}
.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
	:empty {
		display: none;
	}
}
.popover-body {
	padding: 0.5rem 0.75rem;
	color: #212529;
}
.carousel {
	position: relative;
}
.carousel.pointer-event {
	touch-action: pan-y;
}
.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	::after {
		display: block;
		clear: both;
		content: "";
	}
}
.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}
.carousel-item.active {
	display: block;
}
.carousel-item-next {
	display: block;
	:not(.carousel-item-left) {
		transform: translateX(100%);
	}
}
.carousel-item-prev {
	display: block;
	:not(.carousel-item-right) {
		transform: translateX(-100%);
	}
}
.active.carousel-item-right {
	transform: translateX(100%);
}
.active.carousel-item-left {
	transform: translateX(-100%);
}
.carousel-fade {
	.carousel-item {
		opacity: 0;
		transition-property: opacity;
		transform: none;
	}
	.carousel-item.active {
		z-index: 1;
		opacity: 1;
	}
	.carousel-item-next.carousel-item-left {
		z-index: 1;
		opacity: 1;
	}
	.carousel-item-prev.carousel-item-right {
		z-index: 1;
		opacity: 1;
	}
	.active.carousel-item-left {
		z-index: 0;
		opacity: 0;
		transition: opacity 0s 0.6s;
	}
	.active.carousel-item-right {
		z-index: 0;
		opacity: 0;
		transition: opacity 0s 0.6s;
	}
}
.carousel-control-prev {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #fff;
	text-align: center;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	left: 0;
	:hover {
		color: #fff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
	:focus {
		color: #fff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
}
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #fff;
	text-align: center;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	right: 0;
	:hover {
		color: #fff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
	:focus {
		color: #fff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
}
.carousel-control-prev-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: no-repeat 50%/100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: no-repeat 50%/100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		box-sizing: content-box;
		flex: 0 1 auto;
		width: 30px;
		height: 3px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		cursor: pointer;
		background-color: #fff;
		background-clip: padding-box;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		opacity: 0.5;
		transition: opacity 0.6s ease;
	}
	.active {
		opacity: 1;
	}
}
.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
}
.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border 0.75s linear infinite;
	animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: spinner-grow 0.75s linear infinite;
	animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}
.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}
.bg-primary {
	background-color: #007bff !important;
}
a.bg-primary {
	:hover {
		background-color: #0062cc !important;
	}
	:focus {
		background-color: #0062cc !important;
	}
}
button.bg-primary {
	:hover {
		background-color: #0062cc !important;
	}
	:focus {
		background-color: #0062cc !important;
	}
}
.bg-secondary {
	background-color: #6c757d !important;
}
a.bg-secondary {
	:hover {
		background-color: #545b62 !important;
	}
	:focus {
		background-color: #545b62 !important;
	}
}
button.bg-secondary {
	:hover {
		background-color: #545b62 !important;
	}
	:focus {
		background-color: #545b62 !important;
	}
}
.bg-success {
	background-color: #28a745 !important;
}
a.bg-success {
	:hover {
		background-color: #1e7e34 !important;
	}
	:focus {
		background-color: #1e7e34 !important;
	}
}
button.bg-success {
	:hover {
		background-color: #1e7e34 !important;
	}
	:focus {
		background-color: #1e7e34 !important;
	}
}
.bg-info {
	background-color: #17a2b8 !important;
}
a.bg-info {
	:hover {
		background-color: #117a8b !important;
	}
	:focus {
		background-color: #117a8b !important;
	}
}
button.bg-info {
	:hover {
		background-color: #117a8b !important;
	}
	:focus {
		background-color: #117a8b !important;
	}
}
.bg-warning {
	background-color: #ffc107 !important;
}
a.bg-warning {
	:hover {
		background-color: #d39e00 !important;
	}
	:focus {
		background-color: #d39e00 !important;
	}
}
button.bg-warning {
	:hover {
		background-color: #d39e00 !important;
	}
	:focus {
		background-color: #d39e00 !important;
	}
}
.bg-danger {
	background-color: #dc3545 !important;
}
a.bg-danger {
	:hover {
		background-color: #bd2130 !important;
	}
	:focus {
		background-color: #bd2130 !important;
	}
}
button.bg-danger {
	:hover {
		background-color: #bd2130 !important;
	}
	:focus {
		background-color: #bd2130 !important;
	}
}
.bg-light {
	background-color: #f8f9fa !important;
}
a.bg-light {
	:hover {
		background-color: #dae0e5 !important;
	}
	:focus {
		background-color: #dae0e5 !important;
	}
}
button.bg-light {
	:hover {
		background-color: #dae0e5 !important;
	}
	:focus {
		background-color: #dae0e5 !important;
	}
}
.bg-dark {
	background-color: #343a40 !important;
}
a.bg-dark {
	:hover {
		background-color: #1d2124 !important;
	}
	:focus {
		background-color: #1d2124 !important;
	}
}
button.bg-dark {
	:hover {
		background-color: #1d2124 !important;
	}
	:focus {
		background-color: #1d2124 !important;
	}
}
.bg-white {
	background-color: #fff !important;
}
.bg-transparent {
	background-color: transparent !important;
}
.border {
	border: 1px solid #dee2e6 !important;
}
.border-top {
	border-top: 1px solid #dee2e6 !important;
}
.border-right {
	border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
	border-left: 1px solid #dee2e6 !important;
}
.border-0 {
	border: 0 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-right-0 {
	border-right: 0 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-left-0 {
	border-left: 0 !important;
}
.border-primary {
	border-color: #007bff !important;
}
.border-secondary {
	border-color: #6c757d !important;
}
.border-success {
	border-color: #28a745 !important;
}
.border-info {
	border-color: #17a2b8 !important;
}
.border-warning {
	border-color: #ffc107 !important;
}
.border-danger {
	border-color: #dc3545 !important;
}
.border-light {
	border-color: #f8f9fa !important;
}
.border-dark {
	border-color: #343a40 !important;
}
.border-white {
	border-color: #fff !important;
}
.rounded-sm {
	border-radius: 0.2rem !important;
}
.rounded {
	border-radius: 0.25rem !important;
}
.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}
.rounded-right {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
	border-radius: 0.3rem !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-pill {
	border-radius: 50rem !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.clearfix {
	::after {
		display: block;
		clear: both;
		content: "";
	}
}
.d-none {
	display: none !important;
}
.d-inline {
	display: inline !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-block {
	display: block !important;
}
.d-table {
	display: table !important;
}
.d-table-row {
	display: table-row !important;
}
.d-table-cell {
	display: table-cell !important;
}
.d-flex {
	display: flex !important;
}
.d-inline-flex {
	display: inline-flex !important;
}
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	::before {
		display: block;
		content: "";
	}
	.embed-responsive-item {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	embed {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	object {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.embed-responsive-21by9 {
	::before {
		padding-top: 42.8571428571%;
		padding-top: 42.8571428571%;
	}
}
.embed-responsive-16by9 {
	::before {
		padding-top: 56.25%;
		padding-top: 56.25%;
	}
}
.embed-responsive-4by3 {
	::before {
		padding-top: 75%;
		padding-top: 75%;
	}
}
.embed-responsive-1by1 {
	::before {
		padding-top: 100%;
		padding-top: 100%;
	}
}
.flex-row {
	flex-direction: row !important;
}
.flex-column {
	flex-direction: column !important;
}
.flex-row-reverse {
	flex-direction: row-reverse !important;
}
.flex-column-reverse {
	flex-direction: column-reverse !important;
}
.flex-wrap {
	flex-wrap: wrap !important;
}
.flex-nowrap {
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}
.flex-fill {
	flex: 1 1 auto !important;
}
.flex-grow-0 {
	flex-grow: 0 !important;
}
.flex-grow-1 {
	flex-grow: 1 !important;
}
.flex-shrink-0 {
	flex-shrink: 0 !important;
}
.flex-shrink-1 {
	flex-shrink: 1 !important;
}
.justify-content-start {
	justify-content: flex-start !important;
}
.justify-content-end {
	justify-content: flex-end !important;
}
.justify-content-center {
	justify-content: center !important;
}
.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-around {
	justify-content: space-around !important;
}
.align-items-start {
	align-items: flex-start !important;
}
.align-items-end {
	align-items: flex-end !important;
}
.align-items-center {
	align-items: center !important;
}
.align-items-baseline {
	align-items: baseline !important;
}
.align-items-stretch {
	align-items: stretch !important;
}
.align-content-start {
	align-content: flex-start !important;
}
.align-content-end {
	align-content: flex-end !important;
}
.align-content-center {
	align-content: center !important;
}
.align-content-between {
	align-content: space-between !important;
}
.align-content-around {
	align-content: space-around !important;
}
.align-content-stretch {
	align-content: stretch !important;
}
.align-self-auto {
	align-self: auto !important;
}
.align-self-start {
	align-self: flex-start !important;
}
.align-self-end {
	align-self: flex-end !important;
}
.align-self-center {
	align-self: center !important;
}
.align-self-baseline {
	align-self: baseline !important;
}
.align-self-stretch {
	align-self: stretch !important;
}
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
.overflow-auto {
	overflow: auto !important;
}
.overflow-hidden {
	overflow: hidden !important;
}
.position-static {
	position: static !important;
}
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}


.sr-only-focusable {
	:active {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
	:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}
.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
	box-shadow: none !important;
}
.w-25 {
	width: 25% !important;
}
.w-50 {
	width: 50% !important;
}
.w-75 {
	width: 75% !important;
}
.w-100 {
	width: 100% !important;
}
.w-auto {
	width: auto !important;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.mw-100 {
	max-width: 100% !important;
}
.mh-100 {
	max-height: 100% !important;
}
.min-vw-100 {
	min-width: 100vw !important;
}
.min-vh-100 {
	min-height: 100vh !important;
}
.vw-100 {
	width: 100vw !important;
}
.vh-100 {
	height: 100vh !important;
}
.stretched-link {
	::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		pointer-events: auto;
		content: "";
		background-color: rgba(0, 0, 0, 0);
	}
}
.m-0 {
	margin: 0 !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.mt-1 {
	margin-top: 0.25rem !important;
}
.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}
.mr-1 {
	margin-right: 0.25rem !important;
}
.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}
.mb-1 {
	margin-bottom: 0.25rem !important;
}
.ml-1 {
	margin-left: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.ml-2 {
	margin-left: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.m-4 {
	margin: 1.5rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.mt-5 {
	margin-top: 3rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.p-0 {
	padding: 0 !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.pt-1 {
	padding-top: 0.25rem !important;
}
.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}
.pr-1 {
	padding-right: 0.25rem !important;
}
.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}
.pb-1 {
	padding-bottom: 0.25rem !important;
}
.pl-1 {
	padding-left: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-2 {
	padding-top: 0.5rem !important;
}
.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}
.pr-2 {
	padding-right: 0.5rem !important;
}
.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pl-2 {
	padding-left: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.pt-3 {
	padding-top: 1rem !important;
}
.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.pt-4 {
	padding-top: 1.5rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}
.pt-5 {
	padding-top: 3rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.m-n1 {
	margin: -0.25rem !important;
}
.mt-n1 {
	margin-top: -0.25rem !important;
}
.my-n1 {
	margin-top: -0.25rem !important;
	margin-bottom: -0.25rem !important;
}
.mr-n1 {
	margin-right: -0.25rem !important;
}
.mx-n1 {
	margin-right: -0.25rem !important;
	margin-left: -0.25rem !important;
}
.mb-n1 {
	margin-bottom: -0.25rem !important;
}
.ml-n1 {
	margin-left: -0.25rem !important;
}
.m-n2 {
	margin: -0.5rem !important;
}
.mt-n2 {
	margin-top: -0.5rem !important;
}
.my-n2 {
	margin-top: -0.5rem !important;
	margin-bottom: -0.5rem !important;
}
.mr-n2 {
	margin-right: -0.5rem !important;
}
.mx-n2 {
	margin-right: -0.5rem !important;
	margin-left: -0.5rem !important;
}
.mb-n2 {
	margin-bottom: -0.5rem !important;
}
.ml-n2 {
	margin-left: -0.5rem !important;
}
.m-n3 {
	margin: -1rem !important;
}
.mt-n3 {
	margin-top: -1rem !important;
}
.my-n3 {
	margin-top: -1rem !important;
	margin-bottom: -1rem !important;
}
.mr-n3 {
	margin-right: -1rem !important;
}
.mx-n3 {
	margin-right: -1rem !important;
	margin-left: -1rem !important;
}
.mb-n3 {
	margin-bottom: -1rem !important;
}
.ml-n3 {
	margin-left: -1rem !important;
}
.m-n4 {
	margin: -1.5rem !important;
}
.mt-n4 {
	margin-top: -1.5rem !important;
}
.my-n4 {
	margin-top: -1.5rem !important;
	margin-bottom: -1.5rem !important;
}
.mr-n4 {
	margin-right: -1.5rem !important;
}
.mx-n4 {
	margin-right: -1.5rem !important;
	margin-left: -1.5rem !important;
}
.mb-n4 {
	margin-bottom: -1.5rem !important;
}
.ml-n4 {
	margin-left: -1.5rem !important;
}
.m-n5 {
	margin: -3rem !important;
}
.mt-n5 {
	margin-top: -3rem !important;
}
.my-n5 {
	margin-top: -3rem !important;
	margin-bottom: -3rem !important;
}
.mr-n5 {
	margin-right: -3rem !important;
}
.mx-n5 {
	margin-right: -3rem !important;
	margin-left: -3rem !important;
}
.mb-n5 {
	margin-bottom: -3rem !important;
}
.ml-n5 {
	margin-left: -3rem !important;
}
.m-auto {
	margin: auto !important;
}
.mt-auto {
	margin-top: auto !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mr-auto {
	margin-right: auto !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.ml-auto {
	margin-left: auto !important;
}
.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
	text-align: justify !important;
}
.text-wrap {
	white-space: normal !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-lighter {
	font-weight: lighter !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-bold {
	font-weight: 700 !important;
}
.font-weight-bolder {
	font-weight: bolder !important;
}
.font-italic {
	font-style: italic !important;
}
.text-white {
	color: #fff !important;
}
.text-primary {
	color: #007bff !important;
}
a.text-primary {
	:hover {
		color: #0056b3 !important;
	}
	:focus {
		color: #0056b3 !important;
	}
}
.text-secondary {
	color: #6c757d !important;
}
a.text-secondary {
	:hover {
		color: #494f54 !important;
	}
	:focus {
		color: #494f54 !important;
	}
}
.text-success {
	color: #28a745 !important;
}
a.text-success {
	:hover {
		color: #19692c !important;
	}
	:focus {
		color: #19692c !important;
	}
}
.text-info {
	color: #17a2b8 !important;
}
a.text-info {
	:hover {
		color: #0f6674 !important;
	}
	:focus {
		color: #0f6674 !important;
	}
}
.text-warning {
	color: #ffc107 !important;
}
a.text-warning {
	:hover {
		color: #ba8b00 !important;
	}
	:focus {
		color: #ba8b00 !important;
	}
}
.text-danger {
	color: #dc3545 !important;
}
a.text-danger {
	:hover {
		color: #a71d2a !important;
	}
	:focus {
		color: #a71d2a !important;
	}
}
.text-light {
	color: #f8f9fa !important;
}
a.text-light {
	:hover {
		color: #cbd3da !important;
	}
	:focus {
		color: #cbd3da !important;
	}
}
.text-dark {
	color: #343a40 !important;
}
a.text-dark {
	:hover {
		color: #121416 !important;
	}
	:focus {
		color: #121416 !important;
	}
}
.text-body {
	color: #212529 !important;
}
.text-muted {
	color: #6c757d !important;
}
.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.text-decoration-none {
	text-decoration: none !important;
}
.text-break {
	word-break: break-word !important;
	overflow-wrap: break-word !important;
}
.text-reset {
	color: inherit !important;
}
.visible {
	visibility: visible !important;
}
.invisible {
	visibility: hidden !important;
}
#layoutAuthentication {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	#layoutAuthentication_content {
		min-width: 0;
		flex-grow: 1;
	}
	#layoutAuthentication_footer {
		min-width: 0;
	}
}
#layoutSidenav {
	display: flex;
	#layoutSidenav_nav {
		flex-basis: 225px;
		flex-shrink: 0;
		transition: transform 0.15s ease-in-out;
		z-index: 1038;
		transform: translateX(-225px);
	}
	#layoutSidenav_content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-width: 0;
		flex-grow: 1;
		min-height: calc(100vh - 56px);
		margin-left: -225px;
	}
}
.sb-sidenav-toggled {
	#layoutSidenav {
		#layoutSidenav_nav {
			transform: translateX(0);
		}
		#layoutSidenav_content {
			:before {
				/*content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #000;
				z-index: 1037;
				opacity: 0.5;
				transition: opacity 0.3s ease-in-out;*/
			}
		}
	}
}
#layoutError {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	#layoutError_content {
		min-width: 0;
		flex-grow: 1;
	}
	#layoutError_footer {
		min-width: 0;
	}
}
.img-error {
	max-width: 20rem;
}
.sb-sidenav-menu {
	.nav-link {
		.sb-nav-link-icon {
			margin-right: 0.5rem;
		}
	}
}
.sb-topnav {
	padding-left: 0;
	height: 56px;
	z-index: 1039;
	.navbar-brand {
		width: 225px;
		padding-left: 1rem;
		padding-right: 1rem;
		margin: 0;
	}
}
.sb-topnav.navbar-dark {
	#sidebarToggle {
		color: rgba(255, 255, 255, 0.5);
	}
}
.sb-topnav.navbar-light {
	#sidebarToggle {
		color: #212529;
	}
}
.sb-sidenav {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-wrap: nowrap;
	.sb-sidenav-menu {
		flex-grow: 1;
		.nav {
			flex-direction: column;
			flex-wrap: nowrap;
			.sb-sidenav-menu-heading {
				padding: 1.75rem 1rem 0.75rem;
				font-size: 0.75rem;
				font-weight: bold;
				text-transform: uppercase;
			}
			.nav-link {
				display: flex;
				align-items: center;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
				position: relative;
				.sb-nav-link-icon {
					font-size: 0.9rem;
				}
				.sb-sidenav-collapse-arrow {
					display: inline-block;
					margin-left: auto;
					transition: transform 0.15s ease;
				}
			}
			.nav-link.collapsed {
				.sb-sidenav-collapse-arrow {
					transform: rotate(-90deg);
				}
			}
			.sb-sidenav-menu-nested {
				margin-left: 1.5rem;
				flex-direction: column;
			}
		}
	}
	.sb-sidenav-footer {
		padding: 0.75rem;
		flex-shrink: 0;
	}
}
.sb-sidenav-dark {
	background-color: #212529;
	color: rgba(255, 255, 255, 0.5);
	.sb-sidenav-menu {
		.sb-sidenav-menu-heading {
			color: rgba(255, 255, 255, 0.25);
		}
		.nav-link {
			color: rgba(255, 255, 255, 0.5);
			.sb-nav-link-icon {
				color: rgba(255, 255, 255, 0.25);
			}
			.sb-sidenav-collapse-arrow {
				color: rgba(255, 255, 255, 0.25);
			}
			:hover {
				color: #fff;
			}
		}
		.nav-link.active {
			color: #fff;
			.sb-nav-link-icon {
				color: #fff;
			}
		}
	}
	.sb-sidenav-footer {
		background-color: #343a40;
	}
}
.sb-sidenav-light {
	background-color: #f8f9fa;
	color: #212529;
	.sb-sidenav-menu {
		.sb-sidenav-menu-heading {
			color: #adb5bd;
		}
		.nav-link {
			color: #212529;
			.sb-nav-link-icon {
				color: #adb5bd;
			}
			.sb-sidenav-collapse-arrow {
				color: #adb5bd;
			}
			:hover {
				color: #007bff;
			}
		}
		.nav-link.active {
			color: #007bff;
			.sb-nav-link-icon {
				color: #007bff;
			}
		}
	}
	.sb-sidenav-footer {
		background-color: #e9ecef;
	}
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
		max-width: 540px;
	}
	.container-sm {
		max-width: 540px;
	}
	.col-sm {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-sm-1 {
		>* {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-sm-2 {
		>* {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-sm-3 {
		>* {
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}
	.row-cols-sm-4 {
		>* {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-sm-5 {
		>* {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-sm-6 {
		>* {
			flex: 0 0 16.6666666667%;
			max-width: 16.6666666667%;
		}
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-sm-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-sm-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-sm-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-sm-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		order: -1;
	}
	.order-sm-last {
		order: 13;
	}
	.order-sm-0 {
		order: 0;
	}
	.order-sm-1 {
		order: 1;
	}
	.order-sm-2 {
		order: 2;
	}
	.order-sm-3 {
		order: 3;
	}
	.order-sm-4 {
		order: 4;
	}
	.order-sm-5 {
		order: 5;
	}
	.order-sm-6 {
		order: 6;
	}
	.order-sm-7 {
		order: 7;
	}
	.order-sm-8 {
		order: 8;
	}
	.order-sm-9 {
		order: 9;
	}
	.order-sm-10 {
		order: 10;
	}
	.order-sm-11 {
		order: 11;
	}
	.order-sm-12 {
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.3333333333%;
	}
	.offset-sm-2 {
		margin-left: 16.6666666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.3333333333%;
	}
	.offset-sm-5 {
		margin-left: 41.6666666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.3333333333%;
	}
	.offset-sm-8 {
		margin-left: 66.6666666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.3333333333%;
	}
	.offset-sm-11 {
		margin-left: 91.6666666667%;
	}
	.form-inline {
		label {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
		}
		.form-group {
			display: flex;
			flex: 0 0 auto;
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: 0;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-plaintext {
			display: inline-block;
		}
		.input-group {
			width: auto;
		}
		.custom-select {
			width: auto;
		}
		.form-check {
			display: flex;
			align-items: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}
		.form-check-input {
			position: relative;
			flex-shrink: 0;
			margin-top: 0;
			margin-right: 0.25rem;
			margin-left: 0;
		}
		.custom-control {
			align-items: center;
			justify-content: center;
		}
		.custom-control-label {
			margin-bottom: 0;
		}
	}
	.dropdown-menu-sm-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-sm-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-sm {
		flex-flow: row nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}
		}
		>.container {
			flex-wrap: nowrap;
		}
		>.container-fluid {
			flex-wrap: nowrap;
		}
		>.container-sm {
			flex-wrap: nowrap;
		}
		>.container-md {
			flex-wrap: nowrap;
		}
		>.container-lg {
			flex-wrap: nowrap;
		}
		>.container-xl {
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: flex !important;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
		.card {
			flex: 1 0 0%;
			margin-right: 15px;
			margin-bottom: 0;
			margin-left: 15px;
		}
	}
	.card-group {
		display: flex;
		flex-flow: row wrap;
		>.card {
			flex: 1 0 0%;
			margin-bottom: 0;
			+ {
				.card {
					margin-left: 0;
					border-left: 0;
				}
			}
			:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				.card-img-top {
					border-top-right-radius: 0;
				}
				.card-header {
					border-top-right-radius: 0;
				}
				.card-img-bottom {
					border-bottom-right-radius: 0;
				}
				.card-footer {
					border-bottom-right-radius: 0;
				}
			}
			:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				.card-img-top {
					border-top-left-radius: 0;
				}
				.card-header {
					border-top-left-radius: 0;
				}
				.card-img-bottom {
					border-bottom-left-radius: 0;
				}
				.card-footer {
					border-bottom-left-radius: 0;
				}
			}
		}
	}
	.card-columns {
		-moz-column-count: 3;
		column-count: 3;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
		.card {
			display: inline-block;
			width: 100%;
		}
	}
	.jumbotron {
		padding: 4rem 2rem;
	}
	.list-group-horizontal-sm {
		flex-direction: row;
		.list-group-item {
			:first-child {
				border-bottom-left-radius: 0.25rem;
				border-top-right-radius: 0;
			}
			:last-child {
				border-top-right-radius: 0.25rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 1px;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -1px;
					border-left-width: 1px;
				}
			}
		}
		.list-group-item.active {
			margin-top: 0;
		}
	}
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
		.modal-content {
			max-height: calc(100vh - 3.5rem);
		}
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
		::before {
			height: calc(100vh - 3.5rem);
		}
	}
	.modal-sm {
		max-width: 300px;
	}
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-sm-0 {
		margin-right: 0 !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.mr-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-sm-1 {
		margin-left: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.mr-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-sm-2 {
		margin-left: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-sm-3 {
		margin-right: 1rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-3 {
		margin-left: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-4 {
		margin-left: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-sm-5 {
		margin-right: 3rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-5 {
		margin-left: 3rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-sm-0 {
		padding-right: 0 !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.pr-sm-1 {
		padding-right: 0.25rem !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.pr-sm-2 {
		padding-right: 0.5rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-sm-3 {
		padding-right: 1rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-3 {
		padding-left: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-sm-4 {
		padding-right: 1.5rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-4 {
		padding-left: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-sm-5 {
		padding-right: 3rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-5 {
		padding-left: 3rem !important;
	}
	.m-sm-n1 {
		margin: -0.25rem !important;
	}
	.mt-sm-n1 {
		margin-top: -0.25rem !important;
	}
	.my-sm-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.mr-sm-n1 {
		margin-right: -0.25rem !important;
	}
	.mx-sm-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mb-sm-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-sm-n1 {
		margin-left: -0.25rem !important;
	}
	.m-sm-n2 {
		margin: -0.5rem !important;
	}
	.mt-sm-n2 {
		margin-top: -0.5rem !important;
	}
	.my-sm-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.mr-sm-n2 {
		margin-right: -0.5rem !important;
	}
	.mx-sm-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mb-sm-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-sm-n2 {
		margin-left: -0.5rem !important;
	}
	.m-sm-n3 {
		margin: -1rem !important;
	}
	.mt-sm-n3 {
		margin-top: -1rem !important;
	}
	.my-sm-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-sm-n3 {
		margin-right: -1rem !important;
	}
	.mx-sm-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-sm-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-sm-n3 {
		margin-left: -1rem !important;
	}
	.m-sm-n4 {
		margin: -1.5rem !important;
	}
	.mt-sm-n4 {
		margin-top: -1.5rem !important;
	}
	.my-sm-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-sm-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-sm-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-sm-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-sm-n4 {
		margin-left: -1.5rem !important;
	}
	.m-sm-n5 {
		margin: -3rem !important;
	}
	.mt-sm-n5 {
		margin-top: -3rem !important;
	}
	.my-sm-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-sm-n5 {
		margin-right: -3rem !important;
	}
	.mx-sm-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-sm-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-sm-n5 {
		margin-left: -3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-sm-auto {
		margin-right: auto !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto {
		margin-left: auto !important;
	}
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
		max-width: 720px;
	}
	.container-md {
		max-width: 720px;
	}
	.container-sm {
		max-width: 720px;
	}
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-md-1 {
		>* {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-md-2 {
		>* {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-md-3 {
		>* {
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}
	.row-cols-md-4 {
		>* {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-md-5 {
		>* {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-md-6 {
		>* {
			flex: 0 0 16.6666666667%;
			max-width: 16.6666666667%;
		}
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-md-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-md-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-md-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-md-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		order: -1;
	}
	.order-md-last {
		order: 13;
	}
	.order-md-0 {
		order: 0;
	}
	.order-md-1 {
		order: 1;
	}
	.order-md-2 {
		order: 2;
	}
	.order-md-3 {
		order: 3;
	}
	.order-md-4 {
		order: 4;
	}
	.order-md-5 {
		order: 5;
	}
	.order-md-6 {
		order: 6;
	}
	.order-md-7 {
		order: 7;
	}
	.order-md-8 {
		order: 8;
	}
	.order-md-9 {
		order: 9;
	}
	.order-md-10 {
		order: 10;
	}
	.order-md-11 {
		order: 11;
	}
	.order-md-12 {
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.3333333333%;
	}
	.offset-md-2 {
		margin-left: 16.6666666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.3333333333%;
	}
	.offset-md-5 {
		margin-left: 41.6666666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.3333333333%;
	}
	.offset-md-8 {
		margin-left: 66.6666666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.3333333333%;
	}
	.offset-md-11 {
		margin-left: 91.6666666667%;
	}
	.dropdown-menu-md-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-md {
		flex-flow: row nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}
		}
		>.container {
			flex-wrap: nowrap;
		}
		>.container-fluid {
			flex-wrap: nowrap;
		}
		>.container-sm {
			flex-wrap: nowrap;
		}
		>.container-md {
			flex-wrap: nowrap;
		}
		>.container-lg {
			flex-wrap: nowrap;
		}
		>.container-xl {
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: flex !important;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-md {
		flex-direction: row;
		.list-group-item {
			:first-child {
				border-bottom-left-radius: 0.25rem;
				border-top-right-radius: 0;
			}
			:last-child {
				border-top-right-radius: 0.25rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 1px;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -1px;
					border-left-width: 1px;
				}
			}
		}
		.list-group-item.active {
			margin-top: 0;
		}
	}
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-md-0 {
		margin-right: 0 !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.mr-md-1 {
		margin-right: 0.25rem !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-md-1 {
		margin-left: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.mr-md-2 {
		margin-right: 0.5rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-md-2 {
		margin-left: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-md-3 {
		margin-right: 1rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3 {
		margin-left: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-md-4 {
		margin-right: 1.5rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4 {
		margin-left: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-md-0 {
		padding-right: 0 !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.pr-md-1 {
		padding-right: 0.25rem !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.pr-md-2 {
		padding-right: 0.5rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-md-3 {
		padding-right: 1rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3 {
		padding-left: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4 {
		padding-left: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5 {
		padding-left: 3rem !important;
	}
	.m-md-n1 {
		margin: -0.25rem !important;
	}
	.mt-md-n1 {
		margin-top: -0.25rem !important;
	}
	.my-md-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.mr-md-n1 {
		margin-right: -0.25rem !important;
	}
	.mx-md-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mb-md-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-md-n1 {
		margin-left: -0.25rem !important;
	}
	.m-md-n2 {
		margin: -0.5rem !important;
	}
	.mt-md-n2 {
		margin-top: -0.5rem !important;
	}
	.my-md-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.mr-md-n2 {
		margin-right: -0.5rem !important;
	}
	.mx-md-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mb-md-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-md-n2 {
		margin-left: -0.5rem !important;
	}
	.m-md-n3 {
		margin: -1rem !important;
	}
	.mt-md-n3 {
		margin-top: -1rem !important;
	}
	.my-md-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-md-n3 {
		margin-right: -1rem !important;
	}
	.mx-md-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-md-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-md-n3 {
		margin-left: -1rem !important;
	}
	.m-md-n4 {
		margin: -1.5rem !important;
	}
	.mt-md-n4 {
		margin-top: -1.5rem !important;
	}
	.my-md-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-md-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-md-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-md-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-md-n4 {
		margin-left: -1.5rem !important;
	}
	.m-md-n5 {
		margin: -3rem !important;
	}
	.mt-md-n5 {
		margin-top: -3rem !important;
	}
	.my-md-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-md-n5 {
		margin-right: -3rem !important;
	}
	.mx-md-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-md-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-md-n5 {
		margin-left: -3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-md-auto {
		margin-right: auto !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto {
		margin-left: auto !important;
	}
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
		max-width: 960px;
	}
	.container-lg {
		max-width: 960px;
	}
	.container-md {
		max-width: 960px;
	}
	.container-sm {
		max-width: 960px;
	}
	.col-lg {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-lg-1 {
		>* {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-lg-2 {
		>* {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-lg-3 {
		>* {
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}
	.row-cols-lg-4 {
		>* {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-lg-5 {
		>* {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-lg-6 {
		>* {
			flex: 0 0 16.6666666667%;
			max-width: 16.6666666667%;
		}
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-lg-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-lg-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-lg-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-lg-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		order: -1;
	}
	.order-lg-last {
		order: 13;
	}
	.order-lg-0 {
		order: 0;
	}
	.order-lg-1 {
		order: 1;
	}
	.order-lg-2 {
		order: 2;
	}
	.order-lg-3 {
		order: 3;
	}
	.order-lg-4 {
		order: 4;
	}
	.order-lg-5 {
		order: 5;
	}
	.order-lg-6 {
		order: 6;
	}
	.order-lg-7 {
		order: 7;
	}
	.order-lg-8 {
		order: 8;
	}
	.order-lg-9 {
		order: 9;
	}
	.order-lg-10 {
		order: 10;
	}
	.order-lg-11 {
		order: 11;
	}
	.order-lg-12 {
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.3333333333%;
	}
	.offset-lg-2 {
		margin-left: 16.6666666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.3333333333%;
	}
	.offset-lg-5 {
		margin-left: 41.6666666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.3333333333%;
	}
	.offset-lg-8 {
		margin-left: 66.6666666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.3333333333%;
	}
	.offset-lg-11 {
		margin-left: 91.6666666667%;
	}
	.dropdown-menu-lg-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}
		}
		>.container {
			flex-wrap: nowrap;
		}
		>.container-fluid {
			flex-wrap: nowrap;
		}
		>.container-sm {
			flex-wrap: nowrap;
		}
		>.container-md {
			flex-wrap: nowrap;
		}
		>.container-lg {
			flex-wrap: nowrap;
		}
		>.container-xl {
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: flex !important;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-lg {
		flex-direction: row;
		.list-group-item {
			:first-child {
				border-bottom-left-radius: 0.25rem;
				border-top-right-radius: 0;
			}
			:last-child {
				border-top-right-radius: 0.25rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 1px;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -1px;
					border-left-width: 1px;
				}
			}
		}
		.list-group-item.active {
			margin-top: 0;
		}
	}
	.modal-lg {
		max-width: 800px;
	}
	.modal-xl {
		max-width: 800px;
	}
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-lg-0 {
		margin-right: 0 !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.mr-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-lg-1 {
		margin-left: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.mr-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-lg-2 {
		margin-left: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-lg-3 {
		margin-right: 1rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3 {
		margin-left: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4 {
		margin-left: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-lg-5 {
		margin-right: 3rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5 {
		margin-left: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-lg-0 {
		padding-right: 0 !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.pr-lg-1 {
		padding-right: 0.25rem !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.pr-lg-2 {
		padding-right: 0.5rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-lg-3 {
		padding-right: 1rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3 {
		padding-left: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-lg-4 {
		padding-right: 1.5rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4 {
		padding-left: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-lg-5 {
		padding-right: 3rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5 {
		padding-left: 3rem !important;
	}
	.m-lg-n1 {
		margin: -0.25rem !important;
	}
	.mt-lg-n1 {
		margin-top: -0.25rem !important;
	}
	.my-lg-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.mr-lg-n1 {
		margin-right: -0.25rem !important;
	}
	.mx-lg-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mb-lg-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-lg-n1 {
		margin-left: -0.25rem !important;
	}
	.m-lg-n2 {
		margin: -0.5rem !important;
	}
	.mt-lg-n2 {
		margin-top: -0.5rem !important;
	}
	.my-lg-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.mr-lg-n2 {
		margin-right: -0.5rem !important;
	}
	.mx-lg-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mb-lg-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-lg-n2 {
		margin-left: -0.5rem !important;
	}
	.m-lg-n3 {
		margin: -1rem !important;
	}
	.mt-lg-n3 {
		margin-top: -1rem !important;
	}
	.my-lg-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-lg-n3 {
		margin-right: -1rem !important;
	}
	.mx-lg-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-lg-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-lg-n3 {
		margin-left: -1rem !important;
	}
	.m-lg-n4 {
		margin: -1.5rem !important;
	}
	.mt-lg-n4 {
		margin-top: -1.5rem !important;
	}
	.my-lg-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-lg-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-lg-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-lg-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-lg-n4 {
		margin-left: -1.5rem !important;
	}
	.m-lg-n5 {
		margin: -3rem !important;
	}
	.mt-lg-n5 {
		margin-top: -3rem !important;
	}
	.my-lg-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-lg-n5 {
		margin-right: -3rem !important;
	}
	.mx-lg-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-lg-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-lg-n5 {
		margin-left: -3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-lg-auto {
		margin-right: auto !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto {
		margin-left: auto !important;
	}
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
	#layoutSidenav {
		#layoutSidenav_nav {
			transform: translateX(0);
		}
		#layoutSidenav_content {
			margin-left: 0;
			transition: margin 0.15s ease-in-out;
		}
	}
	.sb-sidenav-toggled {
		#layoutSidenav {
			#layoutSidenav_nav {
				transform: translateX(-225px);
			}
			#layoutSidenav_content {
				margin-left: -225px;
				:before {
					/*display: none;*/
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
		max-width: 1140px;
	}
	.container-xl {
		max-width: 1140px;
	}
	.container-lg {
		max-width: 1140px;
	}
	.container-md {
		max-width: 1140px;
	}
	.container-sm {
		max-width: 1140px;
	}
	.col-xl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-xl-1 {
		>* {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-xl-2 {
		>* {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-xl-3 {
		>* {
			flex: 0 0 33.3333333333%;
			max-width: 33.3333333333%;
		}
	}
	.row-cols-xl-4 {
		>* {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-xl-5 {
		>* {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-xl-6 {
		>* {
			flex: 0 0 16.6666666667%;
			max-width: 16.6666666667%;
		}
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-xl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-xl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-xl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-xl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		order: -1;
	}
	.order-xl-last {
		order: 13;
	}
	.order-xl-0 {
		order: 0;
	}
	.order-xl-1 {
		order: 1;
	}
	.order-xl-2 {
		order: 2;
	}
	.order-xl-3 {
		order: 3;
	}
	.order-xl-4 {
		order: 4;
	}
	.order-xl-5 {
		order: 5;
	}
	.order-xl-6 {
		order: 6;
	}
	.order-xl-7 {
		order: 7;
	}
	.order-xl-8 {
		order: 8;
	}
	.order-xl-9 {
		order: 9;
	}
	.order-xl-10 {
		order: 10;
	}
	.order-xl-11 {
		order: 11;
	}
	.order-xl-12 {
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.3333333333%;
	}
	.offset-xl-2 {
		margin-left: 16.6666666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.3333333333%;
	}
	.offset-xl-5 {
		margin-left: 41.6666666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.3333333333%;
	}
	.offset-xl-8 {
		margin-left: 66.6666666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.3333333333%;
	}
	.offset-xl-11 {
		margin-left: 91.6666666667%;
	}
	.dropdown-menu-xl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-xl {
		flex-flow: row nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}
		}
		>.container {
			flex-wrap: nowrap;
		}
		>.container-fluid {
			flex-wrap: nowrap;
		}
		>.container-sm {
			flex-wrap: nowrap;
		}
		>.container-md {
			flex-wrap: nowrap;
		}
		>.container-lg {
			flex-wrap: nowrap;
		}
		>.container-xl {
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: flex !important;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-xl {
		flex-direction: row;
		.list-group-item {
			:first-child {
				border-bottom-left-radius: 0.25rem;
				border-top-right-radius: 0;
			}
			:last-child {
				border-top-right-radius: 0.25rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 1px;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -1px;
					border-left-width: 1px;
				}
			}
		}
		.list-group-item.active {
			margin-top: 0;
		}
	}
	.modal-xl {
		max-width: 1140px;
	}
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-xl-0 {
		margin-right: 0 !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.mr-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.mr-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-xl-3 {
		margin-right: 1rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3 {
		margin-left: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-xl-5 {
		margin-right: 3rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5 {
		margin-left: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-xl-0 {
		padding-right: 0 !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.pr-xl-1 {
		padding-right: 0.25rem !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.pr-xl-2 {
		padding-right: 0.5rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-xl-3 {
		padding-right: 1rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3 {
		padding-left: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-xl-4 {
		padding-right: 1.5rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-xl-5 {
		padding-right: 3rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5 {
		padding-left: 3rem !important;
	}
	.m-xl-n1 {
		margin: -0.25rem !important;
	}
	.mt-xl-n1 {
		margin-top: -0.25rem !important;
	}
	.my-xl-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.mr-xl-n1 {
		margin-right: -0.25rem !important;
	}
	.mx-xl-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mb-xl-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-xl-n1 {
		margin-left: -0.25rem !important;
	}
	.m-xl-n2 {
		margin: -0.5rem !important;
	}
	.mt-xl-n2 {
		margin-top: -0.5rem !important;
	}
	.my-xl-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.mr-xl-n2 {
		margin-right: -0.5rem !important;
	}
	.mx-xl-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mb-xl-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-xl-n2 {
		margin-left: -0.5rem !important;
	}
	.m-xl-n3 {
		margin: -1rem !important;
	}
	.mt-xl-n3 {
		margin-top: -1rem !important;
	}
	.my-xl-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-xl-n3 {
		margin-right: -1rem !important;
	}
	.mx-xl-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-xl-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-xl-n3 {
		margin-left: -1rem !important;
	}
	.m-xl-n4 {
		margin: -1.5rem !important;
	}
	.mt-xl-n4 {
		margin-top: -1.5rem !important;
	}
	.my-xl-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-xl-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-xl-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-xl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-xl-n4 {
		margin-left: -1.5rem !important;
	}
	.m-xl-n5 {
		margin: -3rem !important;
	}
	.mt-xl-n5 {
		margin-top: -3rem !important;
	}
	.my-xl-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-xl-n5 {
		margin-right: -3rem !important;
	}
	.mx-xl-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-xl-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-xl-n5 {
		margin-left: -3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-xl-auto {
		margin-right: auto !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto {
		margin-left: auto !important;
	}
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}
@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		>.table-bordered {
			border: 0;
		}
	}
	.navbar-expand-sm {
		>.container {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-fluid {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-sm {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-md {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-lg {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-xl {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		>.table-bordered {
			border: 0;
		}
	}
	.navbar-expand-md {
		>.container {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-fluid {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-sm {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-md {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-lg {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-xl {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		>.table-bordered {
			border: 0;
		}
	}
	.navbar-expand-lg {
		>.container {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-fluid {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-sm {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-md {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-lg {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-xl {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
@media (max-width: 1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		>.table-bordered {
			border: 0;
		}
	}
	.navbar-expand-xl {
		>.container {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-fluid {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-sm {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-md {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-lg {
			padding-right: 0;
			padding-left: 0;
		}
		>.container-xl {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
	.btn {
		transition: none;
	}
	.fade {
		transition: none;
	}
	.collapsing {
		transition: none;
	}
	.custom-switch {
		.custom-control-label {
			::after {
				transition: none;
			}
		}
	}
	.custom-range {
		::-webkit-slider-thumb {
			-webkit-transition: none;
			transition: none;
		}
		::-moz-range-thumb {
			-moz-transition: none;
			transition: none;
		}
		::-ms-thumb {
			-ms-transition: none;
			transition: none;
		}
	}
	.custom-control-label {
		::before {
			transition: none;
		}
	}
	.custom-file-label {
		transition: none;
	}
	.custom-select {
		transition: none;
	}
	.badge {
		transition: none;
	}
	.progress-bar {
		transition: none;
	}
	.progress-bar-animated {
		-webkit-animation: none;
		animation: none;
	}
	.modal.fade {
		.modal-dialog {
			transition: none;
		}
	}
	.carousel-item {
		transition: none;
	}
	.carousel-fade {
		.active.carousel-item-left {
			transition: none;
		}
		.active.carousel-item-right {
			transition: none;
		}
	}
	.carousel-control-prev {
		transition: none;
	}
	.carousel-control-next {
		transition: none;
	}
	.carousel-indicators {
		li {
			transition: none;
		}
	}
}
@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	* {
		text-shadow: none !important;
		box-shadow: none !important;
		::before {
			text-shadow: none !important;
			box-shadow: none !important;
		}
		::after {
			text-shadow: none !important;
			box-shadow: none !important;
		}
	}
	a {
		:not(.btn) {
			text-decoration: underline;
		}
	}
	abbr[title] {
		::after {
			content: " (" attr(title) ")";
		}
	}
	pre {
		white-space: pre-wrap !important;
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	blockquote {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr {
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
	h2 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body {
		min-width: 992px !important;
	}
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
		td {
			background-color: #fff !important;
		}
		th {
			background-color: #fff !important;
		}
		.thead-dark {
			th {
				color: inherit;
				border-color: #dee2e6;
			}
		}
	}
	.table-bordered {
		th {
			border: 1px solid #dee2e6 !important;
		}
		td {
			border: 1px solid #dee2e6 !important;
		}
	}
	.table-dark {
		color: inherit;
		th {
			border-color: #dee2e6;
		}
		td {
			border-color: #dee2e6;
		}
		thead {
			th {
				border-color: #dee2e6;
			}
		}
		tbody {
			+ {
				tbody {
					border-color: #dee2e6;
				}
			}
		}
	}
}


}

.modal-xl{
	max-width: 80% !important;
}
.modal-xxl{
	max-width: 98% !important;
}